import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import $api from "../../api/url";
import { ABORTED_ERROR } from "../../utils/vars/staticVariables";
import {errorMessage} from "../../api/helpers/scripts";

export const deleteFlow = createAsyncThunk(
    "flow/deleteFlow",
    async ({id}) => {
        try {
            const response = await $api.delete(`/configuration/flows/${id}/`);
            const {data, status} = response;

            return {data, status};
        } catch (error) {
            const {data, status} = error.response;

            return {data, status};
        }
    },
);

export const setNote = createAsyncThunk("flow/setNote", async (noteData) => {
    try {
        const response = await $api.post(
            "/configuration/flow/create/set_notes/",
            noteData,
        );
        const {data, status} = response;

        return {data, status};
    } catch (error) {
        const {data, status} = error.response;

        return {data, status};
    }
});


export const fetchFlowsList = createAsyncThunk(
    "flow/fetchFlowsList",
    async (params, {signal, rejectWithValue}) => {
        try {
            const copiedParams = new URLSearchParams(params);

            const {data} = await $api.get("/configuration/flows/?" + copiedParams, {
                signal,
            });
            return data;
        } catch (error) {
            let err = errorMessage(error);

            if (axios.isCancel(error)) {
                return rejectWithValue();
            }

            throw new Error(err);
        }
    },
);


export const fetchExpiredDomainsList = createAsyncThunk(
    "domains/fetchExpiredDomainsList",
    async (params, {signal, rejectWithValue}) => {
        try {
            const copiedParams = new URLSearchParams(params);

            const {data} = await $api.get("/configuration/expired-flows/?" + copiedParams, {
                signal,
            });
            return data;
        } catch (error) {
            let err = errorMessage(error);

            if (axios.isCancel(error)) {
                return rejectWithValue();
            }

            throw new Error(err);
        }
    },
);


export const duplicateFlow = createAsyncThunk(
    "flow/duplicateFlow",
    async (dataObj) => {
        try {
            const response = await $api.post(
                "/configuration/flow/duplicate/",
                dataObj,
            );

            const {data, status} = response;
            return {data, status};
        } catch (error) {
            const {data, status} = error.response;
            return {data, status};
        }
    },
);

const flowSlice = createSlice({
    name: "flow",
    initialState: {
        flowsList: [],
        totalCount: null,
        duplicateFlowPending: false,
        setNotePending: false,
    },
    reducers: {
        setFlowsList: (state, action) => {
            state.flowsList = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchFlowsList.pending, (state) => {
                state.flowsListPending = true;
                state.flowsList = [];
            })
            .addCase(fetchFlowsList.fulfilled, (state, {payload}) => {
                const {results, count} = payload;

                state.flowsList = results;
                state.totalCount = count;
                state.flowsListPending = false;
            })
            .addCase(fetchFlowsList.rejected, (state, {error}) => {
                const {message} = error;

                state.flowsListPending = message === ABORTED_ERROR;
                state.flowsList = [];

                if (message !== ABORTED_ERROR) {
                    state.totalCount = 0;
                }
            })

            .addCase(fetchExpiredDomainsList.pending, (state) => {
                state.flowsListPending = true;
                state.flowsList = [];
            })
            .addCase(fetchExpiredDomainsList.fulfilled, (state, {payload}) => {
                const {results, count} = payload;

                state.flowsList = results;
                state.totalCount = count;
                state.flowsListPending = false;
            })
            .addCase(fetchExpiredDomainsList.rejected, (state, {error}) => {
                const {message} = error;

                state.flowsListPending = message === ABORTED_ERROR;
                state.flowsList = [];

                if (message !== ABORTED_ERROR) {
                    state.totalCount = 0;
                }
            })

            .addCase(setNote.pending, (state) => {
                state.setNotePending = true;
            })
            .addCase(setNote.fulfilled, (state) => {
                state.setNotePending = false;
            })
            .addCase(setNote.rejected, (state) => {
                state.setNotePending = false;
            })

            .addCase(duplicateFlow.pending, (state) => {
                state.duplicateFlowPending = true;
            })
            .addCase(duplicateFlow.fulfilled, (state) => {
                state.duplicateFlowPending = false;
            })
            .addCase(duplicateFlow.rejected, (state) => {
                state.duplicateFlowPending = false;
            });
    },
});

export const {setFlowsList} = flowSlice.actions;

export default flowSlice.reducer;

export const flowSelector = (state) => state.flow;
