import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ThemeProvider } from "@mui/material/styles";
import { datePickerDarkTheme, datePickerStyles, StyledDatePicker } from "../../assets/styles/Themes/DatePickerTheme";

export const MuiDatePicker = ({
                                  value,
                                  label,
                                  updateSearchParams,
                                  searchParamValue
                              }) => {
    const [selectedDate, setSelectedDate] = useState(value || null);

    const handleClear = () => {
        setSelectedDate(null);
        updateSearchParams(searchParamValue, "", true);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ThemeProvider theme={datePickerDarkTheme}>
                <StyledDatePicker
                    label={label}
                    value={selectedDate}
                    onChange={(newDate) => {
                        if (newDate && newDate.isValid()) {
                            setSelectedDate(newDate);
                            updateSearchParams(
                                searchParamValue,
                                newDate.format("YYYY-MM-DD")
                            );
                        }
                    }}
                    format="YYYY-MM-DD"
                    sx={datePickerStyles}
                    slotProps={{
                        actionBar: {
                            actions: ["clear", "accept"],
                            onClear: handleClear,
                        },
                    }}
                />
            </ThemeProvider>
        </LocalizationProvider>
    );
};
