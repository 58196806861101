import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import s from "./balances.module.scss";
import MultiSelect from "../../../components/Selects/MultiSelect/MultiSelect";

import CustomSkeleton from "../../../components/UiKit/CustomSkeleton/CustomSkeleton";
import Pagination from "../../../components/Pagination/Pagination";
import {
  fetchBalances,
  financeSelector,
} from "../../../redux/slices/financeSlice";
import EmptyList from "../../../components/EmptyList/EmptyList";
import {
  fetchUsersList,
  globalDataSelector,
} from "../../../redux/slices/globalDataSlice";
import { useSelectOptions } from "../../../utils/helpers/useSelectHelpers";
import { balancesOrderingList, params } from "../../../utils/vars/params";
import ParamsSort from "../../../components/ParamsSort/ParamsSort";
import { Link, useSearchParams } from "react-router-dom";
import useGetSearchParams from "../../../hooks/useGetSearchParams";
import useSetSearchParams from "../../../hooks/useSetSearchParams";
import {
  email,
  ordering,
  page,
  search,
} from "../../Authorized/Statistics/ClicksStats/ClicksStats";
import { getTableDefaultParams } from "../../../api/helpers/scripts";
import { INVALID_PAGE_ERROR } from "../../../utils/vars/staticVariables";
import SvgSelector from "../../../components/SvgSelector/SvgSelector";
import { useDebounce } from "../../../hooks/useDebounce";
import { useTranslation } from "react-i18next";
import Header from "../../../components/Partials/Header/Header";

const Balances = () => {
  const { balancesList, balancesPending, totalCount } =
    useSelector(financeSelector);

  const { usersList, isCollapsedSidebar } =
    useSelector(globalDataSelector);

  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const {
    search: urlSearchValue,
    page: pageValue,
    email: emailParam,
    ordering: orderingValue,
  } = useGetSearchParams();

  const updateSearchParams = useSetSearchParams(setSearchParams);

  const { t } = useTranslation();

  const selectedEmail = useMemo(() => {
    return emailParam ? { value: emailParam, label: emailParam } : null;
  }, [emailParam]);

  const emailsListOptions = useSelectOptions(usersList, "email", "email");

  const [inputSearchValue, setInputSearchValue] = useDebounce((value) => {
    if (value) {
      updateSearchParams(search, value);
    } else {
      updateSearchParams(search, value, true);
    }
  }, 600);

  useEffect(() => {
    dispatch(fetchUsersList());
  }, [dispatch]);

  useEffect(() => {
    if (!orderingValue) {
      updateSearchParams(ordering, "wallet__balance");
    }
  }, []);

  useEffect(() => {
    if (!urlSearchValue) {
      setInputSearchValue("");
    } else {
      setInputSearchValue(urlSearchValue);
    }
  }, [searchParams]);

  useEffect(() => {
    const promise = dispatch(
      fetchBalances(
        getTableDefaultParams({
          searchParams,
          defaultPageSize: 25,
        }),
      ),
    );

    promise.unwrap().catch((err) => {
      if (err?.message === INVALID_PAGE_ERROR) {
        updateSearchParams(page, 1);
      }
    });

    return () => {
      promise.abort();
    };
  }, [dispatch, searchParams]);

  return (
    <div
      className={`${s.balancesWrapper} ${isCollapsedSidebar === "true" ? s.collapsedSidebar : ""}`}
    >
      <Header
          searchValue={inputSearchValue}
          setSearchValue={setInputSearchValue}
          isSearch
      />
      <div className={s.pageContent}>
        <div className={s.filtersWrapper}>
          <div className={`${s.filters} ${s.oneFilter}`}>
            <div className={s.selectWrapper}>
              <div className={s.selectIconWrapper}>
                <SvgSelector id="user-select-icon" />
              </div>
              <MultiSelect
                isClearable={true}
                value={selectedEmail}
                options={emailsListOptions}
                setSelectedOptions={(option) => {
                  if (option) {
                    const { value } = option;
                    updateSearchParams(email, value);
                  } else {
                    updateSearchParams(email, "", true);
                  }
                }}
                placeholder={t("user")}
              />
            </div>
          </div>
        </div>

        <div className={s.pageInnerContent}>
          {totalCount === 0 && !balancesPending ? (
            <EmptyList />
          ) : balancesPending ? (
            <section className={s.body}>
              <table>
                <tr>
                  {balancesOrderingList.map(({ ordering, name }) => (
                    <th key={name}>
                      <div className={s.headerContent}>
                        {t(name)}
                        <ParamsSort
                          sortBy={ordering}
                          searchParams={searchParams}
                          setSearchParams={setSearchParams}
                        />
                      </div>
                    </th>
                  ))}
                </tr>
              </table>
              <div className={s.skeletonWrapper}>
                <CustomSkeleton styles={s.skeletonTable} />
              </div>
            </section>
          ) : (
            <section className={s.body}>
              <table>
                <thead>
                  <tr>
                    {balancesOrderingList.map(({ ordering, name }) => (
                      <th key={name}>
                        <div className={s.headerContent}>
                          {t(name)}
                          <ParamsSort
                            sortBy={ordering}
                            searchParams={searchParams}
                            setSearchParams={setSearchParams}
                          />
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {balancesList?.map(({ email, balance, offer }) => {
                    return (
                      <tr key={email}>
                        <td>{email}</td>
                        <td>{offer}</td>
                        <td>{balance}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </section>
          )}
        </div>

        <div
          className={`${s.paginationContainer} ${isCollapsedSidebar === "true" ? s.collapsed : ""}`}
        >
          <div className={s.newRecordsCount}>
            {t("total")}: {!balancesPending && <span>{totalCount}</span>}
          </div>
          {totalCount > 25 && (
            <Pagination
              currentPage={parseInt(pageValue) || 1}
              setCurrentPage={(page) => {
                updateSearchParams(params.page, page);
              }}
              total={totalCount}
              limit={25}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Balances;
