import {
    clicksStatsOrderingList,
    params,
    sortDateOptions,
    statisticsStatusOptions,
} from "../../../../utils/vars/params";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {PageSizeDropdown} from "../../../../components/UiKit/PageSizeDropdown/PageSizeDropdown";
import {useDispatch, useSelector} from "react-redux";
import {
    downloadClicksStats,
    fetchClicksStats,
    resetTotalCount,
    statisticsSelector,
} from "../../../../redux/slices/statisticsSlice";
import {
    fetchUsersList,
    globalDataSelector,
} from "../../../../redux/slices/globalDataSlice";
import {applicationSelector} from "../../../../redux/slices/applicationSlice";
import {useSearchParams} from "react-router-dom";
import useGetSearchParams from "../../../../hooks/useGetSearchParams";
import useSetSearchParams from "../../../../hooks/useSetSearchParams";
import {
    useSearchParamsOptions,
    useSelectOptions,
} from "../../../../utils/helpers/useSelectHelpers";
import {useDebounce} from "../../../../hooks/useDebounce";
import {fetchClickStatsFilters} from "../../../../api/helpers/fetchFilters";
import {getDefaultParams} from "../../../../api/helpers/scripts";
import {
    downloadFileTypesList,
    INVALID_PAGE_ERROR, snackbar_error,
    snackbar_info,
    snackbar_success, snackbar_warning
} from "../../../../utils/vars/staticVariables";
import s from "./clicksStats.module.scss";
import MultiSelect from "../../../../components/Selects/MultiSelect/MultiSelect";
import {MuiDatePicker} from "../../../../components/DatePickers/MuiDatePicker";
import {
    formatDateTime,
    stringToDayJs,
} from "../../../../utils/helpers/formatTime";
import EmptyList from "../../../../components/EmptyList/EmptyList";
import ParamsSort from "../../../../components/ParamsSort/ParamsSort";
import CustomSkeleton from "../../../../components/UiKit/CustomSkeleton/CustomSkeleton";
import SvgSelector from "../../../../components/SvgSelector/SvgSelector";
import Pagination from "../../../../components/Pagination/Pagination";
import {onSetPageSize} from "../../../../utils/helpers/statisticsHelper";
import {cleanSearchParams, hasNonEmptyParams} from "../../../../utils/helpers/cleanFilters";
import FilterApplicationSelect from "../../../../components/Selects/FilterApplicationSelect";
import {cleanDomain} from "../../../../utils/helpers/cleanDomain";
import GeoSelect from "../../../../components/Selects/GeoSelect";
import {is_staff, is_tech_user} from "../../../../storage/user";
import {useTranslation} from "react-i18next";
import {managementSelector} from "../../../../redux/slices/managementSlice";
import {useOutsideAndEscClose} from "../../../../hooks/useOutsideAndEscClose";
import {downloadTableFromBinaryData} from "../../../../utils/helpers/downloadTableFromBinaryData";
import useCustomSnackbar from "../../../../hooks/useCustomSnackbar";
import {useSnackbar} from "notistack";
import {CustomAsyncSelect} from "../../../../components/Selects/CustomAsyncSelect";
import {DOMAINS_REQUEST, REDIRECTORS_REQUEST} from "../../../../api/helpers/getFilterRequest";
import {stringHelpers} from "../../../../utils/helpers/stringHelpers";
import Header from "../../../../components/Partials/Header/Header";

export const {
    domain,
    domain_address,
    sub7,
    status,
    geo,
    application_name,
    sort,
    is_support_handled,
    page_size,
    page,
    created_at_before,
    created_at_after,
    search,
    user,
    ip_address,
    flow,
    country_code,
    os,
    date_time_before,
    date_time_after,
    purpose,
    email,
    ordering,
    postback_type,
    postback_time,
    conversion_date_time,
    conversion_type
} = params;

export const onSortDateHandler = (option, updateSearchParams, setSortDate) => {
    const value = option.value;

    if (value !== "custom_date") {
        updateSearchParams(created_at_before, "", true);
        updateSearchParams(created_at_after, "", true);
    }
    setSortDate(option);

    if (value === "custom_date") {
        updateSearchParams(sort, "", true);
    } else {
        updateSearchParams(sort, value);
    }
};

const ClicksStats = () => {
    const [sortDate, setSortDate] = useState(sortDateOptions[0]);
    const [selectedGeos, setSelectedGeos] = useState([]);
    const [isDownloadFormatPopupActive, setIsDownloadFormatPopupActive] = useState(false);
    const [downloadFormat, setDownloadFormat] = useState(downloadFileTypesList[0]);
    const fileFormatDropdownRef = useRef(null);

    const dispatch = useDispatch();

    const {statisticsList, loading, totalCount, downloadClicksStatisticsPending} =
        useSelector(statisticsSelector);

    const {
        geoList,
        isCollapsedSidebar,
        pageSizeValue,
        usersList,
        asyncDomainsList,
        asyncDomainsPending,
        asyncRedirectorsList,
        asyncRedirectorsPending
    } = useSelector(globalDataSelector);
    const {appList} = useSelector(applicationSelector);

    useOutsideAndEscClose(fileFormatDropdownRef, setIsDownloadFormatPopupActive);

    const showSnackbar = useCustomSnackbar();
    const {closeSnackbar} = useSnackbar();

    const [searchParams, setSearchParams] = useSearchParams();
    const {
        search: urlSearchValue,
        sort: sortValue,
        page: pageValue,
        created_at_after: createdAtAfterParam,
        created_at_before: createdAtBeforeParam,
        email: emailParam,
    } = useGetSearchParams();

    const updateSearchParams = useSetSearchParams(setSearchParams);

    const {t} = useTranslation();

    useEffect(() => {
        const domainParam = searchParams.get("domain");

        if (domainParam) {
            const domains = domainParam.split(",");
            const cleanedDomains = domains.map((domain) =>
                cleanDomain(decodeURIComponent(domain)),
            );

            // Якщо очищені домени не збігаються з оригінальними, оновлюємо параметри
            if (cleanedDomains.join(",") !== domains.join(",")) {
                updateSearchParams("domain", cleanedDomains.join(","));
            }
        }
    }, [searchParams, updateSearchParams]);

    const [selectedDomains, selectedSub7, selectedStatus, selectedApplications] =
        useSearchParamsOptions(searchParams, [
            domain,
            sub7,
            status,
            application_name,
        ]);

    const domainsListOptions = useSelectOptions(
        asyncDomainsList,
        "address",
        "address",
    );
    const sub7ListOptions = useSelectOptions(asyncRedirectorsList, "sub7", "sub7");

    const selectedEmail = useMemo(() => {
        return emailParam ? {value: emailParam, label: emailParam} : null;
    }, [emailParam]);

    const geoListOptions = useMemo(() => {
        return geoList.map((item) => ({
            label: item.name,
            value: item.code,
            icon: item.flag,
        }));
    }, [geoList]);

    const appListOptions = useMemo(() => {
        return appList.map((item) => ({
            label: item.name,
            value: item.name,
            is_alive: is_tech_user ? item.is_alive : true,
            name: "app_list",
            icon: item.icon,
        }));
    }, [appList]);

    const emailsListOptions = useSelectOptions(usersList, "email", "email");

    const [inputSearchValue, setInputSearchValue] = useDebounce((value) => {
        if (value) {
            updateSearchParams(search, value);
        } else {
            updateSearchParams(search, value, true);
        }
    }, 600);

    useEffect(() => {
        fetchClickStatsFilters(dispatch);
    }, [dispatch]);

    useEffect(() => {
        if (!urlSearchValue) {
            setInputSearchValue("");
        } else {
            setInputSearchValue(urlSearchValue);
        }

        if (
            createdAtAfterParam ||
            createdAtBeforeParam ||
            sortDate.value === "custom_date"
        ) {
            setSortDate(sortDateOptions.find((item) => item.value === "custom_date"));
        } else {
            if (!sortValue) {
                setSortDate(sortDateOptions[0]);
            } else {
                setSortDate(sortDateOptions.find(({value}) => value === sortValue));
            }
        }
    }, [searchParams]);

    useEffect(() => {
        const newGeoListOptions = searchParams
            .get(geo)
            ?.split(",")
            .filter((value) => value !== "")
            .map((value) => {
                return geoListOptions.find((item) => item.value === value);
            });

        if (!newGeoListOptions || newGeoListOptions.length === 0) {
            setSelectedGeos([]);
        } else {
            setSelectedGeos(newGeoListOptions);
        }
    }, [searchParams, geoListOptions]);

    useEffect(() => {
        if (is_tech_user) {
            dispatch(fetchUsersList());
        }
    }, [dispatch]);

    useEffect(() => {
        const promise = dispatch(
            fetchClicksStats(
                getDefaultParams({
                    searchParams,
                    sort,
                    defaultPageSize: pageSizeValue,
                }),
            ),
        );

        promise.unwrap().catch((err) => {
            if (err?.message === INVALID_PAGE_ERROR) {
                updateSearchParams(page, 1);
            }
        });

        return () => {
            promise.abort();
        };
    }, [dispatch, searchParams, pageSizeValue]);

    useEffect(() => {
        return () => dispatch(resetTotalCount());
    }, [dispatch]);

    const cleanFilters = () => {
        cleanSearchParams(searchParams, setSearchParams)
        setSelectedGeos([]);
    };

    const handleSetPageSize = (size) => {
        onSetPageSize(dispatch, size);
    };

    const selectedDate = (() => {
        const foundOption = sortDateOptions.find(
            ({value}) => value === sortDate?.value,
        );

        if (foundOption) {
            return {...foundOption, label: t(foundOption.value)};
        }

        return null;
    })();

    const onDownloadStatistics = async () => {

        if (totalCount > 100000 && !is_staff) {
            return showSnackbar(t("more_than_100k_records"), snackbar_warning);
        }
        if (totalCount > 200000 && is_staff) {
            return showSnackbar(t("more_than_200k_records"), snackbar_warning);
        }

        const downloadSnackbar = showSnackbar(
            t("stats_downloading"),
            snackbar_info,
            true,
        );

        const downloadStatisticsResult = await dispatch(downloadClicksStats({
            params: searchParams,
            file_type: downloadFormat?.value
        }));

        if (downloadStatisticsResult?.payload?.status === 200) {

            let statsDateRange
            if (sortDate.value !== 'custom_date') {
                statsDateRange = sortDate.value.split('_').map(word => stringHelpers(word)).join('_')
            } else if (sortDate.value === 'custom_date' && !createdAtAfterParam && !createdAtBeforeParam) {
                statsDateRange = "Today"
            } else {
                statsDateRange = `Custom_Date_${createdAtAfterParam}-${createdAtBeforeParam}`
            }

            downloadTableFromBinaryData(downloadStatisticsResult?.payload?.data, "Clicks_Statistics", downloadFormat?.value, statsDateRange);
            closeSnackbar(downloadSnackbar);
            showSnackbar(t("stats_downloaded"), snackbar_success);
        } else {
            closeSnackbar(downloadSnackbar);
            showSnackbar(t("failed_to_download_stats"), snackbar_error);
        }
    };

    return (
        <div
            className={`${s.clicksStatsWrapper} ${isCollapsedSidebar === "true" ? s.collapsedSidebar : ""}`}
        >
            <Header
                searchValue={inputSearchValue}
                setSearchValue={setInputSearchValue}
                isSearch
            />
            <div className={s.pageContent}>
                <div className={s.filtersWrapper}>
                    <div className={s.filters}>

                        <div className={s.selectDateWrapper}>
                            <div className={s.selectIconWrapper}>
                                <SvgSelector id="date-select-icon"/>
                            </div>
                            <MultiSelect
                                isClearable={false}
                                options={sortDateOptions.map((option) => {
                                    return {
                                        ...option,
                                        label: t(option.value),
                                    };
                                })}
                                setSelectedOptions={(value) =>
                                    onSortDateHandler(value, updateSearchParams, setSortDate)
                                }
                                value={selectedDate}
                                placeholder={t("date")}
                                isMulti={false}
                            />
                        </div>
                        {sortDate?.value === "custom_date" && (
                            <div className={s.datePickersWrapper}>
                                <div className={s.datePicker}>
                                    <MuiDatePicker
                                        label={t("date_from")}
                                        value={stringToDayJs(createdAtAfterParam)}
                                        searchParamValue={created_at_after}
                                        updateSearchParams={updateSearchParams}
                                    />
                                </div>
                                <div className={s.datePicker}>
                                    <MuiDatePicker
                                        label={t("date_to")}
                                        value={stringToDayJs(createdAtBeforeParam)}
                                        searchParamValue={created_at_before}
                                        updateSearchParams={updateSearchParams}
                                    />
                                </div>
                            </div>
                        )}

                        <div className={s.selectWrapper}>
                            <div className={s.selectIconWrapper}>
                                <SvgSelector id="domain-select-icon"/>
                            </div>
                            <CustomAsyncSelect
                                extractDomain={true}
                                value={selectedDomains}
                                options={domainsListOptions}
                                setOptions={(values) =>
                                    updateSearchParams(
                                        domain,
                                        values.map((el) => el.value).join(","),
                                    )
                                }
                                placeholder={t("domain_placeholder")}
                                requestName={DOMAINS_REQUEST}
                                pageSize={50}
                                pending={asyncDomainsPending}
                            />
                        </div>

                        <div className={s.selectWrapper}>
                            <div className={s.selectIconWrapper}>
                                <SvgSelector id="app-select-icon"/>
                            </div>
                            <FilterApplicationSelect
                                isClearable={true}
                                value={selectedApplications}
                                options={appListOptions}
                                onChange={(values) =>
                                    updateSearchParams(
                                        application_name,
                                        values.map((el) => el.value).join(","),
                                    )
                                }
                                placeholder={t("application_placeholder")}
                                isMulti={true}
                            />
                        </div>

                        <div className={s.selectWrapper}>
                            <div className={s.selectIconWrapper}>
                                <SvgSelector id="geo-select-icon"/>
                            </div>
                            <GeoSelect
                                isClearable={true}
                                value={selectedGeos}
                                options={geoListOptions}
                                onChange={(values) =>
                                    updateSearchParams(
                                        geo,
                                        values.map((el) => el.value).join(","),
                                    )
                                }
                                placeholder={t("geo_placeholder")}
                                isMulti={true}
                            />
                        </div>

                        <div className={s.selectWrapper}>
                            <div className={s.selectIconWrapper}>
                                <SvgSelector id="sub7-select-icon"/>
                            </div>
                            <CustomAsyncSelect
                                value={selectedSub7}
                                options={sub7ListOptions}
                                setOptions={(values) =>
                                    updateSearchParams(
                                        sub7,
                                        values.map((el) => el.value).join(","),
                                    )
                                }
                                placeholder="Sub7"
                                requestName={REDIRECTORS_REQUEST}
                                pageSize={50}
                                pending={asyncRedirectorsPending}
                            />
                            {/*<MultiSelect*/}
                            {/*    isClearable={true}*/}
                            {/*    value={selectedSub7}*/}
                            {/*    setSelectedOptions={(values) =>*/}
                            {/*        updateSearchParams(*/}
                            {/*            sub7,*/}
                            {/*            values.map((el) => el.value).join(","),*/}
                            {/*        )*/}
                            {/*    }*/}
                            {/*    options={sub7ListOptions}*/}
                            {/*    placeholder="Sub7"*/}
                            {/*    isMulti={true}*/}
                            {/*    asyncRedirectorsPending*/}
                            {/*/>*/}
                        </div>
                        <div className={s.selectWrapper}>
                            <div className={s.selectIconWrapper}>
                                <SvgSelector id="status-select-icon"/>
                            </div>
                            <MultiSelect
                                isClearable={true}
                                value={selectedStatus}
                                options={statisticsStatusOptions}
                                setSelectedOptions={(values) =>
                                    updateSearchParams(
                                        status,
                                        values.map((el) => el.value).join(","),
                                    )
                                }
                                placeholder={t("status")}
                                isMulti={true}
                            />
                        </div>

                        {
                            is_tech_user && <div className={s.selectWrapper}>
                                <div className={s.selectIconWrapper}>
                                    <SvgSelector id="user-select-icon"/>
                                </div>
                                <MultiSelect
                                    isClearable={true}
                                    value={selectedEmail}
                                    options={emailsListOptions}
                                    setSelectedOptions={(option) => {
                                        if (option) {
                                            const {value} = option;
                                            updateSearchParams(email, value);
                                        } else {
                                            updateSearchParams(email, "", true);
                                        }
                                    }}
                                    placeholder={t("user")}
                                />
                            </div>
                        }

                        <div className={s.downloadBtnWrapper}>

                            <button
                                className={s.primaryBtn}
                                onClick={() => {
                                    if (statisticsList.length > 0) {
                                        onDownloadStatistics()
                                    } else {
                                        showSnackbar(t('no_records_available'), snackbar_warning)
                                    }
                                }}
                                disabled={downloadClicksStatisticsPending}
                            >
                                <SvgSelector id="download-btn-icon"/>
                                {t("download")}
                            </button>
                            <button className={s.arrowBtn} onClick={(e) => {
                                e.stopPropagation();
                                setIsDownloadFormatPopupActive((prev) => !prev);
                            }}>
                                <SvgSelector id='language-dropdown-arrow'/>
                            </button>

                            {isDownloadFormatPopupActive && (
                                <ul className={s.langList} ref={fileFormatDropdownRef} tabIndex={0}>
                                    {downloadFileTypesList.map((type, index) => (
                                        <li
                                            onClick={() => {
                                                setDownloadFormat(type)
                                                setIsDownloadFormatPopupActive(false)
                                            }}
                                            key={index}
                                        >
                                            <button className={s.langOptionBtn}>
                                                {type.label}
                                                {type.value === downloadFormat?.value && (
                                                    <SvgSelector id="language-check-icon"/>
                                                )}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>

                        {hasNonEmptyParams(searchParams) && (
                            <div className={s.cleanBtnContainer}>
                                <button className={s.blueBorderBtn} onClick={cleanFilters}>
                                    {t("clear_button")}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                <div className={s.pageInnerContent}>
                    {totalCount === 0 && !loading ? (
                        <EmptyList/>
                    ) : loading ? (
                        <section className={s.body}>
                            <table>
                                <thead>
                                <tr>
                                    <th>{t("click")} ID</th>
                                    {clicksStatsOrderingList.map(({ordering, name}) => (
                                        <th key={name}>
                                            <div className={s.headerContent}>
                                                {t(name)}
                                                <ParamsSort
                                                    sortBy={ordering}
                                                    searchParams={searchParams}
                                                    setSearchParams={setSearchParams}
                                                />
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                                </thead>
                            </table>
                            <div className={s.skeletonWrapper}>
                                <CustomSkeleton styles={s.skeletonTable}/>
                            </div>
                        </section>
                    ) : (
                        <section className={s.body}>
                            <table>
                                <thead>
                                <tr>
                                    <th>{t("click")} ID</th>
                                    {clicksStatsOrderingList.map(({ordering, name}) => (
                                        <th key={name}>
                                            <div className={s.headerContent}>
                                                {t(name)}
                                                <ParamsSort
                                                    sortBy={ordering}
                                                    searchParams={searchParams}
                                                    setSearchParams={setSearchParams}
                                                />
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                                </thead>
                                <tbody>
                                {statisticsList?.map(
                                    (
                                        {
                                            domain,
                                            geo,
                                            tds_clickid,
                                            app,
                                            sub7,
                                            created_at,
                                            is_lead,
                                            is_sale,
                                            is_installed,
                                            is_unique
                                        },
                                        index,
                                    ) => {
                                        const {date, time} = formatDateTime(created_at);
                                        const geoFlag = geoList.find(
                                            (geoItem) => geoItem.code === geo,
                                        )?.flag;
                                        const appIcon = appList.find(
                                            (appItem) => appItem.name === app?.name,
                                        )?.icon;

                                        return (
                                            <tr key={index}>
                                                <td>{tds_clickid}</td>
                                                <td className={s.geoTd}>
                            <span className={s.value}>
                              <span
                                  className={s.flagWrapper}
                                  style={
                                      geoFlag
                                          ? {
                                              backgroundImage: `url(${geoFlag})`,
                                          }
                                          : {
                                              background: "grey",
                                          }
                                  }
                              ></span>
                              <span>{geo}</span>
                            </span>
                                                </td>
                                                <td>{domain}</td>

                                                <td className={s.appTd}>
                            <span className={s.value}>
                              <span
                                  className={s.iconWrapper}
                                  style={
                                      appIcon
                                          ? {
                                              backgroundImage: `url(${appIcon})`,
                                          }
                                          : {
                                              background: "grey",
                                          }
                                  }
                              ></span>
                              <span>{app?.name}</span>
                            </span>
                                                </td>

                                                <td>{sub7}</td>
                                                <td>
                                                    {is_installed ? (
                                                        <SvgSelector id="check-icon"/>
                                                    ) : (
                                                        <SvgSelector id="cross-icon"/>
                                                    )}
                                                </td>
                                                <td>
                                                    {is_lead ? (
                                                        <SvgSelector id="check-icon"/>
                                                    ) : (
                                                        <SvgSelector id="cross-icon"/>
                                                    )}
                                                </td>
                                                <td>
                                                    {is_sale ? (
                                                        <SvgSelector id="check-icon"/>
                                                    ) : (
                                                        <SvgSelector id="cross-icon"/>
                                                    )}
                                                </td>
                                                <td>
                                                    {is_unique ? (
                                                        <SvgSelector id="check-icon"/>
                                                    ) : (
                                                        <SvgSelector id="cross-icon"/>
                                                    )}
                                                </td>
                                                <td>
                                                    <p>{date}</p>
                                                    <p style={{marginTop: 7}}>{time}</p>
                                                </td>
                                            </tr>
                                        );
                                    },
                                )}
                                </tbody>
                            </table>
                        </section>
                    )}
                </div>

                <div
                    className={`${s.paginationContainerStatistics} ${isCollapsedSidebar === "true" ? s.collapsed : ""}`}
                >
                    <div className={s.countPageSizeContainer}>
                        <div className={s.newRecordsCount}>
                            {t("total")}: {!loading && <span>{totalCount}</span>}
                        </div>
                        <div className={s.pageSizeDropdownContainer}>
                            <PageSizeDropdown
                                setSize={handleSetPageSize}
                                size={pageSizeValue}
                            />
                        </div>
                    </div>
                    {totalCount > pageSizeValue && (
                        <Pagination
                            currentPage={parseInt(pageValue) || 1}
                            setCurrentPage={(page) => {
                                updateSearchParams(params.page, page);
                            }}
                            total={totalCount}
                            limit={pageSizeValue}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default ClicksStats;
