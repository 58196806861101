import React, { useEffect, useMemo, useRef, useState } from "react";
import { params, sortDateOptions } from "../../../../utils/vars/params";
import { useDispatch, useSelector } from "react-redux";
import {
  globalDataSelector,
} from "../../../../redux/slices/globalDataSlice";
import {
  fetchCloacaStats,
  resetCloacaStats,
  statisticsSelector,
} from "../../../../redux/slices/statisticsSlice";
import { useSearchParams } from "react-router-dom";
import useGetSearchParams from "../../../../hooks/useGetSearchParams";
import useSetSearchParams from "../../../../hooks/useSetSearchParams";
import {
  useSearchParamsOptions,
  useSelectOptions,
} from "../../../../utils/helpers/useSelectHelpers";
import {
  country_code,
  created_at_after,
  created_at_before,
  flow,
  ip_address,
  onSortDateHandler,
  os,
  page,
  sort,
} from "../ClicksStats/ClicksStats";
import { fetchCloacaStatsFilters } from "../../../../api/helpers/fetchFilters";

import { getDefaultParams } from "../../../../api/helpers/scripts";
import { INVALID_PAGE_ERROR } from "../../../../utils/vars/staticVariables";
import s from "./cloacaStats.module.scss";
import MultiSelect from "../../../../components/Selects/MultiSelect/MultiSelect";
import { MuiDatePicker } from "../../../../components/DatePickers/MuiDatePicker";
import {
  formatDateTime,
  stringToDayJs,
} from "../../../../utils/helpers/formatTime";
import { IpCreatableSelect } from "../../../../components/UiKit/Select/IpCreatableSelect/IpCreatableSelect";
import EmptyList from "../../../../components/EmptyList/EmptyList";
import CustomSkeleton from "../../../../components/UiKit/CustomSkeleton/CustomSkeleton";
import SvgSelector from "../../../../components/SvgSelector/SvgSelector";
import Pagination from "../../../../components/Pagination/Pagination";
import { onSetPageSize } from "../../../../utils/helpers/statisticsHelper";
import { PageSizeDropdown } from "../../../../components/UiKit/PageSizeDropdown/PageSizeDropdown";
import {cleanSearchParams, hasNonEmptyParams} from "../../../../utils/helpers/cleanFilters";
import { cleanDomain } from "../../../../utils/helpers/cleanDomain";
import GeoSelect from "../../../../components/Selects/GeoSelect";
import { useTranslation } from "react-i18next";
import Header from "../../../../components/Partials/Header/Header";
import {CustomAsyncSelect} from "../../../../components/Selects/CustomAsyncSelect";
import {DOMAINS_REQUEST} from "../../../../api/helpers/getFilterRequest";

const CloacaStats = () => {
  const [ipList, setIpList] = useState([]);
  const [sortDate, setSortDate] = useState(sortDateOptions[0]);
  const [selectedGeos, setSelectedGeos] = useState([]);
  const [isFlowInvalid, setIsFlowInvalid] = useState(false);
  const [ipSelectErrorMessage, setIpSelectErrorMessage] = useState("");

  const promiseRef = useRef(null);
  const abortControllerRef = useRef(null);

  const dispatch = useDispatch();

  const {
    geoList,
    OSList,
    asyncDomainsList,
    asyncDomainsPending,
    isCollapsedSidebar,
    pageSizeValue,
  } = useSelector(globalDataSelector);
  const { cloacaStatisticsList, cloacaLoading, cloacaTotalCount } =
    useSelector(statisticsSelector);

  const [searchParams, setSearchParams] = useSearchParams();
  const {
    sort: sortValue,
    page: pageValue,
    created_at_after: createdAtAfterParam,
    created_at_before: createdAtBeforeParam,
    flow: flowParam,
  } = useGetSearchParams();

  const updateSearchParams = useSetSearchParams(setSearchParams);

  const { t } = useTranslation();

  useEffect(() => {
    const domainParam = searchParams.get("flow");

    if (domainParam) {
      const domains = domainParam.split(",");
      const cleanedDomains = domains.map((domain) =>
        cleanDomain(decodeURIComponent(domain)),
      );

      // Якщо очищені домени не збігаються з оригінальними, оновлюємо параметри
      if (cleanedDomains.join(",") !== domains.join(",")) {
        updateSearchParams("flow", cleanedDomains.join(","));
      }
    }
  }, [searchParams, updateSearchParams]);

  const [selectedOS, selectedIP] = useSearchParamsOptions(searchParams, [
    os,
    ip_address,
  ]);

  const selectedDomain = useMemo(() => {
    return flowParam ? { value: flowParam, label: flowParam } : null;
  }, [flowParam]);

  const domainsListOptions = useSelectOptions(
      asyncDomainsList,
    "address",
    "address",
  );

  const geoListOptions = useMemo(() => {
    return geoList.map((item) => ({
      label: item.name,
      value: item.code,
      icon: item.flag,
    }));
  }, [geoList]);

  const OSListOptions = useMemo(() => {
    return OSList.map((item) => ({
      label: item,
      value: item,
    }));
  }, [OSList]);

  useEffect(() => {
    fetchCloacaStatsFilters(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (
      createdAtAfterParam ||
      createdAtBeforeParam ||
      sortDate.value === "custom_date"
    ) {
      setSortDate(sortDateOptions.find((item) => item.value === "custom_date"));
    } else {
      if (!sortValue) {
        setSortDate(sortDateOptions[0]);
      } else {
        setSortDate(sortDateOptions.find(({ value }) => value === sortValue));
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (selectedIP) {
      setIpList(selectedIP);
    }
  }, []);

  useEffect(() => {
    if (ipList.length > 0)
      updateSearchParams(ip_address, ipList.map((el) => el.value).join(","));
  }, [ipList]);

  useEffect(() => {
    const newGeoListOptions = searchParams
      .get(country_code)
      ?.split(",")
      .filter((value) => value !== "")
      .map((value) => {
        return geoListOptions.find((item) => item.value === value);
      });

    if (!newGeoListOptions || newGeoListOptions.length === 0) {
      setSelectedGeos([]);
    } else {
      setSelectedGeos(newGeoListOptions);
    }
  }, [searchParams, geoListOptions]);

  const handleFetchCloacaStats = () => {
    if (!selectedDomain) {
      setIsFlowInvalid(true);
      return;
    }
    setIsFlowInvalid(false);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;

    const promise = dispatch(
      fetchCloacaStats({
        params: getDefaultParams({
          searchParams,
          sort,
          defaultPageSize: pageSizeValue,
        }),
        signal,
      }),
    );

    promise.unwrap().catch((err) => {
      if (err?.message === INVALID_PAGE_ERROR) {
        updateSearchParams(page, 1);
        dispatch(
          fetchCloacaStats({
            params: getDefaultParams({
              searchParams,
              sort,
              defaultPageSize: pageSizeValue,
            }),
            signal,
          }),
        );
      }
    });

    promiseRef.current = promise;
  };

  useEffect(() => {
    return () => dispatch(resetCloacaStats());
  }, [dispatch]);

  const handleSetPageSize = (size) => {
    onSetPageSize(dispatch, size);
  };

  const clearFilters = () => {
    cleanSearchParams(searchParams, setSearchParams)
    setIpList([]);
    setIsFlowInvalid(false);
    setIpSelectErrorMessage("");
    setSelectedGeos([]);
  };

  return (
    <div
      className={`${s.cloacaStatsWrapper} ${isCollapsedSidebar === "true" ? s.collapsedSidebar : ""}`}
    >
      <Header/>
      <div className={s.pageContent}>
        <div className={s.filtersWrapper}>
          <div className={s.cloacaFilters}>
            <div className={s.selectDateWrapper}>
              <div className={s.selectIconWrapper}>
                <SvgSelector id="date-select-icon" />
              </div>
              <MultiSelect
                isClearable={false}
                options={sortDateOptions}
                setSelectedOptions={(value) =>
                  onSortDateHandler(value, updateSearchParams, setSortDate)
                }
                value={sortDate}
                placeholder={t("date")}
                isMulti={false}
              />
            </div>

            {sortDate?.value === "custom_date" && (
              <div className={s.datePickersWrapper}>
                <div className={s.datePicker}>
                  <MuiDatePicker
                      label={t("date_from")}
                      value={stringToDayJs(createdAtAfterParam)}
                      searchParamValue={created_at_after}
                      updateSearchParams={updateSearchParams}
                  />
                </div>
                <div className={s.datePicker}>
                  <MuiDatePicker
                      label={t("date_to")}
                      value={stringToDayJs(createdAtAfterParam)}
                      searchParamValue={created_at_before}
                      updateSearchParams={updateSearchParams}
                  />
                </div>
              </div>
            )}

            <div className={s.selectWrapper}>
              <div className={s.selectContainerValidation}>
                <div className={s.selectIconWrapper}>
                  <SvgSelector id="flow-select-icon" />
                </div>
                {/*<MultiSelect*/}
                {/*  extractDomain={true}*/}
                {/*  isClearable={true}*/}
                {/*  value={selectedDomain}*/}
                {/*  options={domainsListOptions}*/}
                {/*  setSelectedOptions={(option) => {*/}
                {/*    if (option) {*/}
                {/*      const { value } = option;*/}
                {/*      updateSearchParams(flow, value);*/}
                {/*      setIsFlowInvalid(false);*/}
                {/*    } else {*/}
                {/*      updateSearchParams(flow, "", true);*/}
                {/*    }*/}
                {/*  }}*/}
                {/*  placeholder={t("flow_required")}*/}
                {/*  isInvalid={isFlowInvalid}*/}
                {/*/>*/}
                <CustomAsyncSelect
                    extractDomain={true}
                    value={selectedDomain}
                    options={domainsListOptions}
                    isMulti={false}
                    setOptions={(option) => {
                      if (option) {
                        const {value} = option;
                        updateSearchParams(flow, value);
                        setIsFlowInvalid(false);
                      } else {
                        updateSearchParams(flow, "", true);
                      }
                    }
                    }
                    placeholder={t("flow_required")}
                    requestName={DOMAINS_REQUEST}
                    pageSize={50}
                    pending={asyncDomainsPending}
                    isInvalid={isFlowInvalid}
                />
              </div>
              {isFlowInvalid && (
                <div className={s.errorMessage}>{t("choose_flow")}</div>
              )}
            </div>

            <div className={s.selectWrapper}>
              <div className={s.selectIconWrapper}>
                <SvgSelector id="geo-select-icon" />
              </div>
              <GeoSelect
                isClearable={true}
                value={selectedGeos}
                options={geoListOptions}
                onChange={(values) =>
                  updateSearchParams(
                    country_code,
                    values.map((el) => el.value).join(","),
                  )
                }
                placeholder={t("geo")}
                isMulti={true}
              />
            </div>

            <div className={s.selectWrapper}>
              <div className={s.selectIconWrapper}>
                <SvgSelector id="os-select-icon" />
              </div>
              <MultiSelect
                isClearable={true}
                value={selectedOS}
                options={OSListOptions}
                setSelectedOptions={(values) =>
                  updateSearchParams(os, values.map((el) => el.value).join(","))
                }
                placeholder={t("os")}
                isMulti={true}
              />
            </div>

            <div className={s.selectWrapper}>
              <div className={s.selectContainerValidation}>
                <div className={s.selectIconWrapper}>
                  <SvgSelector id="ip-select-icon" />
                </div>
                <IpCreatableSelect
                  setErrorMessage={setIpSelectErrorMessage}
                  selectedOption={ipList}
                  setSelectedOption={(value) => {
                    setIpList(value);
                  }}
                  isInvalid={ipSelectErrorMessage}
                />
              </div>
              {ipSelectErrorMessage && (
                <div className={s.errorMessage}>{ipSelectErrorMessage}</div>
              )}
            </div>
            <div className={s.cleanBtnContainer}>
              {hasNonEmptyParams(searchParams) && (
                <button className={s.blueBorderBtn} onClick={clearFilters}>
                  {t("clear_button")}
                </button>
              )}
              <button
                className={s.whiteBorderBtn}
                onClick={handleFetchCloacaStats}
              >
                {t("apply")}
              </button>
            </div>
          </div>
        </div>

        <div className={s.pageInnerContent}>
          {!cloacaTotalCount && !cloacaLoading ? (
            <EmptyList />
          ) : cloacaLoading ? (
            <section className={s.body}>
              <table>
                <thead>
                  <tr>
                    <th>{t("time")}</th>
                    <th>{t("flow")}</th>
                    <th>{t("geo")}</th>
                    <th>{t("os")}</th>
                    <th>{t("browser")}</th>
                    <th>{t("languages")}</th>
                    <th>{t("mode")}</th>
                    <th>{t("status")}</th>
                  </tr>
                </thead>
              </table>
              <div className={s.skeletonWrapper}>
                <CustomSkeleton styles={s.skeletonTable} />
              </div>
            </section>
          ) : (
            <section className={s.body}>
              <table>
                <thead>
                  <tr>
                    <th>{t("time")}</th>
                    <th>{t("flow")}</th>
                    <th>{t("geo")}</th>
                    <th>{t("os")}</th>
                    <th>{t("browser")}</th>
                    <th>{t("languages")}</th>
                    <th>{t("mode")}</th>
                    <th>{t("status")}</th>
                  </tr>
                </thead>
                <tbody>
                  {cloacaStatisticsList?.map(
                    (
                      {
                        timestamp,
                        country_code,
                        os,
                        browser,
                        languages,
                        mode,
                        target,
                        flow,
                      },
                      index,
                    ) => {
                      const { date, time } = formatDateTime(timestamp);

                      const geoFlag = geoList.find(
                        (geoItem) => geoItem.code === country_code,
                      )?.flag;

                      return (
                        <tr key={index}>
                          <td>
                            <p>{date}</p>
                            <p style={{ marginTop: 4 }}>{time}</p>
                          </td>
                          <td>{flow}</td>
                          <td className={s.geoTd}>
                            <span className={s.value}>
                              <span
                                className={s.flagWrapper}
                                style={
                                  geoFlag
                                    ? {
                                        backgroundImage: `url(${geoFlag})`,
                                      }
                                    : {
                                        background: "grey",
                                      }
                                }
                              ></span>
                              <span>{country_code}</span>
                            </span>
                          </td>
                          <td>{os}</td>
                          <td>{browser}</td>
                          <td>{languages?.join(" | ")}</td>
                          <td>{mode}</td>
                          <td>
                            {target === 0 ? (
                              <p className={s.mismatch}>
                                <SvgSelector id="cross-icon" />
                                Несовпадение ОС/ хостинг/VPN
                              </p>
                            ) : (
                              <p className={s.content}>
                                <SvgSelector id="check-icon" />
                                Контент
                              </p>
                            )}
                          </td>
                        </tr>
                      );
                    },
                  )}
                </tbody>
              </table>
            </section>
          )}
        </div>

        <div
          className={`${s.paginationContainerStatistics} ${isCollapsedSidebar === "true" ? s.collapsed : ""}`}
        >
          <div className={s.countPageSizeContainer}>
            <div className={s.newRecordsCount}>
              {t("total")}: {!cloacaLoading && <span>{cloacaTotalCount}</span>}
            </div>
            <div className={s.pageSizeDropdownContainer}>
              <PageSizeDropdown
                setSize={handleSetPageSize}
                size={pageSizeValue}
              />
            </div>
          </div>
          {cloacaTotalCount > pageSizeValue && (
            <Pagination
              currentPage={parseInt(pageValue) || 1}
              setCurrentPage={(page) => {
                updateSearchParams(params.page, page);
                handleFetchCloacaStats();
              }}
              total={cloacaTotalCount}
              limit={pageSizeValue}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CloacaStats;
