import React, { useMemo, useState } from "react";
import s from "./redirectorCard.module.scss";
import MuiTooltip from "../../../../components/UiKit/MuiTooltip/MuiTooltip";
import SvgSelector from "../../../../components/SvgSelector/SvgSelector";
import GradientBorder from "../../../../components/GradientBorder/GradientBorder";
import Select from "react-select";
import {
  dropdownStyles,
  redirectDropdownStyles,
} from "../../../../components/UiKit/Popups/GetLinksPopup/GetLinksPopup";
import { useFormik } from "formik";
import * as Yup from "yup";
import PercentageBtn from "../PercentageBtn/PercentageBtn";
import {
  colorList,
  snackbar_error,
  snackbar_success,
} from "../../../../utils/vars/staticVariables";
import PrimaryBtn from "../../../../components/UiKit/Buttons/PrimaryBorderBtn/PrimaryBorderBtn";
import { distributePercentages } from "../../../../utils/helpers/distributePercentages";
import {
  editRedirector,
  redirectorSelector,
  setRedirectorCardsList,
} from "../../../../redux/slices/redirectorSlice";
import { useDispatch, useSelector } from "react-redux";
import useCustomSnackbar from "../../../../hooks/useCustomSnackbar";
import NewConfirmPopup from "../../../../components/UiKit/Popups/NewConfirmPopup/NewConfirmPopup";
import ApplicationSelect from "../../../../components/Selects/ApplicationSelect";
import { useTranslation } from "react-i18next";

const RedirectorCard = ({
  application_percentages,
  id,
  sub7,
  appList,
  user_id,
  onDeleteHandler,
}) => {
  const [isEditActive, setIsEditActive] = useState(false);
  const [newFieldTouched, setNewFieldTouched] = useState(false);
  const [selectTouched, setSelectTouched] = useState([]);
  const [isActiveRemovePopup, setActiveRemovePopup] = useState(false);

  const { editRedirectorPending, redirectorCardsList } =
    useSelector(redirectorSelector);
  const dispatch = useDispatch();

  const showSnackbar = useCustomSnackbar();

  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      user_id,
      application_percentages: application_percentages?.map(
        ({ application, percentage }) => ({
          percentage: Math.round(percentage),
          application: application.id,
          name: application.name,
          is_alive: application.is_alive,
          // isDisabled: !application.is_alive,
        }),
      ),
      sub7,
    },
    // validateOnChange: false, // Не викликаємо валідацію при зміні
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      application_percentages: Yup.array()
        .of(
          Yup.object().shape({
            percentage: Yup.number()
              .min(0, t("validation_not_less_0_percent")) // Дозволяємо 0
              .nullable()
              .required(t("set_percent")), // Вимагаємо значення для відсотків
            application: Yup.string()
              .required(t("choose_application"))
              // .test(
              //   "isAliveCheck",
              //   t("validation_inactive_application_percentage"),
              //   function (value) {
              //     const { percentage } = this.parent; // Отримуємо значення поля `percentage` з поточного об'єкта
              //     const is_alive = this.parent.is_alive; // Отримуємо значення поля `is_alive`
              //     // Перевірка: якщо додаток неактивний і процент більше 0
              //     if (!is_alive && percentage > 0) {
              //       return false;
              //     }
              //     return true;
              //   },
              // ),
          }),
        )
        .test("totalPercentage", function (application_percentages) {
          const totalPercentage = application_percentages.reduce(
            (sum, current) => sum + (current.percentage || 0),
            0,
          );

          if (totalPercentage > 100) {
            return this.createError({
              message: t("validation_percentage_greater_100"),
            });
          } else if (totalPercentage < 100) {
            return this.createError({
              message: t("validation_percentage_less_100"),
            });
          }

          return true;
        }),
    }),
    onSubmit: async (data, { setTouched }) => {
      const touchedFields = {
        application_percentages: formik.values.application_percentages.map(
          () => ({
            percentage: true,
            application: true,
          }),
        ),
      };

      setTouched(touchedFields, true);

      const filteredData = {
        ...data,
        application_percentages: data.application_percentages.map(
          ({ percentage, application }) => ({
            percentage,
            application,
          }),
        ),
      };

      const editRedirectorResult = await dispatch(
        editRedirector({ updatedData: filteredData, id }),
      );

      if (editRedirectorResult?.payload?.status === 201) {
        setIsEditActive(false);

        const elementIndex = redirectorCardsList?.findIndex(
          (item) => item.id === editRedirectorResult?.payload?.data?.id,
        );

        if (elementIndex !== -1) {
          const updatedList = [...redirectorCardsList];

          updatedList[elementIndex] = editRedirectorResult.payload.data;

          dispatch(setRedirectorCardsList(updatedList));
        }

        showSnackbar(t("redirector_is_edited"), snackbar_success);
      } else {
        showSnackbar(t("failed_to_edit_redirector"), snackbar_error);
      }
    },
  });

  const cutAppListOptions = useMemo(() => {
    return appList
      .filter(
        (item) =>
          !formik.values.application_percentages.some(
            ({ application }) => application === item.id,
          ),
      )
      .map((item) => ({
        label: item.name,
        value: item.id,
        is_alive: item.is_alive,
        name: "app_list",
        // isDisabled: !item.is_alive,
        icon: item.icon,
      }));
  }, [appList, formik.values.application_percentages]);

  const addNewApplicationPercentage = () => {
    const newApplication = {
      percentage: null,
      application: null,
      name: null,
    };

    formik.setFieldValue("application_percentages", [
      ...formik.values.application_percentages,
      newApplication,
    ]);

    setSelectTouched([...selectTouched, false]); // Додаємо новий запис у selectTouched
    setNewFieldTouched(false); // Скидаємо стан торкання для нового поля

    // Скидаємо touched для нового поля percentage, щоб валідація не спрацьовувала одразу
    formik.setTouched({
      ...formik.touched,
      application_percentages: [
        ...(formik.touched.application_percentages || []),
        { percentage: false, application: false },
      ],
    });
  };

  const onChangeApplication = (index, selectedOption) => {
    const copiedApplicationPercentages = [
      ...formik.values.application_percentages,
    ];

    copiedApplicationPercentages[index] = {
      ...copiedApplicationPercentages[index],
      application: selectedOption?.value || null,
      name: selectedOption?.label || null,
      is_alive: selectedOption?.is_alive || null,
    };

    formik.setFieldValue(
      "application_percentages",
      copiedApplicationPercentages,
    );

    const updatedTouched = [...selectTouched];
    updatedTouched[index] = true; // Встановлюємо, що цей Select було торкнуто
    setSelectTouched(updatedTouched);
  };

  const onChangePercentage = (index, value) => {
    const copiedApplicationPercentages = [
      ...formik.values.application_percentages,
    ];

    copiedApplicationPercentages[index] = {
      ...copiedApplicationPercentages[index],
      percentage: value,
    };

    formik.setFieldValue(
      "application_percentages",
      copiedApplicationPercentages,
    );

    const updatedTouched = [...selectTouched];
    updatedTouched[index] = true; // Встановлюємо, що поле було торкнуто
    setSelectTouched(updatedTouched);
  };

  const removeApplication = (indexToRemove) => {
    const updatedApplicationPercentages =
      formik.values.application_percentages.filter(
        (_, index) => index !== indexToRemove,
      );

    formik.setFieldValue(
      "application_percentages",
      updatedApplicationPercentages,
    );
  };

  const redistributePercentages = () => {
    // Обчислюємо загальний відсоток
    const totalPercentage = formik.values.application_percentages.reduce(
      (sum, current) => sum + (current.percentage || 0),
      0,
    );

    // Перевіряємо, чи є вже розподілені всі 100%
    if (totalPercentage >= 100) {
      return;
    }

    // Обчислюємо залишковий відсоток
    let remainingPercentage = 100 - totalPercentage;

    // Фільтруємо додатки, де процент не заданий або дорівнює 0
    const appsToDistribute = formik.values.application_percentages.filter(
      (item) => !item.percentage || item.percentage === 0,
    );

    if (appsToDistribute.length > 0) {
      // Розподіляємо залишковий відсоток між додатками
      const newPercentage = Math.floor(
        remainingPercentage / appsToDistribute.length,
      );

      // Перевірка, чи залишкова частина правильно розподілена
      let totalDistributed = newPercentage * appsToDistribute.length;
      let remainder = remainingPercentage - totalDistributed;

      const updatedApplicationPercentages =
        formik.values.application_percentages.map((item) => {
          if (!item.percentage || item.percentage === 0) {
            const addExtra = remainder > 0 ? 1 : 0; // Додаємо 1 якщо залишок більше 0
            remainder -= addExtra; // Зменшуємо залишок
            return {
              ...item,
              percentage: newPercentage + addExtra,
            };
          }
          return item;
        });

      // Оновлюємо значення у Formik
      formik.setFieldValue(
        "application_percentages",
        updatedApplicationPercentages,
      );
    }
  };

  // Перевірка, чи кнопка повинна бути активна
  const totalPercentage = formik.values.application_percentages.reduce(
    (sum, current) => sum + (current.percentage || 0),
    0,
  );

  const canRedistribute =
    formik.values.application_percentages.some(
      (item) => !item.percentage || item.percentage === 0,
    ) && totalPercentage < 100; // Дизейблимо, якщо сума відсотків вже 100

  return (
    <div
      className={`${s.redirectorCardWrapper} ${isEditActive ? s.active : ""}`}
    >
      <NewConfirmPopup
        icon={<SvgSelector id="popup-remove-icon" />}
        active={isActiveRemovePopup}
        setActive={setActiveRemovePopup}
        action={() => onDeleteHandler(id)}
        confirmBtnText={t("button_remove")}
        title={t("redirector_deletion")}
        description={
          <>
            {t("want_to_delete_redirect")}{" "}
            <span
              style={{
                color: "#fff",
                fontWeight: 500,
                marginLeft: 5,
                marginRight: 5,
              }}
            >
              {sub7}
            </span>
            ?
          </>
        }
      />
      {isEditActive ? (
        <form className={s.editContent} onSubmit={formik.handleSubmit}>
          <div className={s.header}>
            <div className={s.sub7}>
              sub7:
              <a
                target="_blank"
                href={`/my-flows?sub7=${sub7}`}
                rel="noreferrer"
              >
                {sub7}
              </a>
            </div>
          </div>
          <GradientBorder className={s.gradientBorderCustom} />
          <div className={s.applicationsTitle}>{t("applications")}</div>
          <div className={s.addApplicationContainer}>
            <button
              className={s.primaryBtn}
              onClick={() => {
                const distributedArr = distributePercentages(
                  formik.values.application_percentages,
                );

                formik.setFieldValue("application_percentages", distributedArr);
              }}
              type="button"
            >
              {t("distribute")} %
            </button>
            <button
              className={s.primaryBtn}
              disabled={!canRedistribute}
              onClick={redistributePercentages}
              type="button"
            >
              {t("autocomplete")} %
            </button>
          </div>
          <div className={s.applicationsContainer}>
            <div className={s.applicationsWrapper}>
              {formik.values.application_percentages?.map(
                ({ percentage, application, name, is_alive }, index) => {
                  const selectValue =
                    application && name
                      ? {
                          value: application,
                          label: name,
                          is_alive: is_alive,
                        }
                      : null;

                  const isInvalidSelect = Boolean(
                    formik.errors.application_percentages?.[index]
                      ?.application &&
                      (formik.touched.application_percentages?.[index]
                        ?.application ||
                        selectTouched[index]),
                  );

                  const isInvalidPercentage = Boolean(
                    formik.errors.application_percentages?.[index]
                      ?.percentage &&
                      (formik.touched.application_percentages?.[index]
                        ?.percentage ||
                        newFieldTouched),
                  );

                  return (
                    <div key={index} className={s.applicationOptionWrapper}>
                      <div className={s.selectWrapper}>
                        <ApplicationSelect
                          value={selectValue}
                          isClearable={false}
                          options={cutAppListOptions}
                          onChange={(selectedOption) =>
                            onChangeApplication(index, selectedOption)
                          }
                          isInvalid={isInvalidSelect}
                          isMulti={false}
                          styles={redirectDropdownStyles}
                        />
                        {/*<Select*/}
                        {/*  value={selectValue}*/}
                        {/*  isClearable={false}*/}
                        {/*  options={cutAppListOptions}*/}
                        {/*  onChange={(selectedOption) =>*/}
                        {/*    onChangeApplication(index, selectedOption)*/}
                        {/*  }*/}
                        {/*  isInvalid={isInvalidSelect}*/}
                        {/*  isMulti={false}*/}
                        {/*  placeholder="Выберите приложение"*/}
                        {/*  styles={redirectDropdownStyles}*/}
                        {/*  noOptionsMessage={() => "Нет опций"}*/}
                        {/*/>*/}
                        {isInvalidSelect && (
                          <div className={s.errorMessage}>
                            {
                              formik.errors.application_percentages[index]
                                .application
                            }
                          </div>
                        )}
                      </div>
                      <div className={s.wrapErrorContainer}>
                        <div className={s.wrapContainer}>
                          <div className={s.percentageWrapper}>
                            <PercentageBtn
                              percentageValue={percentage}
                              onChange={(value) =>
                                onChangePercentage(index, value)
                              }
                              hasError={isInvalidPercentage}
                              isAlive={selectValue?.is_alive}
                            />
                          </div>

                          {index > 0 ? (
                            <div className={s.actionsWrapper}>
                              <div className={s.actionsContainer}>
                                <div className={s.actions}>
                                  <button
                                    className={s.removeBtn}
                                    onClick={() => removeApplication(index)}
                                  >
                                    <MuiTooltip title={t("remove_application")}>
                                      <SvgSelector id="new-remove-icon" />
                                    </MuiTooltip>
                                  </button>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className={s.mockContainer}></div>
                          )}
                        </div>
                        {isInvalidPercentage && (
                          <div className={s.errorMessage}>
                            {
                              formik.errors.application_percentages[index]
                                .percentage
                            }
                          </div>
                        )}
                      </div>
                    </div>
                  );
                },
              )}
            </div>
            {typeof formik.errors.application_percentages === "string" && (
              <div className={s.errorMessageWrapper}>
                <div className={s.errorMessage}>
                  {formik.errors.application_percentages}
                </div>
              </div>
            )}
          </div>
          <div className={s.addApplicationContainer}>
            <button
              className={s.primaryBtn}
              onClick={addNewApplicationPercentage}
              type="button"
            >
              <SvgSelector id="plus-button" />
              {t("add")}
            </button>
          </div>
          <GradientBorder className={s.gradientBorderCustom} />
          <div className={s.buttons}>
            <button
              className={s.whiteBorderBtn}
              onClick={() => {
                formik.resetForm();
                setNewFieldTouched(false);
                setSelectTouched([]);
                setIsEditActive(false);
              }}
            >
              {t("button_cancel")}
            </button>
            <PrimaryBtn
              text={t("button_save")}
              submit={true}
              pending={editRedirectorPending}
              className={s.customPrimaryBtn}
            />
          </div>
        </form>
      ) : (
        <div className={s.displayContent}>
          <div className={s.header}>
            <div className={s.sub7}>
              sub7:{" "}
              <a
                target="_blank"
                href={`/my-flows?sub7=${sub7}`}
                rel="noreferrer"
              >
                {sub7}
              </a>
            </div>
            <div className={s.actionsWrapper}>
              <div className={s.actionsContainer}>
                <div className={s.actions}>
                  <button
                    className={s.manageTableFillBtn}
                    onClick={() => setIsEditActive(true)}
                  >
                    <MuiTooltip title={t("edit_redirect")}>
                      <SvgSelector id="edit-icon" />
                    </MuiTooltip>
                  </button>

                  <button
                    className={s.removeBtn}
                    onClick={() => setActiveRemovePopup(true)}
                  >
                    <MuiTooltip title={t("remove_redirect")}>
                      <SvgSelector id="new-remove-icon" />
                    </MuiTooltip>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <GradientBorder className={s.gradientBorderCustom} />
          <div className={s.applicationPercentagesWrapper}>
            <div className={s.applicationHeader}>
              <div className={s.cell}>{t("applications")}</div>
              <div className={s.cell}>{t("percentages")}</div>
              <div className={s.cell}>{t("click_throughs")}</div>
            </div>
            {application_percentages.map(
              ({ application, id, percentage, service_count }, index) => (
                <div className={s.applicationContainer} key={id}>
                  <div className={s.applicationName}>{application?.name}</div>
                  <div className={s.percentage}>
                    <span
                      className={s.colorDot}
                      style={{ background: colorList[index] }}
                    ></span>
                    {Math.round(percentage)}%
                  </div>
                  <div className={s.service_count}>{service_count}</div>
                </div>
              ),
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default RedirectorCard;
