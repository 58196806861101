import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import $api from "../../api/url";
import {
    getPageSize,
} from "../../storage/statisticsPageSize";
import {sidebarWidthValue} from "../../storage/sidebarWidth";

export const fetchAsyncDomainsList = createAsyncThunk(
    "domains/fetchAsyncDomainsList",
    async ({page, page_size, search}, {rejectWithValue}) => {
        try {
            const {data} = await $api.get(`/domains/domains/?page=${page}&page_size=${page_size}&search=${search}`);
            return data;
        } catch (error) {
            return rejectWithValue(error.response?.data || "Something went wrong");
        }
    }
);

export const fetchAsyncRedirectorsList = createAsyncThunk(
    "redirectors/fetchAsyncRedirectorsList",
    async ({page, page_size, search}, {rejectWithValue}) => {
        try {
            const {data} = await $api.get(`/redirectors/?page=${page}&page_size=${page_size}&sub7=${search}`);
            return data;
        } catch (error) {
            return rejectWithValue(error.response?.data || "Something went wrong");
        }
    }
);

export const fetchGeosList = createAsyncThunk(
    "application/fetchGeoList",
    async () => {
        try {
            const response = await $api.get("/flow-settings/geo");
            return response.data;
        } catch (error) {
            throw error;
        }
    },
);

export const fetchNotificationsList = createAsyncThunk(
    "notifications/fetchNotificationsList",
    async ({ page = 1 }, { rejectWithValue }) => {
        try {
            const response = await $api.get("/notifications/notifications/", {
                params: { page, page_size: 50 },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);



export const archiveAllNotifications = createAsyncThunk(
    "notifications/archiveAllNotifications",
    async () => {
        try {
            const response = await $api.post("/notifications/notifications/archive_all/");
            const { status } = response;

            return { status }
        } catch (error) {
            throw error;
        }
    },
);

export const readNotification = createAsyncThunk(
    "notifications/readNotification",
    async (id) => {
        try {
            const response = await $api.post(`/notifications/notifications/${id}/mark_as_read/`);
            const { status } = response;

            return { status }
        } catch (error) {
            throw error;
        }
    },
);


export const readAllNotifications = createAsyncThunk(
    "notifications/readAllNotifications",
    async () => {
        try {
            const response = await $api.post("/notifications/notifications/mark_all_as_read/");
            const { status } = response;

            return { status }
        } catch (error) {
            throw error;
        }
    },
);

export const archiveNotification = createAsyncThunk(
    "notifications/archiveNotification",
    async (id) => {
        try {
            const response = await $api.post(`/notifications/notifications/${id}/archive/`);
            const { status } = response;

            return { status }
        } catch (error) {
            throw error;
        }
    },
);

export const fetchUsersList = createAsyncThunk(
    "balance/fetchUsers",
    async () => {
        try {
            const {data} = await $api.get(`/users/users/`);
            return data;
        } catch (error) {
            throw error;
        }
    },
);

export const fetchOSList = createAsyncThunk("statistics/fetchOS", async () => {
    try {
        const {data} = await $api.get("/click-data/os/");

        return data;
    } catch (error) {
        throw error;
    }
});

const globalDataSlice = createSlice({
    name: "globalData",
    initialState: {
        domainsList: [],
        geoList: [],
        usersList: [],
        OSList: [],

        notifyPopup: false,
        exitPopup: false,
        isAdaptiveMenuActive: false,
        isCollapsedSidebar: sidebarWidthValue || "true",
        pageSizeValue: getPageSize(),

        technicalUsersListPending: false,
        technicalUsersList: [],
        technicalUsersCount: null,

        asyncDomainsList: [],
        asyncDomainsPending: false,

        asyncRedirectorsList: [],
        asyncRedirectorsPending: false,

        isNotificationPopupActive: false,

        notificationsList: [],
        fetchNotificationsPending: false,
        isFetchingMoreNotifications: false,
        notificationsTotalCount: null,
        hasUnreadNotifications: false,
        readAllNotificationsPending: false,
        archiveAllNotificationsPending: false,
    },
    reducers: {
        setIsCollapsedSidebar: (state, action) => {
            state.isCollapsedSidebar = action.payload;
        },
        setPageSizeValue: (state, action) => {
            state.pageSizeValue = action.payload;
        },

        setExitPopup: (state, action) => {
            state.exitPopup = action.payload;
        },

        setIsNotificationPopupActive: (state, action) => {
            state.isNotificationPopupActive = action.payload;
        },

        setNotifyPopup: (state, action) => {
            state.notifyPopup = action.payload;
        },

        setAdaptiveMenu: (state, action) => {
            state.isAdaptiveMenuActive = action.payload;
        },

        setNotificationsList: (state, action) => {
            state.notificationsList = action.payload;
        },

        setHasUnreadNotifications: (state, action) => {
            state.hasUnreadNotifications = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAsyncRedirectorsList.pending, (state) => {
                state.asyncRedirectorsPending = true;
            })
            .addCase(fetchAsyncRedirectorsList.fulfilled, (state, action) => {
                state.asyncRedirectorsPending = false;
                state.asyncRedirectorsList = [...state.asyncDomainsList, ...action.payload.results]; // Додаємо нові дані
            })
            .addCase(fetchAsyncRedirectorsList.rejected, (state, action) => {
                state.asyncRedirectorsPending = false;
            })

            .addCase(fetchNotificationsList.pending, (state, action) => {
                if (action.meta.arg.page === 1) {
                    state.fetchNotificationsPending = true;
                    state.notificationsList = [];
                } else {
                    state.isFetchingMoreNotifications = true;
                }
            })
            .addCase(fetchNotificationsList.fulfilled, (state, action) => {
                const {results, count, unread_notification} = action.payload

                state.fetchNotificationsPending = false;
                state.isFetchingMoreNotifications = false;

                if (action.meta.arg.page === 1) {
                    state.notificationsList = results;
                } else {
                    state.notificationsList = [...state.notificationsList, ...results];
                }

                state.notificationsTotalCount = count;
                state.hasUnreadNotifications = unread_notification;
            })
            .addCase(fetchNotificationsList.rejected, (state) => {
                state.fetchNotificationsPending = false;
                state.isFetchingMoreNotifications = false;
            })

            .addCase(readAllNotifications.pending, (state) => {
                state.readAllNotificationsPending = true
            })
            .addCase(readAllNotifications.fulfilled, (state) => {
                state.readAllNotificationsPending = false
            })
            .addCase(readAllNotifications.rejected, (state) => {
                state.readAllNotificationsPending = false
            })

            .addCase(archiveAllNotifications.pending, (state) => {
                state.archiveAllNotificationsPending = true
            })
            .addCase(archiveAllNotifications.fulfilled, (state) => {
                state.archiveAllNotificationsPending = false
            })
            .addCase(archiveAllNotifications.rejected, (state) => {
                state.archiveAllNotificationsPending = false
            })

            .addCase(fetchAsyncDomainsList.pending, (state) => {
                state.asyncDomainsPending = true;
            })
            .addCase(fetchAsyncDomainsList.fulfilled, (state, action) => {
                state.asyncDomainsPending = false;
                state.asyncDomainsList = [...state.asyncDomainsList, ...action.payload.results]; // Додаємо нові дані
            })
            .addCase(fetchAsyncDomainsList.rejected, (state, action) => {
                state.asyncDomainsPending = false;
            })

            .addCase(fetchGeosList.pending, (state) => {
                state.geoListPending = true;
            })
            .addCase(fetchGeosList.fulfilled, (state, action) => {
                state.geoList = action.payload;
                state.geoListPending = false;
            })
            .addCase(fetchGeosList.rejected, (state) => {
                state.geoListPending = false;
            })

            .addCase(fetchUsersList.pending, (state) => {
                state.usersList = [];
            })
            .addCase(fetchUsersList.fulfilled, (state, action) => {
                state.usersList = action.payload;
            })
            .addCase(fetchUsersList.rejected, (state) => {
                state.usersList = [];
            })

            .addCase(fetchOSList.pending, (state) => {
                state.OSList = [];
            })
            .addCase(fetchOSList.fulfilled, (state, action) => {
                state.OSList = action.payload.details;
            })
            .addCase(fetchOSList.rejected, (state, action) => {
                state.OSList = [];
            });
    },
});

export default globalDataSlice.reducer;

export const {
    setIsCollapsedSidebar,
    setExitPopup,
    setPageSizeValue,
    setAdaptiveMenu,
    setNotifyPopup,
    setIsNotificationPopupActive,
    setNotificationsList,
    setHasUnreadNotifications
} = globalDataSlice.actions;

export const globalDataSelector = (state) => state.globalData;
