import Select from "react-select";
import { selectStyles } from "./MultiSelect/MultiSelect";
import {useTranslation} from "react-i18next";

const customOption = (props) => {
  const { data, isFocused, isSelected } = props;

  const customStyle = {
    display: "flex",
    alignItems: "center",
    padding: "10px 8px 10px 16px",
    borderRadius: 20,
    cursor: "pointer",
    backgroundColor: isFocused ? "#1E2437" : "#171a21", // Логіка для фокуса
    color: isSelected ? "#F2F5FA" : "#F2F5FA", // Логіка для вибраного стану
    textAlign: "left",
    marginTop: 7,
    marginBottom: 7,
    "&:last-child": {
      // marginBottom: 7,
    },
    "&:active": {
      backgroundColor: "#1E2437",
    },
    "&:hover": {
      backgroundColor: "#1E2437",
    },
  };

  // Додаткова логіка для кольору, якщо `data.name` і `data.is_alive`
  // if (data?.name === "app_list" && !data.is_alive) {
  //     customStyle.color = "#d32f2f";
  // }

  return (
    <div {...props.innerProps} style={customStyle}>
      {props.data.icon && (
        <img
          src={props.data.icon}
          alt=""
          style={{ width: 20, marginRight: 10, borderRadius: "2px" }}
        />
      )}
      {props.data.label}
    </div>
  );
};

const GeoSelect = ({
  isClearable,
  value,
  options,
  onChange,
  isInvalid,
  isMulti,
  placeholder,
}) => {

  const { t } = useTranslation();

  return (
    <Select
      value={value}
      isClearable={isClearable}
      options={options}
      onChange={(selectedOption) => onChange(selectedOption)}
      isInvalid={isInvalid}
      isMulti={isMulti}
      placeholder={placeholder}
      styles={selectStyles}
      noOptionsMessage={() => t('no_options')}
      components={{ Option: customOption }}
    />
  );
};

export default GeoSelect;
