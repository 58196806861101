import React, { useEffect, useRef, useState } from "react";
import s from "./languageDropdown.module.scss";
import SvgSelector from "../SvgSelector/SvgSelector";
import i18next from "i18next";
import {getLanguage, setLanguage} from "../../storage/switchLanguage";

export const languageOptions = ["ua", "en", "ru"];

const LanguageDropdown = () => {
  const [isActive, setIsActive] = useState(false);

  const langDropdownRef = useRef(null);

  const onClickHandler = (lang) => {
    setLanguage(lang);
    i18next.changeLanguage(lang);
    setIsActive(false);
  };

  const handleClickOutside = (e) => {
    if (
      langDropdownRef.current &&
      !langDropdownRef.current.contains(e.target)
    ) {
      setIsActive(false);
    }
  };

  const handleEscClose = (e) => {
    if (e.key === "Escape") {
      setIsActive(false);
      if (document.activeElement) {
        document.activeElement.blur();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    document.addEventListener("keydown", handleEscClose);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("keydown", handleEscClose);
    };
  }, []);

  return (
    <div
      className={s.languageDropdownWrapper}
      tabIndex={0}
      ref={langDropdownRef}
    >
      <button
        className={s.selectedLang}
        onClick={(e) => setIsActive(!isActive)}
      >
        {getLanguage()} <SvgSelector id="language-dropdown-arrow" />
      </button>

      {/*{isActive && (*/}
        <ul className={`${s.langList} ${isActive ? s.active : ""}`}>
          {languageOptions.map((item, index) => (
            <li onClick={() => onClickHandler(item)} key={index}>
              <button className={s.langOptionBtn}>
                {item}
                {item === getLanguage() && (
                  <SvgSelector id="language-check-icon" />
                )}
              </button>
            </li>
          ))}
        </ul>
      {/*)}*/}
    </div>
  );
};

export default LanguageDropdown;
