import React, {useEffect, useMemo, useRef, useState} from "react";
import s from "../flows.module.scss";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {duplicateFlow, setFlowsList, setNote} from "../../../../redux/slices/flowSlice";
import GetLinksPopup from "../../../../components/UiKit/Popups/GetLinksPopup/GetLinksPopup";
import SvgSelector from "../../../../components/SvgSelector/SvgSelector";
import {
    ACTIVE_FLOWS,
    FLOW_STATUS_FILTER,
    FLOW_STATUS_MONEY,
    FLOW_STATUS_REVIEWS,
    snackbar_error,
    snackbar_info,
    snackbar_success,
    snackbar_warning,
} from "../../../../utils/vars/staticVariables";
import MuiTooltip from "../../../../components/UiKit/MuiTooltip/MuiTooltip";
import NewConfirmPopup from "../../../../components/UiKit/Popups/NewConfirmPopup/NewConfirmPopup";
import useCustomSnackbar from "../../../../hooks/useCustomSnackbar";
import {useSnackbar} from "notistack";
import {
    flowCreateLimit,
    noDomains,
    notEnoughMoney,
    // unsufficientFunds,
} from "../../../../utils/vars/errorMessages";
import SupportPopup from "../../../../components/UiKit/Popups/SupportPopup/SupportPopup";
import FlowTableGeoTooltip from "../../../../components/UiKit/FlowTableGeoTooltip/FlowTableGeoTooltip";
import FlowTableAppTooltip from "../../../../components/UiKit/ToolTips/FlowTableAppFieldTooltip/FlowTableAppTooltip";
import SetNotePopup from "../../../../components/UiKit/Popups/SetNotePopup/SetNotePopup";
import {useTranslation} from "react-i18next";
import {
    noDomainsNotifError,
    unsufficientFundsNotifError,
} from "../../../../utils/vars/notificationsMessages";
import {getDaysToExpiry} from "../../../../utils/helpers/getDaysToExpiry";
import {domain_address, search} from "../../Statistics/ClicksStats/ClicksStats";
import useSetSearchParams from "../../../../hooks/useSetSearchParams";

const FlowTd = ({
                    item,
                    getFlows,
                    onDeleteHandler,
                    duplicateFlowPending,
                    isPartner,
                    geosList,
                    activeFlow = true,
                    index,
                    flowsList = [],
                    statusColumn = false,
                    clearDomainParam
                }) => {
    const tooltipRef = useRef(null);

    const [isVisibleExpiringDomainTooltip, setIsVisibleExpiringDomainTooltip] = useState(false);
    const [activeRemovePopup, setActiveRemovePopup] = useState(false);
    const [copyLinksPopupIsActive, setCopyLinksPopup] = useState(false);
    const [activeCreateFlowPopup, setActiveCreateFlowPopup] = useState(false);
    const [flowActionsPopup, setFlowActionsPopup] = useState(false);
    const [isActiveSetNotePopup, setActiveSetNotePopup] = useState(false);
    const [activeFlowCreateLimitPopup, setActiveFlowCreateLimitPopup] =
        useState(false);
    const [noteText, setNoteText] = useState("");

    const flowActionPopupRef = useRef(null);
    const toggleFlowActionButtonRef = useRef(null);


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const showSnackbar = useCustomSnackbar();
    const {closeSnackbar} = useSnackbar();
    const [searchParams, setSearchParams] = useSearchParams();
    const updateSearchParams = useSetSearchParams(setSearchParams);

    const disabledDuplicate = !item?.settings;

    const handleClickOutsideActionsPopup = (event) => {
        if (
            flowActionPopupRef.current &&
            !flowActionPopupRef.current.contains(event.target) &&
            toggleFlowActionButtonRef.current &&
            !toggleFlowActionButtonRef.current.contains(event.target) // Перевірка, чи клік був на кнопці
        ) {
            setFlowActionsPopup(false); // Закриваємо попап, якщо клік поза ним і не на кнопці
        }
    };

    useEffect(() => {
        // Додаємо обробник подій при монтовані компонента
        document.addEventListener("mousedown", handleClickOutsideActionsPopup);

        // Видаляємо обробник подій при демонтуванні компонента
        return () => {
            document.removeEventListener("mousedown", handleClickOutsideActionsPopup);
        };
    }, []);

    const onDuplicateFlowHandler = async () => {
        const data = {flow_id: item.id};

        const duplicatePendingSnackbar = showSnackbar(
            `${t("notif_flow_duplication")} ${item?.domain?.address} ...`,
            snackbar_info,
            true,
        );

        const duplicateFlowResult = await dispatch(duplicateFlow(data)).unwrap();

        if (duplicateFlowResult?.status === 201) {
            updateSearchParams("content", ACTIVE_FLOWS)
            if (searchParams.get(domain_address) || searchParams.get(search)) {
                clearDomainParam()
            } else {
                dispatch(setFlowsList([duplicateFlowResult.data ,...flowsList]))
            }
            closeSnackbar(duplicatePendingSnackbar);
            showSnackbar(
                `${t("notif_the_flow")} ${item?.domain?.address} ${t("notif_duplicated")}`,
                snackbar_success,
            );
        } else {
            closeSnackbar(duplicatePendingSnackbar);

            if (duplicateFlowResult?.data?.details === noDomains) {
                return showSnackbar(t(noDomainsNotifError), snackbar_warning);
            }

            if (duplicateFlowResult?.data?.details === notEnoughMoney) {
                return showSnackbar(t(unsufficientFundsNotifError), snackbar_warning);
            }

            if (duplicateFlowResult?.data?.details === flowCreateLimit) {
                return setActiveFlowCreateLimitPopup(true);
            }

            showSnackbar(
                `${t("notif_failed_duplicate")} ${item?.domain?.address} ${t("notif_lowercase_flow")}`,
                snackbar_error,
            );
        }
    };

    const {geo = [], status = ""} = item?.settings || {};

    const toggleTooltip = () => setIsVisibleExpiringDomainTooltip(!isVisibleExpiringDomainTooltip);

    const tooltipEventHandlers = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
        ? {onClick: toggleTooltip}
        : {
            onMouseEnter: () => setIsVisibleExpiringDomainTooltip(true),
            onMouseLeave: () => setIsVisibleExpiringDomainTooltip(false),
        };

    const getStatusContent = (status) => {
        switch (status) {
            case "Filter":
                return (
                    <>
                        <SvgSelector id="cloacaStatusOn"/>
                        {t(status)}
                    </>
                );
            case "Review":
                return (
                    <>
                        <SvgSelector id="cloacaStatusReview"/>
                        {t(status)}
                    </>
                );
            case "Money":
                return (
                    <>
                        <SvgSelector id="cloacaStatusOff"/>
                        {t(status)}
                    </>
                );

            default:
                return <></>;
        }
    };

    const uniqueApplications = item?.redirector
        ?.flatMap((item) => item.application_percentages)
        .map((app) => ({name: app.application.name, icon: app.application.icon}))
        .reduce((acc, current) => {
            // Перевірка на унікальність за полем 'name'
            if (!acc.some((item) => item.name === current.name)) {
                acc.push(current);
            }
            return acc;
        }, []);

    const onEditFlowName = async (form) => {
        const setNoteResult = await dispatch(setNote(form));

        if (setNoteResult?.payload?.status === 200) {
            setActiveSetNotePopup(false);
            getFlows();
            showSnackbar(t("notif_renamed_flow"), snackbar_success);
        } else {
            showSnackbar(t("notif_failed_flow_renaming"), snackbar_error);
        }
    };

    const daysToExpiry = useMemo(() => {
        return getDaysToExpiry(item.domain.expired_date);
    }, [item?.domain?.expired_date]);

    const getTooltip = () => (
        <div
            ref={tooltipRef}
            style={
                index === 0
                    ? { transform: 'translateY(-30%)' }
                    : index >= flowsList.length - 4
                        ? { transform: 'translateY(-90%)' }
                        : null
            }
            className={`${s.domainTooltip} ${daysToExpiry > 1 ? s.orange : s.red}`}
        >
            {
                daysToExpiry < 2 && <span>{t('one_day_expiring_domain_text')}</span>
            }

            {
                daysToExpiry > 1 && <>
                    <span className={s.orangeUpperText}>
                        <span className={s.bold}>{t('attention')}</span>
                        {" "}
                        {t('domain_expires_in')}
                        {" "}
                        {daysToExpiry}
                        {" "}
                        {daysToExpiry > 4 && t('days_word_case_iv')}
                        {daysToExpiry > 1 && daysToExpiry < 5 && t('days_word_case_i')}
                        .
                        {" "}
                        {t('to_extend_domain')}
                    </span>
                    <span className={s.lowerOrangeText}>
                        {t('not_planing_to_renew')}
                    </span>
                </>
            }
        </div>
    )

    return (
        <tr>
            <td className={s.noteTd}>
                <SetNotePopup
                    active={isActiveSetNotePopup}
                    setActive={setActiveSetNotePopup}
                    flowId={item.id}
                    title={t("flow_name")}
                    notes={item.notes}
                    setNoteText={setNoteText}
                    icon={<SvgSelector id="note-popup-icon"/>}
                    onSetNoteHandler={(form) => onEditFlowName(form)}
                />
                <SupportPopup
                    icon={<SvgSelector id="popup-duplicate-icon"/>}
                    active={activeFlowCreateLimitPopup}
                    setActive={setActiveFlowCreateLimitPopup}
                    action={() => setActiveFlowCreateLimitPopup(false)}
                    title={t("duplicate_flow_title")}
                    description={t("duplicate_limit_message")}
                />
                <NewConfirmPopup
                    icon={<SvgSelector id="popup-duplicate-icon"/>}
                    active={activeCreateFlowPopup}
                    setActive={setActiveCreateFlowPopup}
                    action={() => onDuplicateFlowHandler()}
                    confirmBtnText={t("continue")}
                    title={t("duplicate_flow_title")}
                    description={
                        isPartner
                            ? `${t("duplicate_flow")} ${item?.domain?.address} ${t('notif_lowercase_flow')}?`
                            : t("duplicate_popup_content")
                    }
                    subDescription={t('expiring_domain_popup_warning')}
                />
                <NewConfirmPopup
                    icon={<SvgSelector id="popup-remove-icon"/>}
                    active={activeRemovePopup}
                    setActive={setActiveRemovePopup}
                    action={() => onDeleteHandler(item.id, item?.domain?.address)}
                    confirmBtnText={t("button_remove")}
                    title={t("flow_remove_delete_title")}
                    description={t("remove_flow_popup_content")}
                />
                <GetLinksPopup
                    redirectors={item?.redirector}
                    active={copyLinksPopupIsActive}
                    domain={item?.domain?.address}
                    setActive={setCopyLinksPopup}
                />

                <div className={s.editNoteField}>
                    <button
                        className={s.noteTextBtn}
                        onClick={activeFlow ? () => setActiveSetNotePopup(true) : undefined}
                    >
                        <div className={s.noteText}>{item.notes}</div>
                        <MuiTooltip title={item.notes}></MuiTooltip>
                        {activeFlow && <SvgSelector id="edit-icon"/>}
                    </button>
                </div>

            </td>
            {
                statusColumn && <td>{t('status_column')}</td>
            }
            <td
                className={s.domainTd}
            >
                <div className={s.domainTdContent} style={!activeFlow ? {color: '#D02533'} : null}>
                    <span className={s.domainName}>{item?.domain?.address || ""}</span>
                    {
                        activeFlow &&
                        daysToExpiry <= 14
                        && <button
                            {...tooltipEventHandlers}
                            className={`${s.tooltipButton} ${daysToExpiry > 1 ? s.orange : s.red}`}
                        >
                            <SvgSelector id="tooltip-btn"/>
                            {
                                isVisibleExpiringDomainTooltip && getTooltip()
                            }
                        </button>
                    }
                </div>

            </td>

            <td
                style={{position: "relative"}}
                className={`${geo?.length > 0 ? s.geoTd : s.mocked}`}
            >
                <div className={s.tdContentContainer}>
                    <FlowTableGeoTooltip geos={geo} geosList={geosList}>
                        <div className={s.iterativeContentWrapper}>
                            {geo.map(({id, code}) => {
                                const foundFlag = geosList.find((item) => item.id === id)?.flag;

                                return (
                                    <span className={s.value} key={id}>
                    <span
                        className={s.iconWrapper}
                        style={{
                            backgroundImage: foundFlag
                                ? `url(${foundFlag})`
                                : "none",
                        }}
                    ></span>
                    <span>{code}</span>
                  </span>
                                );
                            })}
                        </div>
                    </FlowTableGeoTooltip>
                </div>
            </td>
            <td className={s.statusTd}>
                <div className={s.statusIndicator}>{getStatusContent(status)}</div>
            </td>
            <td
                className={`${uniqueApplications?.length > 0 ? s.applicationTd : s.mocked}`}
            >
                <div className={s.tdContentContainer}>
                    <FlowTableAppTooltip uniqueApplications={uniqueApplications}>
                        <div className={s.applicationContentWrapper}>
                            {uniqueApplications.map(({name, icon}, index) => (
                                <span className={s.appValue} key={index}>
                  <span
                      className={s.iconWrapper}
                      style={{
                          backgroundImage: icon ? `url(${icon})` : "none",
                      }}
                  ></span>
                  <span>{name}</span>
                </span>
                            ))}
                        </div>
                    </FlowTableAppTooltip>
                </div>
            </td>

            <td className={s.actionsTd}>
                {flowActionsPopup && (
                    <ul className={s.flowActionsPopup} ref={flowActionPopupRef}>
                        <li onClick={() => setFlowActionsPopup(false)}>
                            <a
                                href={`/stats/clicks?domain=${item?.domain?.address}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <SvgSelector id="new-stats-icon"/>{" "}
                                <span>{t("go_to_clicks_stat_option")}</span>
                            </a>
                        </li>

                        <li>
                            <button
                                onClick={() => {
                                    setActiveCreateFlowPopup(true);
                                    setFlowActionsPopup(false);
                                }}
                                disabled={disabledDuplicate || duplicateFlowPending}
                            >
                                <SvgSelector id="duplicate"/>
                                <span>
                  {duplicateFlowPending
                      ? t("duplicating")
                      : t("duplicate_option")}
                </span>
                            </button>
                        </li>

                        <li>
                            <button
                                onClick={() => {
                                    setActiveRemovePopup(true);
                                    setFlowActionsPopup(false);
                                }}
                            >
                                <SvgSelector id="new-remove-icon"/>
                                <span>{t("button_remove")}</span>
                            </button>
                        </li>
                    </ul>
                )}

                <div className={s.actionsWrapper}>
                    <div className={s.actionsContainer}>

                        {
                            activeFlow && <div className={s.actions}>
                                {/*<button*/}
                                {/*    className={`${s.manageTableFillBtn} ${s.linksBtn} ${s.attentionBtn}`}*/}
                                {/*>*/}
                                {/*    <MuiTooltip title={t("pay_attention_to_the_flow_status")}>*/}
                                {/*        <SvgSelector id="attention-icon"/>*/}
                                {/*    </MuiTooltip>*/}
                                {/*</button>*/}

                                <button
                                    className={`${s.manageTableStrokeBtn} ${s.linksBtn}`}
                                    onClick={() => setCopyLinksPopup(true)}
                                >
                                    <MuiTooltip title={t("get_links_tooltip")}>
                                        <SvgSelector id="new-links-icon"/>
                                    </MuiTooltip>
                                </button>


                                <Link
                                    to={`/my-flows/edit/${item.id}`}
                                    className={`${s.manageTableStrokeBtn} ${s.linksBtn}`}
                                >
                                    <MuiTooltip title={t("edit_option")}>
                                        <SvgSelector id="old-edit-icon"/>
                                    </MuiTooltip>
                                </Link>

                                <button
                                    className={s.removeBtn}
                                    onClick={() => setFlowActionsPopup(!flowActionsPopup)}
                                    ref={toggleFlowActionButtonRef}
                                >
                                    <MuiTooltip title={t('more')}>
                                        <SvgSelector id="flow-menu"/>
                                    </MuiTooltip>
                                </button>
                            </div>
                        }

                        {
                            !activeFlow && <div className={s.actions}>
                                <button
                                    className={`${s.manageTableFillBtn} ${s.linksBtn}`}
                                    onClick={() => {
                                        setActiveCreateFlowPopup(true);
                                    }}
                                    disabled={disabledDuplicate || duplicateFlowPending}
                                >
                                    <MuiTooltip title={duplicateFlowPending
                                        ? t("duplicating")
                                        : !item.settings ? t('set_whitepage') : t("duplicate_option")}>
                                        <SvgSelector id="duplicate"/>
                                    </MuiTooltip>
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </td>
        </tr>
    );
};

export default FlowTd;
