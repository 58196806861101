import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import $api from "../../api/url";

export const fetchAppList = createAsyncThunk(
  "application/fetchAppList",
  async () => {
    try {
      const response = await $api.get("/applications/");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

export const fetchAppListWithSearch = createAsyncThunk(
  "application/fetchAppListWithSearch",
  async (searchParams) => {
    try {
      const response = await $api.get(`/applications/?` + searchParams);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

const applicationSlice = createSlice({
  name: "application",
  initialState: {
    appList: [],
    appListLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAppList.pending, (state) => {
        state.appListLoading = true;
        state.appList = [];
      })
      .addCase(fetchAppList.fulfilled, (state, { payload }) => {
        state.appList = payload;
        state.appListLoading = false;
      })
      .addCase(fetchAppList.rejected, (state) => {
        state.appListLoading = false;
        state.appList = [];
      })

      .addCase(fetchAppListWithSearch.pending, (state) => {
        state.appListLoading = true;
        state.appList = [];
      })
      .addCase(fetchAppListWithSearch.fulfilled, (state, { payload }) => {
        state.appList = payload;
        state.appListLoading = false;
      })
      .addCase(fetchAppListWithSearch.rejected, (state) => {
        state.appListLoading = false;
        state.appList = [];
      })
  },
});

// export const { } = applicationSlice.actions;

export default applicationSlice.reducer;

export const applicationSelector = (state) => state.application;
