import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {animated, useSpring} from "@react-spring/web";
import s from "./categoryPriority.module.scss";
import SvgSelector from "../../../../../components/SvgSelector/SvgSelector";
import {useDispatch, useSelector} from "react-redux";
import React, {useState} from "react";
import {
    closestCenter,
    DndContext,
    PointerSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import GradientBorder from "../../../../../components/GradientBorder/GradientBorder";
import EmptyList from "../../../../../components/EmptyList/EmptyList";
import CustomSkeleton from "../../../../../components/UiKit/CustomSkeleton/CustomSkeleton";
import {managementSelector, setCategoryPriority} from "../../../../../redux/slices/managementSlice";
import {API} from "../../../../../api/url";
import {useTranslation} from "react-i18next";
import useCustomSnackbar from "../../../../../hooks/useCustomSnackbar";
import {snackbar_error, snackbar_success} from "../../../../../utils/vars/staticVariables";
import FlowTableAppTooltip from "../../../../../components/UiKit/ToolTips/FlowTableAppFieldTooltip/FlowTableAppTooltip";

const SortableRow = ({category}) => {
    const {name: categoryName, position, applications} = category;

    const {attributes, listeners, setNodeRef, transform, isDragging} =
        useSortable({id: `${categoryName}-${position}`}); // Використовуємо унікальний комбінований id

    const style = useSpring({
        transform: transform
            ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
            : "translate3d(0px, 0px, 0)",
        config: {tension: 0, friction: 20},
    });

    return (
        <animated.tr
            ref={setNodeRef}
            style={{
                ...style,
                boxShadow: isDragging ? "6px 13px 14px 0px #0075FF33" : "none",
                transform: transform
                    ? `translate3d(0, ${transform.y}px, 0)`
                    : undefined,
            }}
            {...attributes}
            {...listeners}
            className={`${isDragging ? s.dragTr : ""}`}
        >
            <td className={s.dragIconTd}>
                <SvgSelector id="drag-n-drop-icon"/>
            </td>
            <td className={s.categoryName}>{categoryName}</td>
            {/*<td className={s.appsWrapper}>*/}


            {/*    {*/}
            {/*        applications.map(({icon, name}) => {*/}
            {/*            return (*/}
            {/*                <div className={s.appValue}>*/}
            {/*        <span*/}
            {/*            className={s.iconWrapper}*/}
            {/*            style={{*/}
            {/*                backgroundImage: icon ? `url(${API + icon})` : "none",*/}
            {/*            }}*/}
            {/*        ></span>*/}
            {/*                    <span>{name}</span>*/}
            {/*                </div>*/}
            {/*            )*/}
            {/*        })*/}
            {/*    }*/}
            {/*</td>*/}

            <td
                className={`${applications?.length > 0 ? s.applicationTd : s.mocked}`}
            >
                <div className={s.tdContentContainer}>
                    <FlowTableAppTooltip uniqueApplications={applications} fullUrlPath={false}>
                        <div className={s.applicationContentWrapper}>
                            {applications.map(({name, icon}, index) => (
                                <span className={s.appValue} key={index}>
                  <span
                      className={s.iconWrapper}
                      style={{
                          backgroundImage: icon ? `url(${API + icon})` : "none",
                      }}
                  ></span>
                  <span>{name}</span>
                </span>
                            ))}
                        </div>
                    </FlowTableAppTooltip>
                </div>
            </td>


        </animated.tr>
    );
};

const CategoryPriority = () => {
    const {categoryPriorityPending, categoryPriorityList, setCategoryPriorityPending} = useSelector(managementSelector);
    const [itemsOrderList, setItemsOrderList] = useState(categoryPriorityList);
    const dispatch = useDispatch();

    const {t} = useTranslation();

    const showSnackbar = useCustomSnackbar();

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 10,
            },
        })
    );

    const handleDragEnd = (event) => {
        const {active, over} = event;

        if (!active || !over || active.id === over.id) return;

        setItemsOrderList((prevList) => {
            const oldIndex = prevList.findIndex(
                (category) => `${category.name}-${category.position}` === active.id
            );
            const newIndex = prevList.findIndex(
                (category) => `${category.name}-${category.position}` === over.id
            );

            if (oldIndex === -1 || newIndex === -1) return prevList;

            return arrayMove(prevList, oldIndex, newIndex);
        });
    };

    const onSaveOrder = async () => {
        const updatedItemsOrderList = itemsOrderList.map((item, index) => ({
            ...item,
            position: index + 1
        }));

        const requestResult = await dispatch(setCategoryPriority(updatedItemsOrderList))

        if (requestResult?.payload?.status === 200) {
            showSnackbar(t('category_priority_changed'), snackbar_success);
        } else {
            showSnackbar(t('failed_to_change_category_priority'), snackbar_error);
        }

    }

    return (
        <div className={s.categoryPriorityWrapper}>
            <GradientBorder className={s.gradientBorderCustom}/>
            {/*<div className={s.tabHeader}>*/}
            {/*    Порядок приложений для отображения в интерфейсе*/}
            {/*</div>*/}
            <div className={s.pageInnerContent}>
                <div className={s.innerContentContainer}>
                    {itemsOrderList.length === 0 && !categoryPriorityPending ? (
                        <EmptyList/>
                    ) : categoryPriorityPending ? (
                        <section className={s.body}>
                            <table>
                                <tr>
                                    <th></th>
                                    <th>{t('category_name')}</th>
                                    <th>{t('available_apps')}
                                    </th>
                                </tr>
                            </table>
                            <div className={s.skeletonWrapper}>
                                <CustomSkeleton styles={s.skeletonTable}/>
                            </div>
                        </section>
                    ) : (
                        <DndContext
                            sensors={sensors}
                            collisionDetection={closestCenter}
                            onDragEnd={handleDragEnd}
                        >
                            <SortableContext
                                items={itemsOrderList.map((category) => `${category.name}-${category.position}`)} // Використовуємо комбінований id
                                strategy={verticalListSortingStrategy}
                            >
                                <section className={s.body}>
                                    <table>
                                        <thead>
                                        <tr>
                                            <th></th>
                                            <th>{t('category_name')}</th>
                                            <th>{t('available_apps')}
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {itemsOrderList.map((category) => (
                                            <SortableRow key={`${category.name}-${category.position}`}
                                                         category={category}/>
                                        ))}
                                        </tbody>
                                    </table>
                                </section>
                            </SortableContext>
                        </DndContext>
                    )}
                </div>
            </div>
            <div className={s.buttons}>
                <button
                    className={s.whiteBorderBtn}
                    type="button"
                    onClick={() => setItemsOrderList(categoryPriorityList)}
                >
                    {t('button_cancel')}
                </button>

                <button
                    className={s.blueBorderBtn}
                    onClick={onSaveOrder}
                    disabled={setCategoryPriorityPending}
                >{t('button_save')}
                </button>
            </div>
        </div>
    );
};

export default CategoryPriority;
