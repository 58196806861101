import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import dayjs from "dayjs";
import $api from "../../api/url";
import { errorMessage } from "../../api/helpers/scripts";
import { created_at_before } from "../../pages/Authorized/Statistics/ClicksStats/ClicksStats";
import { ABORTED_ERROR } from "../../utils/vars/staticVariables";

export const fetchUserBalance = createAsyncThunk(
  "balance/fetchUserBalance",
  async () => {
    try {
      const { data } = await $api.get(`/finance/wallet/get_user_balance/`);
      return data;
    } catch (error) {
      throw error;
    }
  },
);

export const fetchCryptoWallet = createAsyncThunk(
  "balance/fetchCryptoWallet",
  async () => {
    try {
      const { data } = await $api.get(`/finance/wallet/get_crypto_wallet/`);
      return data;
    } catch (error) {
      throw error;
    }
  },
);

export const fetchBalances = createAsyncThunk(
  "balance/fetchBalances",
  async (params, { signal, rejectWithValue }) => {
    try {
      const { data } = await $api.get("/finance/get-all-balances?" + params, {
        signal,
      });
      return data;
    } catch (error) {
      let err = errorMessage(error);

      if (axios.isCancel(error)) {
        return rejectWithValue();
      }

      throw new Error(err);
    }
  },
);

export const exportBalances = createAsyncThunk(
    "finance/exportBalances",
    async ({ params }) => {
      try {
        const filteredParams = {};

        const createdAtAfter = params.get("created_at_after");
        const createdAtBefore = params.get("created_at_before");

        if (createdAtAfter) {
          filteredParams.created_at_after = createdAtAfter;
        }
        if (createdAtBefore) {
          filteredParams.created_at_before = createdAtBefore;
        }

        const response = await $api.get(
            `/finance/generate-report-refill/`,
            {
              params: filteredParams,
              responseType: "arraybuffer",
              headers: {
                Accept: "*/*"
              },
            }
        );

        const { data, status } = response;
        return { data, status };
      } catch (error) {
        const { status, data } = error.response || {};
        return { data, status };
      }
    }
);


export const exportTransactions = createAsyncThunk(
    "finance/exportTransactions",
    async ({ params }) => {
      try {
        const filteredParams = {};

        const createdAtAfter = params.get("created_at_after");
        const createdAtBefore = params.get("created_at_before");

        if (createdAtAfter) {
          filteredParams.created_at_after = createdAtAfter;
        }
        if (createdAtBefore) {
          filteredParams.created_at_before = createdAtBefore;
        }

        const response = await $api.get(
            '/finance/generate-report/',
            {
              params: filteredParams,
              responseType: "arraybuffer",
              headers: {
                Accept: "*/*",
              },
            }
        );

        const { data, status } = response;
        return { data, status };
      } catch (error) {
        const { status, data } = error.response || {};
        return { data, status };
      }
    }
);


export const fetchTransactions = createAsyncThunk(
  "finance/fetchTransactions",
  async (params, { signal, rejectWithValue }) => {
    try {
      const copiedParams = new URLSearchParams(params);
      const createdAtBefore = copiedParams.get(created_at_before);
      if (
        createdAtBefore &&
        dayjs(createdAtBefore, "YYYY-MM-DD", true).isValid()
      ) {
        const newDate = dayjs(createdAtBefore)
          .subtract(1, "day")
          .format("YYYY-MM-DD");
        copiedParams.set(created_at_before, newDate);
      }

      const { data } = await $api.get(
        "/finance/transactions/?" + copiedParams,
        {
          signal,
        },
      );
      return data;
    } catch (error) {
      let err = errorMessage(error);

      if (axios.isCancel(error)) {
        return rejectWithValue();
      }

      throw new Error(err);
    }
  },
);

const financeSlice = createSlice({
  name: "finance",
  initialState: {
    balanceLoading: false,
    balance: 0,
    wallet: null,
    transactionsLoading: false,
    transactionsList: [],
    topUpBalanceIsActive: false,
    totalCount: null,
    transactionsGlobalTotal: null,
    balancesList: [],
    balancesPending: false,
    exportTransactionsPending: false,
    exportBalancesPending: false,
  },
  reducers: {
    setTopUpBalanceActive: (state, action) => {
      state.topUpBalanceIsActive = action.payload;
    },
    setBalance: (state, action) => {
      state.balance = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserBalance.pending, (state) => {
        state.balanceLoading = true;
      })
      .addCase(fetchUserBalance.fulfilled, (state, action) => {
        state.balance = action.payload.user_balance;
        state.balanceLoading = false;
      })
      .addCase(fetchUserBalance.rejected, (state) => {
        state.balanceLoading = false;
      })

      .addCase(fetchCryptoWallet.fulfilled, (state, action) => {
        state.wallet = action.payload?.crypto_currency_wallet;
      })

      .addCase(exportTransactions.pending, (state) => {
        state.exportTransactionsPending = true;
      })
      .addCase(exportTransactions.fulfilled, (state) => {
        state.exportTransactionsPending = false;
      })
      .addCase(exportTransactions.rejected, (state) => {
        state.exportTransactionsPending = false;
      })

      .addCase(exportBalances.pending, (state) => {
        state.exportBalancesPending = true;
      })
      .addCase(exportBalances.fulfilled, (state) => {
        state.exportBalancesPending = false;
      })
      .addCase(exportBalances.rejected, (state) => {
        state.exportBalancesPending = false;
      })

      .addCase(fetchTransactions.pending, (state) => {
        state.transactionsList = [];
        state.transactionsLoading = true;
        state.transactionsGlobalTotal = null;
      })
      .addCase(fetchTransactions.fulfilled, (state, { payload }) => {
        const { results = {}, count = null } = payload || {};

        state.transactionsGlobalTotal = results?.global_total;
        state.transactionsList = results?.transactions;
        state.totalCount = count;
        state.transactionsLoading = false;
      })
      .addCase(fetchTransactions.rejected, (state, { error }) => {
        const { message } = error;

        state.transactionsLoading = message === ABORTED_ERROR;
        state.transactionsList = [];
        state.transactionsGlobalTotal = null;

        if (message !== ABORTED_ERROR) {
          state.totalCount = 0;
        }
      })

      .addCase(fetchBalances.pending, (state) => {
        state.balancesPending = true;
        state.balancesList = [];
      })
      .addCase(fetchBalances.fulfilled, (state, { payload }) => {
        const { results, count } = payload;

        state.balancesList = results;
        state.totalCount = count;
        state.balancesPending = false;
      })
      .addCase(fetchBalances.rejected, (state, { error }) => {
        const { message } = error;

        state.balancesPending = message === ABORTED_ERROR;
        state.balancesList = [];

        if (message !== ABORTED_ERROR) {
          state.totalCount = 0;
        }
      });
  },
});

export const {
  setTopUpBalanceActive,
  setBalance,
} = financeSlice.actions;

export default financeSlice.reducer;

export const financeSelector = (state) => state.finance;
