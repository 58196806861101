import { languageOptions } from "../components/LanguageDropdown/LanguageDropdown";

export const getLanguage = () => {
  if (typeof localStorage !== "undefined") {
    const lang = localStorage.getItem("language");
    return languageOptions.includes(lang) ? lang : "ua";
  }
  return "ua";
};

export const setLanguage = (lang) => {
  localStorage.setItem("language", lang);
};