import React from "react";
import { useTranslation } from "react-i18next";
import s from "./emptyList.module.scss";

const EmptyList = () => {
  const { t } = useTranslation();

  return <div className={s.emptyList}>{t("records_not_found")}</div>;
};

export default EmptyList;
