import React, { useRef, useState } from "react";
import s from "./changePassword.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  globalDataSelector,
  setAdaptiveMenu,
} from "../../../redux/slices/globalDataSlice";
import { setTopUpBalanceActive } from "../../../redux/slices/financeSlice";
import SvgSelector from "../../../components/SvgSelector/SvgSelector";
import {getUser, is_tech_user} from "../../../storage/user";
import { useFormik } from "formik";
import * as Yup from "yup";
import { authSelector, changePassword } from "../../../redux/slices/authSlice";
import PrimaryBtn from "../../../components/UiKit/Buttons/PrimaryBorderBtn/PrimaryBorderBtn";
import { logoutHandler } from "../../../api/helpers/handlers";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageDropdown from "../../../components/LanguageDropdown/LanguageDropdown";
import {managementSelector} from "../../../redux/slices/managementSlice";
import Header from "../../../components/Partials/Header/Header";

const ChangePassword = () => {
  const [isShowedOldPassword, setShowedOldPassword] = useState(false);
  const [isShowedNewPassword1, setShowedNewPassword1] = useState(false);
  const [isShowedNewPassword2, setShowedNewPassword2] = useState(false);

  const oldPasswordInputRef = useRef(null);
  const newPassword1InputRef = useRef(null);
  const newPassword2InputRef = useRef(null);

  const { isCollapsedSidebar, isAdaptiveMenuActive } =
    useSelector(globalDataSelector);

  const { changePasswordPending } = useSelector(authSelector);
  const { standardClickPriceValue } = useSelector(managementSelector);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { email, first_name, offer } = getUser()?.user || {};

  const handleShowPasswordClick = (e, ref, state, action) => {
    e.preventDefault();
    action(!state);

    if (ref.current) {
      ref.current.focus();
    }
  };

  const formik = useFormik({
    initialValues: {
      new_password: "",
      re_new_password: "",
      current_password: "",
    },
    validationSchema: Yup.object({
      new_password: Yup.string()
        .min(10, t("passwordMin10Characters"))
        .matches(/[A-Z]/, t("passwordMin1CapitalLetter"))
        .matches(/[a-z]/, t("passwordMin1SmallLetter"))
        .matches(/[0-9]/, t("passwordMin1Digit"))
        .matches(
          /[!@#$%^&*(),.?":{}|<>]/,
          t("password_must_contain_1_special_symbol"),
        )
        .required(t("requiredField")),
      re_new_password: Yup.string()
        .oneOf([Yup.ref("new_password"), null], t("passwordsMustMatch"))
        .required(t("requiredField")),
      current_password: Yup.string().required(t("requiredField")),
    }),
    onSubmit: async (form, { setFieldError }) => {
      form.current_password = form.current_password.trim();
      form.new_password = form.new_password.trim();
      form.re_new_password = form.re_new_password.trim();

      const changePasswordResult = await dispatch(changePassword(form));

      const dataResponse = changePasswordResult?.payload?.data;

      if (changePasswordResult?.payload?.status === 204) {
        logoutHandler();
      } else if (
        dataResponse?.current_password?.includes("Invalid password.")
      ) {
        return setFieldError(
          "current_password",
          t("current_password_does_not_match"),
        );
      } else if (
        dataResponse?.new_password?.includes(
          "The password is too similar to the email address.",
        )
      ) {
        return setFieldError(
          "current_password",
          t("validation_password_different_from_email"),
        );
      } else if (
        dataResponse?.new_password?.includes("This password is too common.")
      ) {
        return setFieldError(
          "current_password",
          t("validation_common_password"),
        );
      }
    },
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
  });

  return (
    <div
      className={`${s.changePasswordPageWrapper} ${isCollapsedSidebar === "true" ? s.collapsedSidebar : ""}`}
    >
      <Header />
      <div className={s.pageContent}>
        <div className={s.pageTitle}>
          <SvgSelector id="personal-account-icon" />
          {t("personal_account")}
        </div>
        <div className={s.contentWrapper}>
          <div className={s.personalDataInfoContainer}>
            <p className={s.title}>{t("personal_info")}</p>
            <div className={s.inputsWrapper}>
              <div className={s.inputWrapper}>
                <p>{t("email")}</p>
                <input
                  type="text"
                  className={s.textInput}
                  value={email}
                  placeholder={t("type_email")}
                />
              </div>
              <div className={s.inputWrapper}>
                <p>{t("username")}</p>
                <input
                  type="text"
                  className={s.textInput}
                  value={first_name}
                  placeholder={t("placehoder_type_text")}
                />
              </div>
              <div className={s.inputWrapper}>
                <p>{t("team")}</p>
                <input
                  type="text"
                  className={s.textInput}
                  value={offer}
                  placeholder={t("placehoder_type_text")}
                />
              </div>
            </div>
          </div>
          <form
            className={s.changePasswordContainer}
            onSubmit={formik.handleSubmit}
          >
            <p className={s.title}>{t("password_change")}</p>
            <div className={s.inputsWrapper}>
              <div className={s.fieldWrapper}>
                <p>{t("old_password")}</p>
                <div className={s.inputWrapper}>
                  <input
                    name="current_password"
                    type={isShowedOldPassword ? "text" : "password"}
                    placeholder={t("type_password")}
                    value={formik.values.current_password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`${s.textInput} ${
                      formik.touched.current_password &&
                      formik.errors.current_password
                        ? s.error
                        : ""
                    }`}
                  />
                  {formik.touched.current_password &&
                  formik.errors.current_password ? (
                    <div className={s.error}>
                      {formik.errors.current_password}
                    </div>
                  ) : null}
                  <button
                    type="button"
                    className={s.showPasswordBtn}
                    onClick={(e) =>
                      handleShowPasswordClick(
                        e,
                        oldPasswordInputRef,
                        isShowedOldPassword,
                        setShowedOldPassword,
                      )
                    }
                  >
                    <SvgSelector
                      id={
                        isShowedOldPassword
                          ? "show-password-icon"
                          : "hide-password-icon"
                      }
                    />
                  </button>
                </div>
              </div>
              <div className={s.fieldWrapper}>
                <p>{t("newPassword")}</p>
                <div className={s.inputWrapper}>
                  <input
                    name="new_password"
                    type={isShowedNewPassword1 ? "text" : "password"}
                    placeholder={t("type_password")}
                    value={formik.values.new_password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`${s.textInput} ${
                      formik.touched.new_password && formik.errors.new_password
                        ? s.error
                        : ""
                    }`}
                  />
                  {formik.touched.new_password && formik.errors.new_password ? (
                    <div className={s.error}>{formik.errors.new_password}</div>
                  ) : null}
                  <button
                    type="button"
                    className={s.showPasswordBtn}
                    onClick={(e) =>
                      handleShowPasswordClick(
                        e,
                        newPassword1InputRef,
                        isShowedNewPassword1,
                        setShowedNewPassword1,
                      )
                    }
                  >
                    <SvgSelector
                      id={
                        isShowedNewPassword1
                          ? "show-password-icon"
                          : "hide-password-icon"
                      }
                    />
                  </button>
                </div>
              </div>

              <div className={s.fieldWrapper}>
                <p>{t("repeatPassword")}</p>
                <div className={s.inputWrapper}>
                  <input
                    name="re_new_password"
                    type={isShowedNewPassword2 ? "text" : "password"}
                    placeholder={t("type_password")}
                    value={formik.values.re_new_password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`${s.textInput} ${
                      formik.touched.re_new_password &&
                      formik.errors.re_new_password
                        ? s.error
                        : ""
                    }`}
                  />
                  {formik.touched.re_new_password &&
                  formik.errors.re_new_password ? (
                    <div className={s.error}>
                      {formik.errors.re_new_password}
                    </div>
                  ) : null}
                  <button
                    type="button"
                    className={s.showPasswordBtn}
                    onClick={(e) =>
                      handleShowPasswordClick(
                        e,
                        newPassword2InputRef,
                        isShowedNewPassword2,
                        setShowedNewPassword2,
                      )
                    }
                  >
                    <SvgSelector
                      id={
                        isShowedNewPassword2
                          ? "show-password-icon"
                          : "hide-password-icon"
                      }
                    />
                  </button>
                </div>
              </div>
            </div>
            <div className={s.buttons}>
              <button
                className={s.whiteBorderBtn}
                type="button"
                onClick={() => formik.resetForm()}
              >
                {t("button_cancel")}
              </button>
              <PrimaryBtn
                text={t("button_save")}
                submit={true}
                pending={changePasswordPending}
                className={s.customPrimaryBtn}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
