import React, { useEffect, useState } from "react";
import s from "./prelanding.module.scss";
import GradientBorder from "../../../../../components/GradientBorder/GradientBorder";
import ChoosePrelanding from "./TabContent/ChoosePrelanding/ChoosePrelanding";
import CreatePrelanding from "./TabContent/CreatePrelanding/CreatePrelanding";
import UploadPrelanding from "./TabContent/UploadPrelanding/UploadPrelanding";
import EditPrelanding from "./TabContent/EditPrelanding/EditPrelanding";
import { useDispatch } from "react-redux";
import UploadPrelandingPopup from "./UploadPrelandingPopup/UploadPrelandingPopup";
import SvgSelector from "../../../../../components/SvgSelector/SvgSelector";
import { useTranslation } from "react-i18next";

const Prelanding = ({
  choosePrelandingFormik,
  createPrelandingFormik,
  resetCreatePrelandingForm,
  uploadPrelandingFormik,
  content,
  setContent,
  uploadPrelandingPopupIsActive,
  setUploadPrelandingPopupIsActive,
}) => {
  const { t } = useTranslation();

  return (
    <div className={s.prelandingWrapper}>
      <UploadPrelandingPopup
        active={uploadPrelandingPopupIsActive}
        setActive={setUploadPrelandingPopupIsActive}
        icon={<SvgSelector id="prelandings" />}
        formik={uploadPrelandingFormik}
      />
      <div className={s.contentButtonsContainer}>
        <div className={s.contentButtonsWrapper}>
          <button
            onClick={() => setContent("choose")}
            className={`${s.contentBtn} ${content === "choose" ? s.active : ""}`}
          >
            {t("choose")}
          </button>
          <button
            onClick={() => setContent("create")}
            className={`${s.contentBtn} ${content === "create" ? s.active : ""}`}
          >
            {t("create")}
          </button>
          <button
            onClick={() => setUploadPrelandingPopupIsActive(true)}
            className={`${s.contentBtn} ${content === "upload" ? s.active : ""}`}
          >
            {t("upload")}
          </button>
          {content === "edit" && (
            <button
              onClick={() => setContent("edit")}
              className={`${s.contentBtn} ${content === "edit" ? s.active : ""}`}
            >
              {t("edit_option")}
            </button>
          )}
        </div>
      </div>
      <GradientBorder className={s.gradientBorderCustom} />
      {content === "choose" && (
        <ChoosePrelanding
          formik={choosePrelandingFormik}
          content={content}
          setContent={setContent}
        />
      )}
      {content === "create" && (
        <CreatePrelanding
          setContent={setContent}
          formik={createPrelandingFormik}
          resetForm={resetCreatePrelandingForm}
        />
      )}
      {content === "edit" && (
        <EditPrelanding
          setContent={setContent}
          choosePrelandingFormik={choosePrelandingFormik}
        />
      )}
    </div>
  );
};

export default Prelanding;
