import {fetchAsyncDomainsList, fetchAsyncRedirectorsList} from "../../redux/slices/globalDataSlice";

export const DOMAINS_REQUEST = 'DOMAINS_REQUEST';
export const REDIRECTORS_REQUEST = 'REDIRECTORS_REQUEST';

export const getFilterRequest = (requestName, dispatch) => {
    switch (requestName) {
        case DOMAINS_REQUEST:
            return async ({ page, pageSize, searchQuery }) => {
                const response = await dispatch(
                    fetchAsyncDomainsList({
                        page,
                        page_size: pageSize,
                        search: searchQuery || "",
                    })
                ).unwrap();

                return {
                    options: response.results.map((domain) => ({
                        value: domain.address,
                        label: domain.address,
                    })),
                    hasMore: response.results.length > 0,
                    totalCount: response.count,
                };
            };

        case REDIRECTORS_REQUEST:
            return async ({ page, pageSize, searchQuery }) => {
                const response = await dispatch(
                    fetchAsyncRedirectorsList({
                        page,
                        page_size: pageSize,
                        search: searchQuery || "",
                    })
                ).unwrap();

                return {
                    options: response.results.map((redirector) => ({
                        value: redirector.sub7,
                        label: redirector.sub7,
                    })),
                    hasMore: response.results.length > 0,
                    totalCount: response.count,
                };
            };

        default:
            throw new Error(`Unknown requestName: ${requestName}`);
    }
};