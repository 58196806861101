import axios from "axios";
import {getRefreshToken, TOKEN} from "../storage/user";
import {logoutHandler} from "./helpers/handlers";
import {invalidToken} from "../utils/vars/errorMessages";

const DEVELOPING_MODE = false;

export const API = DEVELOPING_MODE ? process.env.REACT_APP_STAGE_API_URL : process.env.REACT_APP_PROD_API_URL

const $api = axios.create({
    baseURL: API,
});

$api.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${TOKEN}`;
    return config;
});

$api.interceptors.response.use(
    (config) => {
        return config;
    },
    async (error) => {
        const originalRequest = error.config;
        if (
            error?.response?.status === 401 &&
            originalRequest &&
            !originalRequest._isRetry
        ) {
            originalRequest._isRetry = true;
            try {
                const response = await axios.post(
                    `${API}/users/token/refresh/`,
                    {
                        refresh: getRefreshToken(),
                    },
                );
                const {access, refresh} = response.data;
                if (access && refresh) {
                    localStorage.setItem("accessToken", access);
                    localStorage.setItem("refreshToken", refresh);
                    return $api.request(originalRequest);
                } else {
                    logoutHandler();
                }
            } catch (e) {
                logoutHandler();
            }
        } else {
            if (error?.response?.data?.detail === invalidToken) {
                logoutHandler();
            }
            throw error;
        }
    },
);

export default $api;
