import React from "react";
import { useTranslation } from "react-i18next";
import MuiTooltip from "../UiKit/MuiTooltip/MuiTooltip";
import useCustomSnackbar from "../../hooks/useCustomSnackbar";
import SvgSelector from "../SvgSelector/SvgSelector";
import s from "./baseTextInput.module.scss";

const BaseTextInput = ({
  value,
  placeholder,
  action,
  copy,
  alertMessage,
  alertType,
}) => {
  const showSnackbar = useCustomSnackbar();

  const { t } = useTranslation();

  const copyText = async () => {
    await navigator.clipboard.writeText(value);
    showSnackbar(alertMessage, alertType);
  };

  return (
    <div className={s.mainInputWrapper}>
      <input
        readOnly={!action}
        type="text"
        value={value ?? ''}
        onChange={action}
        className={s.textInput}
        placeholder={placeholder}
      />
      {copy && value && (
        <div className={s.copyRedirectContainer}>
          <button className={s.copyBtn} onClick={copyText}>
            <MuiTooltip title={t("copy")}>
              <SvgSelector id="copy-link-icon" />
            </MuiTooltip>
          </button>
        </div>
      )}
    </div>
  );
};

export default BaseTextInput;
