export const updateArrayById = (array, newObject) => {
    return array.map(item => item.id === newObject.id ? newObject : item);
};

export const updateFieldById = (array, id, field, value) => {
    return array.map(item => {
        if (item.id === id) {
            return {
                ...item,
                [field]: value
            };
        }
        return item;
    });
};
