import {createTheme, styled} from "@mui/material/styles";
import {DesktopDatePicker} from "@mui/x-date-pickers";

export const datePickerStyles = {
    width: "100%",

    "& .MuiOutlinedInput-root": {
        borderRadius: "40px",
        background: "#171A21",
        display: "flex",
        alignItems: "center",
        "& fieldset": {
            borderColor: "#171A21",
        },
        "&:hover fieldset": {
            borderColor: "rgba(208, 211, 217, .75)",
        },
        "&.Mui-focused fieldset": {
            borderColor: "rgba(93, 135, 255, .7)",
        },
        "&.Mui-focused:hover fieldset": {
            borderColor: "rgba(93, 135, 255, 1)",
        },
        "& .MuiInputBase-input": {
            color: "#F2F5FA",
            height: "40px",
            padding: "0 0 0 10px",
            boxSizing: "border-box",
            display: "flex",
            alignItems: "center",
            lineHeight: "normal",
        },
        "& .MuiInputBase-input::placeholder": {
            color: "#99A0AE",
            opacity: 1,
        },
    },
    "& .MuiInputLabel-root": {
        color: "#99A0AE",
        top: "-4px",
        transform: "translate(14px, 12px) scale(1)",
        "&.MuiInputLabel-shrink": {
            transform: "translate(14px, -6px) scale(0.75)",
        },
    },

    "& .MuiSvgIcon-root": {
        color: "#fff",
    },

    "& .Mui-focused .MuiInputLabel-root": {
        color: "#476CFF",
    },
};

export const datePickerDarkTheme = createTheme({
    palette: {
        mode: "dark",
        background: {
            default: "#222632",
            paper: "#222632",
        },
        primary: {
            main: "#476CFF",
        },
        text: {
            primary: "#F2F5FA",
            secondary: "#d0d3d9",
        },
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: "18px",
                },
            },
        },
    },
});

export const StyledDatePicker = styled(DesktopDatePicker)({
    ...datePickerStyles,
});