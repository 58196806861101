import { fetchAppList } from "../../redux/slices/applicationSlice";
import {
  fetchGeosList,
  fetchOSList,
  fetchUsersList,
} from "../../redux/slices/globalDataSlice";
import { fetchRedirectorsList } from "../../redux/slices/redirectorSlice";

export const fetchFlowFilters = async (dispatch) => {
  try {
    await Promise.all([
      dispatch(fetchAppList()).unwrap(),
      dispatch(fetchGeosList()).unwrap(),
    ]);
  } catch (error) {
    console.error("Failed to fetch data:", error);
  }
};

export const fetchAllRedirectorsFilters = async (dispatch) => {
  try {
    await Promise.all([
      dispatch(fetchAppList()).unwrap(),
    ]);
  } catch (error) {
    console.error("Failed to fetch data:", error);
  }
};

export const fetchFlowSettingsData = async (dispatch) => {
  try {
    await Promise.all([
      dispatch(fetchGeosList()).unwrap(),
      dispatch(fetchAppList()).unwrap(),
      dispatch(fetchRedirectorsList({})).unwrap(),
    ]);
  } catch (error) {
    console.error("Failed to fetch data:", error);
  }
};

export const fetchClickStatsFilters = async (dispatch) => {
  try {
    await Promise.all([
      dispatch(fetchAppList()).unwrap(),
      dispatch(fetchGeosList()).unwrap(),
    ]);
  } catch (error) {
    console.error("Failed to fetch data:", error);
  }
};

export const fetchConversionStatsFilters = async (dispatch) => {
  try {
    await Promise.all([
      dispatch(fetchAppList()).unwrap(),
      dispatch(fetchGeosList()).unwrap(),
      dispatch(fetchRedirectorsList({})).unwrap(),
    ]);
  } catch (error) {
    console.error("Failed to fetch data:", error);
  }
};

export const fetchGeoAppStatsFilters = async (dispatch) => {
  try {
    await Promise.all([
      dispatch(fetchAppList()).unwrap(),
      dispatch(fetchGeosList()).unwrap(),
    ]);
  } catch (error) {
    console.error("Failed to fetch data:", error);
  }
};

export const fetchUsersStatsFilters = async (dispatch) => {
  try {
    await Promise.all([
      dispatch(fetchAppList()).unwrap(),
      dispatch(fetchGeosList()).unwrap(),
      dispatch(fetchUsersList()).unwrap(),
    ]);
  } catch (error) {
    console.error("Failed to fetch data:", error);
  }
};

export const fetchCloacaStatsFilters = async (dispatch) => {
  try {
    await Promise.all([
      dispatch(fetchGeosList()).unwrap(),
      dispatch(fetchOSList()).unwrap(),
    ]);
  } catch (error) {
    console.error("Failed to fetch data:", error);
  }
};
