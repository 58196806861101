import {TOKEN} from "../storage/user";
import {API} from "./url";

export const MAX_WS_RECONNECT_ATTEMPTS = 5; // Максимальна кількість спроб підключення

function getWebSocketApiUrl() {
  return API
    .replace("https:", "wss:")
    .replace("http:", "wss:")
    .replace("/api", "/websocket");
}

/// GLOBAL
export const wsOptions = {
  share: true,
  shouldReconnect: () => false,
};

/// Finance
export const getFinanceWebSocket = () =>
  getWebSocketApiUrl() + "/finance-websocket/?token=" + TOKEN;

export function decodeFinanceEvent(event) {
  const { message } = JSON.parse(event.data);

  return message;
}

export const wsSource = {
  balance: "update_user_balance_notification",
};
