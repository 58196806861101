import React, { useState } from "react";
import { Link } from "react-router-dom";
import MuiTooltip from "../../../../components/UiKit/MuiTooltip/MuiTooltip";
import SvgSelector from "../../../../components/SvgSelector/SvgSelector";
import NewConfirmPopup from "../../../../components/UiKit/Popups/NewConfirmPopup/NewConfirmPopup";
import s from "../prelandings.module.scss";
import { useTranslation } from "react-i18next";

const PrelandingTd = ({ item, onDeleteHandler }) => {
  const [active, setActive] = useState(false);

  const { t } = useTranslation();

  return (
    <tr>
      <td>
        <NewConfirmPopup
          icon={<SvgSelector id="popup-remove-icon" />}
          active={active}
          setActive={setActive}
          action={() => onDeleteHandler(item.id)}
          confirmBtnText={t("button_remove")}
          title={t("flow_removing")}
          description={
            <>
              {t("you_want_remove")}{" "}
              <span style={{ color: "#fff", fontWeight: 600, marginRight: 5 }}>
                {item.name}
              </span>
              ?
            </>
          }
        />
        {item.name}
      </td>
      <td>
        {item?.prelanding_type === 1 && t("wheel_type")}
        {item?.prelanding_type === 4 && t("uploaded_prelanding_type")}
      </td>
      <td>
        <div className={s.actionsWrapper}>
          <div className={s.actionsContainer}>
            <div className={s.actions}>
              <Link
                to={`/prelandings/edit/${item.id}`}
                className={s.manageTableFillBtn}
              >
                <MuiTooltip title={t("edit_option")}>
                  <SvgSelector id="edit-icon" />
                </MuiTooltip>
              </Link>

              <button className={s.removeBtn} onClick={() => setActive(true)}>
                <MuiTooltip title={t("button_remove")}>
                  <SvgSelector id="new-remove-icon" />
                </MuiTooltip>
              </button>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default PrelandingTd;
