import React from "react";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {globalDataSelector} from "../../../redux/slices/globalDataSlice";
import SvgSelector from "../../SvgSelector/SvgSelector";
import s from "./footer.module.scss";

const Footer = () => {
    const {isCollapsedSidebar} = useSelector(globalDataSelector);

    const {t} = useTranslation();

    const currentYear = new Date().getFullYear();

    return (
        <div
            className={`${s.footerWrapper} ${isCollapsedSidebar === "true" ? s.collapsedSidebar : ""}`}
        >
            <Link className={s.logoWrapper} to="/my-flows">
                <SvgSelector id="logo-icon"/>
            </Link>
            <div className={s.infoWrapper}>
                <div className={s.companyInfo}>
                    <p className={s.name}>Wild Wild Apps</p>
                    <p className={s.rights}>© Copyright {currentYear} All rights reserved</p>
                </div>
                <div className={s.servicesLinks}>
                    <div className={s.linkDetails}>
                        <p className={s.linkTitle}>{t("our_services")}</p>
                        <a
                            href="https://t.me/TL_supp_wwa_vados"
                            target="_blank"
                            className={s.pwaLink}
                            rel="noreferrer"
                        >
                            PWA
                        </a>
                    </div>

                    {/*<div className={s.linkDetails}>*/}
                    {/*  <p className={s.linkTitle}>Приватность</p>*/}
                    {/*  <a href="" target="_blank" className={s.privacyPolicyLink}>*/}
                    {/*    Privacy Policy*/}
                    {/*  </a>*/}
                    {/*</div>*/}
                </div>
            </div>

            {/*<div className={s.socials}>*/}
            {/*  <a href="" target="_blank" className={s.socialLink}>*/}
            {/*    <SvgSelector id="telegram-social" />*/}
            {/*  </a>*/}
            {/*  <a href="" target="_blank" className={s.socialLink}>*/}
            {/*    <SvgSelector id="instagram-social" />*/}
            {/*  </a>*/}
            {/*  <a href="" target="_blank" className={s.socialLink}>*/}
            {/*    <SvgSelector id="linkedin-social" />*/}
            {/*  </a>*/}
            {/*</div>*/}
        </div>
    );
};

export default Footer;
