import React from "react";
import s from "./postbackStatus.module.scss";
import GradientBorder from "../../../../../components/GradientBorder/GradientBorder";
import ApplicationSelect from "../../../../../components/Selects/ApplicationSelect";
import { redirectDropdownStyles } from "../../../../../components/UiKit/Popups/GetLinksPopup/GetLinksPopup";
import PrimaryBtn from "../../../../../components/UiKit/Buttons/PrimaryBorderBtn/PrimaryBorderBtn";
import { postbackStatusList } from "../../../../../utils/vars/params";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  addPostbackStatus,
  managementSelector,
} from "../../../../../redux/slices/managementSlice";
import { useDispatch, useSelector } from "react-redux";
import useCustomSnackbar from "../../../../../hooks/useCustomSnackbar";
import {
  snackbar_error,
  snackbar_success,
} from "../../../../../utils/vars/staticVariables";
import { useTranslation } from "react-i18next";

const PostbackStatus = ({}) => {
  const dispatch = useDispatch();
  const showSnackbar = useCustomSnackbar();

  const { addPostbackStatusPending } = useSelector(managementSelector);

  const { t } = useTranslation();

  const postbackStatusFormik = useFormik({
    initialValues: {
      status: postbackStatusList[0],
      value: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      value: Yup.string().trim().required(t("requiredField")),
    }),
    onSubmit: async (form) => {
      const dataObj = {
        value: form.value.trim(),
        status: form.status.value,
      };

      const addPostbackStatusResult = await dispatch(
        addPostbackStatus(dataObj),
      );

      if (addPostbackStatusResult?.payload?.status === 201) {
        showSnackbar(t("postback_status_added"), snackbar_success);
        postbackStatusFormik.resetForm();
      } else {
        showSnackbar(t("failed_to_add_postback_status"), snackbar_error);
      }
    },
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
  });

  return (
    <div className={s.postbackStatusWrapper}>
      <GradientBorder className={s.gradientBorderCustom} />
      <div className={s.tabHeader}>
        <p className={s.title}>{t("adding_postback_statuses")}</p>
        {/*<p className={s.desc}>*/}
        {/*  Выберите цену только для данного пользователя из списка доступных цен.*/}
        {/*  Вы сможете изменить ее в любой момент.*/}
        {/*</p>*/}
      </div>
      <div className={s.pageInnerContent}>
        <form
          className={s.postbackStatusForm}
          onSubmit={postbackStatusFormik.handleSubmit}
        >
          <div className={s.inputsWrapper}>
            <div className={s.inputWrapper}>
              <p>{t("postback_status")}</p>
              <ApplicationSelect
                value={postbackStatusFormik.values.status}
                isClearable={false}
                options={postbackStatusList}
                onChange={(option) => {
                  postbackStatusFormik.setFieldValue("status", option);
                }}
                isMulti={false}
                placeholderProp={t("choose_option_placeholder")}
                styles={redirectDropdownStyles}
              />
            </div>
            <div className={s.inputWrapper}>
              <p>{t("value")}</p>
              <input
                type="text"
                name="value"
                placeholder={t("type_value")}
                value={postbackStatusFormik.values.value}
                onChange={postbackStatusFormik.handleChange}
                onBlur={postbackStatusFormik.handleBlur}
                className={`${s.textInput} ${
                  postbackStatusFormik.touched.value &&
                  postbackStatusFormik.errors.value
                    ? s.error
                    : ""
                }`}
              />
              {postbackStatusFormik.touched.value &&
              postbackStatusFormik.errors.value ? (
                <div className={s.error}>
                  {postbackStatusFormik.errors.value}
                </div>
              ) : null}
            </div>
          </div>
          <div className={s.buttons}>
            <button
              className={s.whiteBorderBtn}
              type="button"
              onClick={() => postbackStatusFormik.resetForm()}
            >
              {t("button_cancel")}
            </button>
            <PrimaryBtn
              text={t("button_save")}
              submit={true}
              pending={addPostbackStatusPending}
              className={s.customPrimaryBtn}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default PostbackStatus;
