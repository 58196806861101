import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import $api from "../../api/url";
import axios from "axios";
import { errorMessage } from "../../api/helpers/scripts";
import { ABORTED_ERROR } from "../../utils/vars/staticVariables";

export const fetchRedirectorsList = createAsyncThunk(
  "redirector/fetchRedirectorList",
  async ({ sub7 }) => {
    try {
      let url = "/redirectors/?page_size=1000";

      if (sub7) {
        url += `&sub7=${sub7}`;
      }

      const response = await $api.get(url);

      return response.data;
    } catch (error) {
      throw error;
    }
  },
);


export const fetchRedirectorsWithParams = createAsyncThunk(
  "redirector/fetchRedirectorsWithParams",
  async (params, { signal, rejectWithValue }) => {
    try {
      const copiedParams = new URLSearchParams(params);

      const { data } = await $api.get("/redirectors/?" + copiedParams, {
        signal,
      });
      return data;
    } catch (error) {
      let err = errorMessage(error);

      if (axios.isCancel(error)) {
        return rejectWithValue();
      }

      throw new Error(err);
    }
  },
);

export const fetchRedirectorById = createAsyncThunk(
  "redirector/fetchRedirectorById",
  async (id) => {
    try {
      const response = await $api.get(`/redirectors/${id}/`);

      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

export const editRedirector = createAsyncThunk(
  "redirector/editRedirector",
  async ({ updatedData, id }) => {
    try {
      const response = await $api.put(`/redirectors/${id}/`, updatedData);
      const { data, status } = response;

      return { data, status };
    } catch (error) {
      const { data, status } = error.response;

      return { data, status };
    }
  },
);


export const createRedirector = createAsyncThunk(
  "redirector/createRedirector",
  async (redirectorObj) => {
    try {
      const response = await $api.post("/redirectors/", redirectorObj);
      const { data, status } = response;

      return { data, status };
    } catch (error) {
      const { data, status } = error.response;

      return { data, status };
    }
  },
);


export const deleteRedirector = createAsyncThunk(
  "redirector/deleteRedirector",
  async (id) => {
    try {
      const response = await $api.delete(`/redirectors/${id}/`);
      const { data, status } = response;

      return { data, status };
    } catch (error) {
      const { data, status } = error.response;

      return { data, status };
    }
  },
);

const redirectorSlice = createSlice({
  name: "redirector",
  initialState: {
    redirectorsList: [],
    redirectorListLoading: false,

    redirectorCardsList: [],
    redirectorCardsPending: false,

    createRedirectorPending: false,
    editRedirectorPending: false,

    error: null,
    redirectorLoading: false,

    popupActive: false,

    editRedirectorObj: null,
    editRedirectorLoading: false,

    totalCount: 0,

    flowBySub7Id: null,
  },
  reducers: {
    setEditRedirectorObj: (state) => {
      state.editRedirectorObj = null;
    },
    setRedirectorCardsList: (state, action) => {
      state.redirectorCardsList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRedirectorsList.pending, (state) => {
        state.redirectorListLoading = true;
      })
      .addCase(fetchRedirectorsList.fulfilled, (state, action) => {
        state.redirectorsList = action.payload.results;
        state.redirectorListLoading = false;
        state.popupActive = true;
      })
      .addCase(fetchRedirectorsList.rejected, (state, action) => {
        state.redirectorListLoading = false;
      })

      .addCase(fetchRedirectorsWithParams.pending, (state) => {
        state.redirectorCardsPending = true;
      })
      .addCase(fetchRedirectorsWithParams.fulfilled, (state, action) => {
        const { count, results } = action.payload;

        state.totalCount = count;
        state.redirectorCardsList = results;
        state.redirectorCardsPending = false;
      })
      .addCase(fetchRedirectorsWithParams.rejected, (state, { error }) => {
        const { message } = error;

        state.redirectorCardsPending = message === ABORTED_ERROR;
        state.redirectorCardsList = [];

        if (message !== ABORTED_ERROR) {
          state.totalCount = 0;
        }
      })

      .addCase(editRedirector.pending, (state) => {
        state.editRedirectorPending = true;
      })
      .addCase(editRedirector.fulfilled, (state) => {
        state.editRedirectorPending = false;
      })
      .addCase(editRedirector.rejected, (state, action) => {
        state.editRedirectorPending = false;
      })

      .addCase(createRedirector.pending, (state) => {
        state.createRedirectorPending = true;
      })
      .addCase(createRedirector.fulfilled, (state, action) => {
        state.createRedirectorPending = false;
      })
      .addCase(createRedirector.rejected, (state, action) => {
        state.createRedirectorPending = false;
      })

      .addCase(fetchRedirectorById.pending, (state) => {
        state.redirectorLoading = true;
      })
      .addCase(fetchRedirectorById.fulfilled, (state, action) => {
        state.editRedirectorObj = action.payload;
        state.redirectorLoading = false;
      })
      .addCase(fetchRedirectorById.rejected, (state, action) => {
        state.redirectorLoading = false;
      });
  },
});

export const { setEditRedirectorObj, setRedirectorCardsList } =
  redirectorSlice.actions;

export default redirectorSlice.reducer;

export const redirectorSelector = (state) => state.redirector;
