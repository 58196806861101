import React, { useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { createTheme, ThemeProvider } from "@mui/material";

export const toolTipTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          cursor: "default",
          marginTop: 0,
          backgroundColor: "#202936",
          color: "white",
          fontSize: 14,
          letterSpacing: 0.5,
          fontWeight: 400,
          pointerEvents: "none",
          textAlign: "center",
        },
      },
    },
  },
});


const MuiTooltip = ({ title, children, disabled, offsetValue = -10, type }) => {
  const [open, setOpen] = useState(false);

  let currentTheme;

  currentTheme = toolTipTheme;

  const popperProps = {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, offsetValue],
        },
      },
    ],
    popperOptions: {
      modifiers: [
        {
          name: "preventOverflow",
          options: {},
        },
      ],
    },
    style: { pointerEvents: "none" },
  };

  const handleScroll = () => {
    setOpen(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <ThemeProvider theme={currentTheme}>
      <Tooltip
        title={title}
        PopperProps={popperProps}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        style={disabled ? { cursor: "not-allowed" } : null}
      >
        <IconButton>{children}</IconButton>
      </Tooltip>
    </ThemeProvider>
  );
};

export default MuiTooltip;
