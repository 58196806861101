import React, { useEffect, useState } from "react";
import {
  params,
  postbackLogsStatusOptions,
  postbackTypeStatusOptions,
  sortDateOptions,
} from "../../../../utils/vars/params";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPostbackLogsStats,
  resetTotalCount,
  statisticsSelector,
} from "../../../../redux/slices/statisticsSlice";
import {
  globalDataSelector,
} from "../../../../redux/slices/globalDataSlice";
import { Link, useSearchParams } from "react-router-dom";
import useGetSearchParams from "../../../../hooks/useGetSearchParams";
import useSetSearchParams from "../../../../hooks/useSetSearchParams";
import { useSearchParamsOptions } from "../../../../utils/helpers/useSelectHelpers";
import { useDebounce } from "../../../../hooks/useDebounce";
import { getDefaultParams } from "../../../../api/helpers/scripts";
import { INVALID_PAGE_ERROR } from "../../../../utils/vars/staticVariables";
import { onSetPageSize } from "../../../../utils/helpers/statisticsHelper";
import s from "./postbackLogsStats.module.scss";
import SvgSelector from "../../../../components/SvgSelector/SvgSelector";
import MultiSelect from "../../../../components/Selects/MultiSelect/MultiSelect";
import { MuiDatePicker } from "../../../../components/DatePickers/MuiDatePicker";
import {
  formatDateTime,
  stringToDayJs,
} from "../../../../utils/helpers/formatTime";
import {cleanSearchParams, hasNonEmptyParams} from "../../../../utils/helpers/cleanFilters";
import EmptyList from "../../../../components/EmptyList/EmptyList";
import ParamsSort from "../../../../components/ParamsSort/ParamsSort";
import CustomSkeleton from "../../../../components/UiKit/CustomSkeleton/CustomSkeleton";
import { PageSizeDropdown } from "../../../../components/UiKit/PageSizeDropdown/PageSizeDropdown";
import Pagination from "../../../../components/Pagination/Pagination";
import {
  created_at_after,
  created_at_before,
  onSortDateHandler,
  page,
  postback_time,
  postback_type,
  search,
  sort,
  status,
} from "../ClicksStats/ClicksStats";
import { stringHelpers } from "../../../../utils/helpers/stringHelpers";
import { useTranslation } from "react-i18next";
import Header from "../../../../components/Partials/Header/Header";

const PostbackLogsStats = () => {
  const [sortDate, setSortDate] = useState(sortDateOptions[0]);

  const dispatch = useDispatch();

  const { statisticsList, loading, totalCount } =
    useSelector(statisticsSelector);

  const { isCollapsedSidebar, pageSizeValue } =
    useSelector(globalDataSelector);


  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const {
    search: urlSearchValue,
    sort: sortValue,
    page: pageValue,
    created_at_after: createdAtAfterParam,
    created_at_before: createdAtBeforeParam,
  } = useGetSearchParams();

  const updateSearchParams = useSetSearchParams(setSearchParams);

  const [selectedStatus, selectedPostbackType] = useSearchParamsOptions(
    searchParams,
    [status, postback_type],
  );

  const [inputSearchValue, setInputSearchValue] = useDebounce((value) => {
    if (value) {
      updateSearchParams(search, value);
    } else {
      updateSearchParams(search, value, true);
    }
  }, 600);

  useEffect(() => {
    if (!urlSearchValue) {
      setInputSearchValue("");
    } else {
      setInputSearchValue(urlSearchValue);
    }

    if (
      createdAtAfterParam ||
      createdAtBeforeParam ||
      sortDate.value === "custom_date"
    ) {
      setSortDate(sortDateOptions.find((item) => item.value === "custom_date"));
    } else {
      if (!sortValue) {
        setSortDate(sortDateOptions[0]);
      } else {
        setSortDate(sortDateOptions.find(({ value }) => value === sortValue));
      }
    }
  }, [searchParams]);

  useEffect(() => {
    const promise = dispatch(
      fetchPostbackLogsStats(
        getDefaultParams({
          searchParams,
          sort,
          defaultPageSize: pageSizeValue,
        }),
      ),
    );

    promise.unwrap().catch((err) => {
      if (err?.message === INVALID_PAGE_ERROR) {
        updateSearchParams(page, 1);
      }
    });

    return () => {
      promise.abort();
    };
  }, [dispatch, searchParams, pageSizeValue]);

  useEffect(() => {
    return () => dispatch(resetTotalCount());
  }, [dispatch]);

  const handleSetPageSize = (size) => {
    onSetPageSize(dispatch, size);
  };

  const theadTrElement = (
    <tr>
      <th>
        <div className={s.headerContent}>
          {t("date")}
          <ParamsSort
            sortBy={postback_time}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        </div>
      </th>
      <th>
        <div className={s.headerContent}>
          Event
          <ParamsSort
            sortBy={postback_type}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        </div>
      </th>
      <th>Tds_clickid</th>
      <th>URL</th>

      <th>
        <div className={s.headerContent}>
          {t("status")}
          <ParamsSort
            sortBy={status}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        </div>
      </th>
    </tr>
  );

  const selectedDate = (() => {
    const foundOption = sortDateOptions.find(
      ({ value }) => value === sortDate?.value,
    );

    if (foundOption) {
      return { ...foundOption, label: t(foundOption.value) };
    }

    return null;
  })();

  return (
    <div
      className={`${s.postbackLogsStatsWrapper} ${isCollapsedSidebar === "true" ? s.collapsedSidebar : ""}`}
    >
      {/*<div className={s.pageHeaderWrapper}>*/}
      {/*  <div className={s.pageHeaderContainer}>*/}
      {/*    <div className={s.staticElements}>*/}
      {/*      <div className={s.leftSideWrapper}>*/}
      {/*        <button*/}
      {/*          className={s.walletBtn}*/}
      {/*          onClick={() => {*/}
      {/*            dispatch(setAdaptiveMenu(false));*/}
      {/*            dispatch(setTopUpBalanceActive(true));*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          <SvgSelector id="wallet" />*/}
      {/*        </button>*/}
      {/*        <Link className={s.walletBtn} to="/account">*/}
      {/*          <SvgSelector id="account-gear-icon" />*/}
      {/*        </Link>*/}
      {/*        {is_tech_user && (*/}
      {/*            <Link to='/accounts-management' className={s.standardInstallPrice}>*/}
      {/*              <SvgSelector id='standard-install-price-icon'/>*/}
      {/*              <span>$ {standardClickPriceValue || "----" }</span>*/}
      {/*            </Link>*/}
      {/*        )}*/}
      {/*        <LanguageDropdown />*/}
      {/*      </div>*/}

      {/*      <button*/}
      {/*        className={s.menuBtn}*/}
      {/*        onClick={() => dispatch(setAdaptiveMenu(!isAdaptiveMenuActive))}*/}
      {/*      >*/}
      {/*        <SvgSelector id="burger-menu" />*/}
      {/*      </button>*/}
      {/*    </div>*/}

      {/*    <div className={s.changingElements}>*/}
      {/*      <Search value={inputSearchValue} setValue={setInputSearchValue} />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}

      <Header
          searchValue={inputSearchValue}
          setSearchValue={setInputSearchValue}
          isSearch
      />

      <div className={s.pageContent}>
        <div className={s.filtersWrapper}>
          <div className={s.filters}>
            <div className={s.selectDateWrapper}>
              <div className={s.selectIconWrapper}>
                <SvgSelector id="date-select-icon" />
              </div>
              <MultiSelect
                isClearable={false}
                options={sortDateOptions.map((option) => {
                  return {
                    ...option,
                    label: t(option.value),
                  };
                })}
                setSelectedOptions={(value) =>
                  onSortDateHandler(value, updateSearchParams, setSortDate)
                }
                value={selectedDate}
                placeholder={t("date")}
                isMulti={false}
              />
            </div>
            {sortDate?.value === "custom_date" && (
              <div className={s.datePickersWrapper}>
                <div className={s.datePicker}>
                  <MuiDatePicker
                      label={t("date_from")}
                      value={stringToDayJs(createdAtAfterParam)}
                      searchParamValue={created_at_after}
                      updateSearchParams={updateSearchParams}
                  />
                  {/*<MuiDatePicker*/}
                  {/*  label={t("date_from")}*/}
                  {/*  value={stringToDayJs(createdAtAfterParam)}*/}
                  {/*  onChange={(e) =>*/}
                  {/*    updateSearchParams(*/}
                  {/*      created_at_after,*/}
                  {/*      formatTimestampToServerDate(e?.toString()),*/}
                  {/*    )*/}
                  {/*  }*/}
                  {/*/>*/}
                </div>
                <div className={s.datePicker}>
                  <MuiDatePicker
                      label={t("date_to")}
                      value={stringToDayJs(createdAtAfterParam)}
                      searchParamValue={created_at_before}
                      updateSearchParams={updateSearchParams}
                  />
                  {/*<MuiDatePicker*/}
                  {/*  label={t("date_to")}*/}
                  {/*  value={stringToDayJs(createdAtBeforeParam)}*/}
                  {/*  onChange={(e) => {*/}
                  {/*    updateSearchParams(*/}
                  {/*      created_at_before,*/}
                  {/*      formatTimestampToServerDate(e?.toString()),*/}
                  {/*    );*/}
                  {/*  }}*/}
                  {/*/>*/}
                </div>
              </div>
            )}

            <div className={s.selectWrapper}>
              <div className={s.selectIconWrapper}>
                <SvgSelector id="status-select-icon" />
              </div>
              <MultiSelect
                isClearable={true}
                value={selectedStatus}
                options={postbackLogsStatusOptions}
                setSelectedOptions={(values) =>
                  updateSearchParams(
                    status,
                    values.map((el) => el.value).join(","),
                  )
                }
                placeholder={t("status")}
                isMulti={true}
              />
            </div>

            <div className={s.selectWrapper}>
              <div className={s.selectIconWrapper}>
                <SvgSelector id="app-select-icon" />
              </div>
              <MultiSelect
                isClearable={true}
                value={selectedPostbackType}
                options={postbackTypeStatusOptions}
                setSelectedOptions={(values) =>
                  updateSearchParams(
                    postback_type,
                    values.map((el) => el.value).join(","),
                  )
                }
                placeholder="Event"
                isMulti={true}
              />
            </div>

            {hasNonEmptyParams(searchParams) && (
              <div className={s.cleanBtnContainer}>
                <button className={s.blueBorderBtn} onClick={() => cleanSearchParams(searchParams, setSearchParams)}>
                  {t("clear_button")}
                </button>
              </div>
            )}
          </div>
        </div>
        <div className={s.pageInnerContent}>
          {totalCount === 0 && !loading ? (
            <EmptyList />
          ) : loading ? (
            <section className={s.body}>
              <table>
                <thead>{theadTrElement}</thead>
              </table>
              <div className={s.skeletonWrapper}>
                <CustomSkeleton styles={s.skeletonTable} />
              </div>
            </section>
          ) : (
            <section className={s.body}>
              <table>
                <thead>{theadTrElement}</thead>
                <tbody>
                  {statisticsList?.map(
                    (
                      {
                        url,
                        postback_type,
                        status,
                        postback_time,
                        tds_clickid,
                      },
                      index,
                    ) => {
                      const { date, time } = formatDateTime(postback_time);

                      return (
                        <tr key={index}>
                          <td>
                            <p>{date}</p>
                            <p style={{ marginTop: 7, color: "#99A0AE" }}>
                              {time}
                            </p>
                          </td>
                          <td>{postback_type}</td>
                          <td className={s.tdsClickidTd}>{tds_clickid}</td>
                          <td className={s.urlTd}>
                            <a href={url} target="_blank" rel="noreferrer">
                              {url}
                            </a>
                          </td>
                          <td
                            style={
                              status === "success"
                                ? { color: "#476CFF" }
                                : { color: "#D02533" }
                            }
                          >
                            {stringHelpers(status)}
                          </td>
                        </tr>
                      );
                    },
                  )}
                </tbody>
              </table>
            </section>
          )}
        </div>

        <div
          className={`${s.paginationContainerStatistics} ${isCollapsedSidebar === "true" ? s.collapsed : ""}`}
        >
          <div className={s.countPageSizeContainer}>
            <div className={s.newRecordsCount}>
              {t("total")}: {!loading && <span>{totalCount}</span>}
            </div>
            <div className={s.pageSizeDropdownContainer}>
              <PageSizeDropdown
                setSize={handleSetPageSize}
                size={pageSizeValue}
              />
            </div>
          </div>
          {totalCount > pageSizeValue && (
            <Pagination
              currentPage={parseInt(pageValue) || 1}
              setCurrentPage={(page) => {
                updateSearchParams(params.page, page);
              }}
              total={totalCount}
              limit={pageSizeValue}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PostbackLogsStats;
