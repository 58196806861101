import React, { useEffect } from "react";
import s from "./popupWheel.module.scss";
import { useSelector } from "react-redux";
import { financeSelector } from "../../../../redux/slices/financeSlice";
import { globalDataSelector } from "../../../../redux/slices/globalDataSlice";
import AdaptiveWheel from "./AdaptiveWheel/AdaptiveWheel";
import SvgSelector from "../../../../components/SvgSelector/SvgSelector";

const PopupWheel = ({
  active,
  setActive,
  currentWheel,
  wheelNumbers,
  prelandTitle,
  spinButtonText,
  respinButtonText,
  winSector,
  popupTitle,
  popupButtonText,
  popupText,
}) => {
  const { topUpBalanceIsActive } = useSelector(financeSelector);
  const { exitPopup, isCollapsedSidebar } = useSelector(globalDataSelector);

  useEffect(() => {
    if (topUpBalanceIsActive || exitPopup) {
      setActive(false);
    }
  }, [topUpBalanceIsActive, exitPopup]);

  useEffect(() => {
    const handleEscClose = (e) => {
      if (e.key === "Escape") {
        setActive(false);
        if (document.activeElement) {
          document.activeElement.blur();
        }
      }
    };

    document.addEventListener("keydown", handleEscClose);

    return () => {
      document.removeEventListener("keydown", handleEscClose);
    };
  }, [setActive]);

  const handleClosePopup = (event) => {
    if (event.target.classList.contains(s.popupWrapper)) {
      setActive(false);
    }
  };

  return (
    <div
      className={`${s.popupWrapper} ${active ? s.active : ""} ${isCollapsedSidebar === "true" ? s.collapsed : ""}`}
      onClick={handleClosePopup}
    >
      <div className={s.popupContent}>
        <AdaptiveWheel
          currentWheel={currentWheel}
          wheelNumbers={wheelNumbers}
          prelandTitle={prelandTitle}
          spinButtonText={spinButtonText}
          respinButtonText={respinButtonText}
          winSector={winSector}
          popupTitle={popupTitle}
          popupButtonText={popupButtonText}
          popupText={popupText}
        />
        <button
          onClick={() => setActive(false)}
          className={s.closeBtn}
          type="button"
        >
          <SvgSelector id="close-popup" />
        </button>
      </div>
    </div>
  );
};

export default PopupWheel;
