import React from "react";
import Select from "react-select";
import {SelectStyles} from "../ReactSelectTheme/themes";
import {useTranslation} from "react-i18next";

const customOption = (props) => {
    const {data, isFocused, isSelected} = props;

    const customStyle = {
        display: "flex",
        alignItems: "center",
        padding: "10px 8px 10px 16px",
        borderRadius: 20,
        cursor: "pointer",
        // cursor: data.isDisabled ? "default" : "pointer",
        backgroundColor: isFocused ? "#1E2437" : "#171a21", // Логіка для фокуса
        color: isSelected ? "#F2F5FA" : "#F2F5FA", // Логіка для вибраного стану
        textAlign: "left",
        marginTop: 7,
        marginBottom: 7,
        "&:last-child": {
            // marginBottom: 7,
        },
        "&:active": {
            backgroundColor: "#1E2437",
        },
        "&:hover": {
            backgroundColor: "#1E2437",
        },
    };


    // Додаткова логіка для кольору, якщо `data.name` і `data.is_alive`
    // if (data?.code === "US" || data?.code === "AE") {
    //   customStyle.color = "#d32f2f";
    // }

    // if (data?.code === "US" || data?.code === "AE") {
    //   customStyle.color = "#d32f2f";
    // }

    return (
        <div {...props.innerProps} style={customStyle}>
            {props.data.icon && (
                <img
                    src={props.data.icon}
                    alt=""
                    style={{width: 20, marginRight: 10, borderRadius: "2px"}}
                />
            )}
            {props.data.label}
        </div>
    );
};

const filterOptions = (option, inputValue) => {
    const input = inputValue.toLowerCase();
    const label = option.label.toLowerCase();
    const value = String(option.value).toLowerCase();
    const code = String(option.data.code).toLowerCase();

    return label.includes(input) || value.includes(input) || code.includes(input);
};

const FlowSettingsGeoSelect = ({
                                   value,
                                   options,
                                   action,
                                   isMulti,
                                   placeholder,
                                   darkTheme,
                                   isClearable = false,
                                   isInvalid,
                               }) => {

    const {t} = useTranslation();


    return (
        <Select
            value={value}
            isClearable={isClearable}
            options={options}
            onChange={(o) => {
                action(o);
            }}
            filterOption={filterOptions}
            isMulti={isMulti}
            placeholder={placeholder}
            styles={SelectStyles}
            isInvalid={isInvalid}
            darkTheme={darkTheme}
            noOptionsMessage={() => t('no_options')}
            components={{Option: customOption}}
        />
    )
};

export default FlowSettingsGeoSelect;
