import React, {useRef} from 'react';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Search from "../../Search/Search";
import LanguageDropdown from "../../LanguageDropdown/LanguageDropdown";
import SvgSelector from "../../SvgSelector/SvgSelector";
import {globalDataSelector, setAdaptiveMenu, setIsNotificationPopupActive} from "../../../redux/slices/globalDataSlice";
import {managementSelector} from "../../../redux/slices/managementSlice";
import {setTopUpBalanceActive} from "../../../redux/slices/financeSlice";
import {is_tech_user} from "../../../storage/user";
import s from './header.module.scss'
import NotificationsPopup from "../../UiKit/Popups/NotificationsPopup/NotificationsPopup";

const Header = ({
                    isSearch = false,
                    searchValue,
                    setSearchValue,
                    isCreateButton = false,
                    isButton = false,
                    isLink = false,
                    linkPath,
                    buttonText,
                    buttonHandler,
                }) => {
    const notificationsBtnRef = useRef(null);
    const dispatch = useDispatch();
    const {standardClickPriceValue} = useSelector(managementSelector);
    const {
        isAdaptiveMenuActive,
        isNotificationPopupActive,
        hasUnreadNotifications,
        isCollapsedSidebar
    } = useSelector(globalDataSelector);

    return (
        <div
            className={`${s.pageHeaderWrapper} ${isCollapsedSidebar === "true" ? s.collapsedSidebar : ""}`}
        >
            <NotificationsPopup bellButtonRef={notificationsBtnRef}/>
            <div className={s.pageHeaderContainer}>
                <div className={s.staticElements}>
                    <div className={s.leftSideWrapper}>
                        <button
                            className={s.walletBtn}
                            onClick={() => {
                                dispatch(setAdaptiveMenu(false));
                                dispatch(setTopUpBalanceActive(true));
                            }}
                        >
                            <SvgSelector id="wallet"/>
                        </button>
                        <Link className={s.walletBtn} to="/account">
                            <SvgSelector id="account-gear-icon"/>
                        </Link>
                        <button
                            className={s.notificationsBtn}
                            onClick={() => {
                                dispatch(setIsNotificationPopupActive(!isNotificationPopupActive))
                            }}
                            ref={notificationsBtnRef}
                        >
                            <div className={s.bellWrapper}>
                                <SvgSelector id="notifications-bell-icon"/>
                            </div>
                            {
                                hasUnreadNotifications && <span className={s.circle}></span>
                            }
                        </button>
                        {is_tech_user && (
                            <Link to='/accounts-management' className={s.standardInstallPrice}>
                                <SvgSelector id='standard-install-price-icon'/>
                                <span>$ {standardClickPriceValue || "----"}</span>
                            </Link>
                        )}
                        <LanguageDropdown/>
                    </div>

                    <button
                        className={s.menuBtn}
                        onClick={() => dispatch(setAdaptiveMenu(!isAdaptiveMenuActive))}
                    >
                        <SvgSelector id="burger-menu"/>
                    </button>
                </div>


                {
                    (isSearch || isButton || isButton) && <div className={s.changingElements}>
                        {
                            isSearch && <Search value={searchValue} setValue={setSearchValue}/>
                        }

                        {
                            isLink && <Link to={linkPath} className={s.primaryBtn}>
                                {isCreateButton && <SvgSelector id="plus-button"/>}
                                {buttonText}
                            </Link>
                        }

                        {
                            isButton && <button className={`${s.primaryBtn} ${!isCreateButton ? s.default : ""}`} onClick={buttonHandler}>
                                {isCreateButton && <SvgSelector id="plus-button"/>}
                                {buttonText}
                            </button>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default Header;