import React from "react";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import Header from "../../../components/Partials/Header/Header";
import {
    globalDataSelector,
} from "../../../redux/slices/globalDataSlice";
import s from "./page404.module.scss";

const Page404 = () => {
    const {isCollapsedSidebar} = useSelector(globalDataSelector);

    const {t} = useTranslation();

    return (
        <div
            className={`${s.page404Wrapepr} ${
                isCollapsedSidebar === "true" ? s.collapsedSidebar : ""
            }`}
        >
            <Header/>
            <div className={s.pageContent}>
                <div className={s.contentWrapper}>
                    <span className={s.loader}></span>
                    <div className={s.letters404}>404</div>
                    <div className={s.messages}>
                        <p>{t("cant_find_page")}</p>
                        <p>{t("incorrect_page_path")}</p>
                    </div>
                    <div className={s.buttonContainer}>
                        <Link to="/app-catalog" className={s.primaryBtn}>
                            {t("back_to_main_page")}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Page404;
