import { useDispatch, useSelector } from "react-redux";
import { setTopUpBalanceActive } from "../../../redux/slices/financeSlice";
import {
  globalDataSelector,
  setAdaptiveMenu,
} from "../../../redux/slices/globalDataSlice";
import { Link, useSearchParams } from "react-router-dom";
import useGetSearchParams from "../../../hooks/useGetSearchParams";
import useSetSearchParams from "../../../hooks/useSetSearchParams";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDebounce } from "../../../hooks/useDebounce";
import {
  page,
  search,
} from "../../Authorized/Statistics/ClicksStats/ClicksStats";
import s from "./management.module.scss";
import SvgSelector from "../../../components/SvgSelector/SvgSelector";
import Search from "../../../components/Search/Search";
import AddNewTeam from "../../../components/UiKit/Popups/AddNewTeam/AddNewTeam";
import Users from "./Tabs/Users/Users";
import PostbackStatus from "./Tabs/PostbackStatus/PostbackStatus";
import InterfaceApps from "./Tabs/InterfaceApps/InterfaceApps";
import CategoryPriority from "./Tabs/CategoryPriority/CategoryPriority";
import {
  fetchCategoryPriority,
  fetchStandardClickPrice,
  fetchTechnicalUsersList,
  managementSelector,
} from "../../../redux/slices/managementSlice";
import { getTableDefaultParams } from "../../../api/helpers/scripts";
import { INVALID_PAGE_ERROR } from "../../../utils/vars/staticVariables";
import { useTranslation } from "react-i18next";
import LanguageDropdown from "../../../components/LanguageDropdown/LanguageDropdown";
import {
  is_tech_user
} from "../../../storage/user";
import Header from "../../../components/Partials/Header/Header";

const tabList = [
  {
    name: "users",
    icon: "management-user-tab-icon",
    value: "users",
  },
  {
    name: "postback_statuses",
    icon: "postback-status-icon",
    value: "postback_status",
  },
  {
    name: "category_priority",
    icon: "category-priority-icon",
    value: "category_priority",
  },
  // {
  //   name: "Приложения интерфейса",
  //   icon: "interface-apps-icon",
  //   value: "interface_apps",
  // },
];

const Management = () => {
  const [currentTab, setCurrentTab] = useState(tabList[0].value);

  const [isAddNewTeamPopupActive, setAddNewTeamPopupActive] = useState(false);

  const underlineRef = useRef(null);

  const { standardClickPriceValue } = useSelector(managementSelector);

  const { isCollapsedSidebar, isAdaptiveMenuActive } =
    useSelector(globalDataSelector);

  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const { search: searchValue, page: pageValue } = useGetSearchParams();

  const updateSearchParams = useSetSearchParams(setSearchParams);

  const { t, i18n } = useTranslation();

  const [inputSearchValue, setInputSearchValue] = useDebounce((value) => {
    if (value) {
      updateSearchParams(search, value);
    } else {
      updateSearchParams(search, value, true);
    }
  }, 600);

  useEffect(() => {
    const activeBtn = document.querySelector(`.${s.active}`);
    if (activeBtn && underlineRef.current) {
      const { left, width } = activeBtn.getBoundingClientRect();
      const parentLeft = activeBtn.parentElement.getBoundingClientRect().left;
      const scrollLeft = activeBtn.parentElement.scrollLeft; // Додаємо врахування скролу

      underlineRef.current.style.width = `${width}px`;
      underlineRef.current.style.transform = `translateX(${left - parentLeft + scrollLeft}px)`;
    }
  }, [currentTab, i18n.language]);

  useEffect(() => {
    if (!searchValue) {
      setInputSearchValue("");
    } else {
      setInputSearchValue(searchValue);
    }
  }, [searchParams]);

  const fetchUsers = useCallback(() => {
    const promise = dispatch(
      fetchTechnicalUsersList(
        getTableDefaultParams({
          searchParams,
          defaultPageSize: 25,
        }),
      ),
    );

    promise.unwrap().catch((err) => {
      if (err?.message === INVALID_PAGE_ERROR) {
        updateSearchParams(page, 1);
      }
    });

    return promise;
  }, [dispatch, searchParams, updateSearchParams]);

  useEffect(() => {
    const promise = fetchUsers();

    return () => {
      promise.abort();
    };
  }, [dispatch, searchParams]);

  useEffect(() => {
    dispatch(fetchCategoryPriority())
  },[dispatch])

  return (
    <div
      className={`${s.balancesWrapper} ${isCollapsedSidebar === "true" ? s.collapsedSidebar : ""}`}
    >
      <AddNewTeam
        active={isAddNewTeamPopupActive}
        setActive={setAddNewTeamPopupActive}
        setCurrentTab={setCurrentTab}
        fetchUsers={fetchUsers}
      />
      <Header
          searchValue={inputSearchValue}
          setSearchValue={setInputSearchValue}
          isSearch
          isButton
          buttonText={t("create_team")}
          isCreateButton
          buttonHandler={() => setAddNewTeamPopupActive(true)}
      />
      <div className={s.pageContent}>
        <div className={s.switchPageContentContainer}>
          <div className={s.buttonsContainer}>
            {tabList.map(({ name, value, icon }) => (
              <button
                key={name}
                onClick={() => setCurrentTab(value)}
                className={`${s.pageBtn} ${currentTab === value ? s.active : ""}`}
              >
                <SvgSelector id={icon} />
                {t(name)}
              </button>
            ))}

            <div className={s.underline} ref={underlineRef}></div>
          </div>
        </div>

        {currentTab === "users" && <Users setCurrentTab={setCurrentTab} />}
        {currentTab === "postback_status" && (
          <PostbackStatus setCurrentTab={setCurrentTab} />
        )}
        {currentTab === "category_priority" && (
          <CategoryPriority setCurrentTab={setCurrentTab} />
        )}
        {/*{currentTab === "interface_apps" && (*/}
        {/*  <InterfaceApps setCurrentTab={setCurrentTab} />*/}
        {/*)}*/}
      </div>
    </div>
  );
};

export default Management;
