import {useDispatch, useSelector} from "react-redux";
import {
    exportBalances,
    exportTransactions,
    fetchTransactions,
    financeSelector,
    setTopUpBalanceActive,
} from "../../../redux/slices/financeSlice";
import {authSelector} from "../../../redux/slices/authSlice";
import {
    fetchUsersList,
    globalDataSelector,
    setAdaptiveMenu,
} from "../../../redux/slices/globalDataSlice";
import {Link, useSearchParams} from "react-router-dom";
import useGetSearchParams from "../../../hooks/useGetSearchParams";
import {
    INVALID_PAGE_ERROR,
    PURPOSE_REFILL,
    PURPOSE_WRITE_OFF,
    snackbar_error,
    snackbar_info,
    snackbar_success, XLSX,
} from "../../../utils/vars/staticVariables";
import useSetSearchParams from "../../../hooks/useSetSearchParams";
import React, {useEffect, useMemo, useRef} from "react";

import {useSelectOptions} from "../../../utils/helpers/useSelectHelpers";
import {downloadTableFromBinaryData} from "../../../utils/helpers/downloadTableFromBinaryData";
import {
    created_at_after,
    created_at_before,
    page,
    purpose,
    user,
} from "../Statistics/ClicksStats/ClicksStats";
import {getDefaultParams} from "../../../api/helpers/scripts";
import s from "./transactions.module.scss";
import MultiSelect from "../../../components/Selects/MultiSelect/MultiSelect";
import {MuiDatePicker} from "../../../components/DatePickers/MuiDatePicker";
import {
    formatTimestampToServerDate,
    stringToDayJs,
} from "../../../utils/helpers/formatTime";
import Pagination from "../../../components/Pagination/Pagination";
import {params} from "../../../utils/vars/params";
import RefillOption from "./TransactionsOptions/RefillOption";
import WriteOffOption from "./TransactionsOptions/WriteOffOption";
import SvgSelector from "../../../components/SvgSelector/SvgSelector";
import useCustomSnackbar from "../../../hooks/useCustomSnackbar";
import {useSnackbar} from "notistack";
import {cleanSearchParams, hasNonEmptyParams} from "../../../utils/helpers/cleanFilters";
import {useTranslation} from "react-i18next";
import LanguageDropdown from "../../../components/LanguageDropdown/LanguageDropdown";
import {
    is_tech_user,
} from "../../../storage/user";
import {managementSelector} from "../../../redux/slices/managementSlice";
import Header from "../../../components/Partials/Header/Header";

const Transactions = () => {
    const underlineRef = useRef(null);

    const dispatch = useDispatch();

    const {exportTransactionsPending, exportBalancesPending, totalCount} =
        useSelector(financeSelector);

    const {isPartner} = useSelector(authSelector);
    const {usersList, isCollapsedSidebar, isAdaptiveMenuActive} =
        useSelector(globalDataSelector);
    const {standardClickPriceValue} = useSelector(managementSelector);


    const [searchParams, setSearchParams] = useSearchParams();

    const showSnackbar = useCustomSnackbar();
    const {closeSnackbar} = useSnackbar();

    const {
        page: pageValue,
        created_at_after: createdAtAfterParam,
        created_at_before: createdAtBeforeParam,
        user: userParam,
        purpose: purposeParam,
    } = useGetSearchParams();

    const {t, i18n} = useTranslation();

    const purposeContent = isPartner
        ? PURPOSE_WRITE_OFF
        : purposeParam || PURPOSE_REFILL;

    const updateSearchParams = useSetSearchParams(setSearchParams);

    const selectedUser = useMemo(() => {
        return userParam ? {value: userParam, label: userParam} : null;
    }, [userParam]);

    useEffect(() => {
        const activeBtn = document.querySelector(`.${s.active}`);
        if (activeBtn && underlineRef.current) {
            const {left, width} = activeBtn.getBoundingClientRect();
            const parentLeft = activeBtn.parentElement.getBoundingClientRect().left;
            underlineRef.current.style.width = `${width}px`;
            underlineRef.current.style.transform = `translateX(${left - parentLeft}px)`;
        }
    }, [purposeContent, isPartner, i18n.language]);

    useEffect(() => {
        if (is_tech_user) {
            dispatch(fetchUsersList());
        }
    }, [dispatch]);

    const usersListOptions = useSelectOptions(usersList, "email", "email");

    const onExportTransactions = async () => {
        const exportTransactionsSnackbar = showSnackbar(
            t("transactions_export_processing"),
            snackbar_info,
            true,
        );

        const exportTranscationsResult = await dispatch(
            exportTransactions({params: searchParams}),
        );

        if (exportTranscationsResult?.payload?.status === 200) {
            downloadTableFromBinaryData(exportTranscationsResult?.payload?.data, "transactions", XLSX);
            closeSnackbar(exportTransactionsSnackbar);
            showSnackbar(t("transactions_report_downloaded"), snackbar_success);
        } else {
            closeSnackbar(exportTransactionsSnackbar);
            showSnackbar(t("failed_transactions_export"), snackbar_error);
        }
    };

    const onExportBalances = async () => {
        const exportBalancesSnackbar = showSnackbar(
            t("balances_export_processing"),
            snackbar_info,
            true,
        );

        const exportBalancesResult = await dispatch(exportBalances({params: searchParams}));

        if (exportBalancesResult?.payload?.status === 200) {
            downloadTableFromBinaryData(exportBalancesResult?.payload?.data, "transactions");
            closeSnackbar(exportBalancesSnackbar);
            showSnackbar(t("balances_report_downloaded"), snackbar_success);
        } else {
            closeSnackbar(exportBalancesSnackbar);
            showSnackbar(t("failed_balances_export"), snackbar_error);
        }
    };


    useEffect(() => {
        if (isPartner === false) {
            if (
                purposeContent !== PURPOSE_REFILL &&
                purposeContent !== PURPOSE_WRITE_OFF
            ) {
                updateSearchParams(purpose, PURPOSE_REFILL);
            }
        }
        if (isPartner === true) {
            updateSearchParams(purpose, PURPOSE_WRITE_OFF);
        }
    }, [isPartner]);

    useEffect(() => {
        const promise = dispatch(
            fetchTransactions(
                getDefaultParams({
                    searchParams,
                    defaultPageSize: 25,
                    purpose: purposeContent,
                }),
            ),
        );

        promise.unwrap().catch((err) => {
            if (err?.message === INVALID_PAGE_ERROR) {
                updateSearchParams(page, 1);
            }
        });

        return () => {
            promise.abort();
        };
    }, [dispatch, searchParams]);

    return (
        <div
            className={`${s.transactionsWrapper} ${isCollapsedSidebar === "true" ? s.collapsedSidebar : ""}`}
        >
            <Header
                isButton={isPartner === false}
                buttonText={t("top_up")}
                buttonHandler={() => dispatch(setTopUpBalanceActive(true))}
            />
            <div className={s.pageContent}>
                <div className={s.switchPageContentContainer}>
                    {isPartner === false && (
                        <button
                            className={`${s.pageBtn} ${
                                purposeContent === PURPOSE_REFILL ? s.active : ""
                            }`}
                            onClick={() => {
                                updateSearchParams(purpose, PURPOSE_REFILL);
                                updateSearchParams(page, 1);
                            }}
                        >
                            <SvgSelector id="recharge-icon"/>
                            {t("refills")}
                        </button>
                    )}

                    <button
                        className={`${s.pageBtn} ${
                            purposeContent === PURPOSE_WRITE_OFF ? s.active : ""
                        }`}
                        onClick={() => {
                            updateSearchParams(purpose, PURPOSE_WRITE_OFF);
                            updateSearchParams(page, 1);
                        }}
                    >
                        <SvgSelector id="write-off-icon"/>
                        {t("write_offs")}
                    </button>
                    <div className={s.underline} ref={underlineRef}></div>
                </div>

                <div className={s.filtersWrapper}>
                    <div className={s.filters}>
                        {is_tech_user && (
                            <div className={s.btnsWrapper}>
                                <button
                                    className={s.blueBorderIconBtn}
                                    onClick={onExportBalances}
                                    disabled={exportBalancesPending}
                                >
                                    <SvgSelector id="download-btn-icon"/>
                                    {t("export_balances")}
                                </button>
                                <button
                                    className={s.blueBorderIconBtn}
                                    onClick={onExportTransactions}
                                    disabled={exportTransactionsPending}
                                >
                                    <SvgSelector id="download-btn-icon"/>
                                    {t("export_transactions")}
                                </button>
                            </div>
                        )}
                        <div
                            className={s.filter}
                            style={
                                !is_tech_user
                                    ? {width: "100%"}
                                    : null
                            }
                        >
                            {is_tech_user && (
                                <div className={s.selectWrapper}>
                                    <div className={s.selectIconWrapper}>
                                        <SvgSelector id="user-select-icon"/>
                                    </div>
                                    <MultiSelect
                                        isClearable={true}
                                        options={usersListOptions}
                                        value={selectedUser}
                                        setSelectedOptions={(option) => {
                                            if (option) {
                                                const {value} = option;
                                                updateSearchParams(user, value);
                                            } else {
                                                updateSearchParams(user, "", true);
                                            }
                                        }}
                                        placeholder={t("choose_user")}
                                        isMulti={false}
                                    />
                                </div>
                            )}

                            <div className={s.datePickersWrapper}>
                                <div className={s.datePicker}>
                                    <MuiDatePicker
                                        label={t("date_from")}
                                        value={stringToDayJs(createdAtAfterParam)}
                                        searchParamValue={created_at_after}
                                        updateSearchParams={updateSearchParams}
                                    />
                                </div>
                                <div className={s.datePicker}>
                                    <MuiDatePicker
                                        label={t("date_to")}
                                        value={stringToDayJs(createdAtAfterParam)}
                                        searchParamValue={created_at_before}
                                        updateSearchParams={updateSearchParams}
                                    />
                                </div>
                            </div>

                            {hasNonEmptyParams(searchParams) && (
                                <div className={s.cleanBtnContainer}>
                                    <button
                                        className={s.blueBorderBtn}
                                        onClick={() => cleanSearchParams(searchParams, setSearchParams)}
                                    >
                                        {t("clear_button")}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className={s.pageInnerContent}>
                    {purposeContent === PURPOSE_REFILL && (
                        <RefillOption updateSearchParams={updateSearchParams}/>
                    )}

                    {purposeContent === PURPOSE_WRITE_OFF && (
                        <WriteOffOption updateSearchParams={updateSearchParams}/>
                    )}
                </div>

                <div
                    className={`${s.paginationContainer} ${isCollapsedSidebar === "true" ? s.collapsed : ""}`}
                >
                    {totalCount > 25 && (
                        <Pagination
                            currentPage={parseInt(pageValue) || 1}
                            setCurrentPage={(page) => {
                                updateSearchParams(params.page, page);
                            }}
                            total={totalCount}
                            limit={25}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Transactions;
