import i18n from "i18next";
import {initReactI18next} from "react-i18next";

import ru from "../locales/ru.json";
import en from "../locales/en.json";
import ua from "../locales/ua.json";
import {getLanguage} from "../storage/switchLanguage";

i18n.use(initReactI18next).init({
    resources: {
        ru: {translation: ru},
        en: {translation: en},
        ua: {translation: ua},
    },
    lng: getLanguage(),
    fallbackLng: "ua",
    interpolation: {escapeValue: false},
});
