import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllRedirectorsFilters } from "../../../api/helpers/fetchFilters";
import s from "./allRedirectors.module.scss";
import {
  globalDataSelector,
} from "../../../redux/slices/globalDataSlice";
import { applicationSelector } from "../../../redux/slices/applicationSlice";
import useCustomSnackbar from "../../../hooks/useCustomSnackbar";
import { useSnackbar } from "notistack";
import useSetSearchParams from "../../../hooks/useSetSearchParams";
import {
  useSearchParamsOptions,
  useSelectOptions,
} from "../../../utils/helpers/useSelectHelpers";
import {
  application_name,
  domain_address,
  page,
  search,
} from "../Statistics/ClicksStats/ClicksStats";
import { useDebounce } from "../../../hooks/useDebounce";
import { useSearchParams } from "react-router-dom";
import useGetSearchParams from "../../../hooks/useGetSearchParams";
import SvgSelector from "../../../components/SvgSelector/SvgSelector";
import { getTableDefaultParams } from "../../../api/helpers/scripts";
import {
  INVALID_PAGE_ERROR,
  snackbar_error,
  snackbar_info,
  snackbar_success,
} from "../../../utils/vars/staticVariables";
import {
  deleteRedirector,
  fetchRedirectorsWithParams,
  redirectorSelector,
} from "../../../redux/slices/redirectorSlice";
import RedirectorCard from "./RedirectorCard/RedirectorCard";
import {getUser, is_tech_user} from "../../../storage/user";
import CustomSkeleton from "../../../components/UiKit/CustomSkeleton/CustomSkeleton";
import CreateRedirectorPopup from "./CreateRedirectorPopup/CreateRedirectorPopup";
import Pagination from "../../../components/Pagination/Pagination";
import { params } from "../../../utils/vars/params";
import EmptyList from "../../../components/EmptyList/EmptyList";
import {cleanSearchParams, hasNonEmptyParams} from "../../../utils/helpers/cleanFilters";
import FilterApplicationSelect from "../../../components/Selects/FilterApplicationSelect";
import { cleanDomain } from "../../../utils/helpers/cleanDomain";
import { useTranslation } from "react-i18next";
import {DOMAINS_REQUEST} from "../../../api/helpers/getFilterRequest";
import {CustomAsyncSelect} from "../../../components/Selects/CustomAsyncSelect";
import Header from "../../../components/Partials/Header/Header";

const AllRedirectors = () => {
  const [isCreateRedirectorActive, setCreateRedirectorActive] = useState(false);

  const { totalCount, redirectorCardsList, redirectorCardsPending } =
    useSelector(redirectorSelector);
  const { asyncDomainsList, asyncDomainsPending, isCollapsedSidebar } = useSelector(globalDataSelector);
  const { appList } = useSelector(applicationSelector);

  const showSnackbar = useCustomSnackbar();
  const { closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const { search: urlSearchValue, page: pageValue } = useGetSearchParams();

  const updateSearchParams = useSetSearchParams(setSearchParams);

  const { t } = useTranslation();

  const { user_id } = getUser();

  const [selectedDomains, selectedApplications] = useSearchParamsOptions(
    searchParams,
    [domain_address, application_name],
  );

  useEffect(() => {
    const domainParam = searchParams.get("domain_address");

    if (domainParam) {
      const domains = domainParam.split(",");
      const cleanedDomains = domains.map((domain) =>
        cleanDomain(decodeURIComponent(domain)),
      );

      // Якщо очищені домени не збігаються з оригінальними, оновлюємо параметри
      if (cleanedDomains.join(",") !== domains.join(",")) {
        updateSearchParams("domain_address", cleanedDomains.join(","));
      }
    }
  }, [searchParams, updateSearchParams]);

  const domainsListOptions = useSelectOptions(
      asyncDomainsList,
    "address",
    "address",
  );

  const appListOptions = useMemo(() => {
    return appList.map((item) => ({
      label: item.name,
      value: item.name,
      is_alive: is_tech_user ? item.is_alive : true,
      name: "app_list",
      icon: item.icon,
    }));
  }, [appList]);

  const [inputSearchValue, setInputSearchValue] = useDebounce((value) => {
    if (value) {
      updateSearchParams(search, value);
    } else {
      updateSearchParams(search, value, true);
    }
  }, 600);

  useEffect(() => {
    if (!urlSearchValue) {
      setInputSearchValue("");
    } else {
      setInputSearchValue(urlSearchValue);
    }
  }, [searchParams]);

  useEffect(() => {
    fetchAllRedirectorsFilters(dispatch);
  }, [dispatch]);

  const fetchData = useCallback(() => {
    const promise = dispatch(
      fetchRedirectorsWithParams(
        getTableDefaultParams({
          searchParams,
          defaultPageSize: 20,
        }),
      ),
    );

    promise.unwrap().catch((err) => {
      if (err?.message === INVALID_PAGE_ERROR) {
        updateSearchParams(page, 1);
      }
    });

    return promise;
  }, [dispatch, searchParams, updateSearchParams]);

  useEffect(() => {
    const promise = fetchData();

    return () => {
      promise.abort();
    };
  }, [dispatch, searchParams]);

  const onDeleteHandler = useCallback(
    async (id) => {
      const pendingDeleteSnackbar = showSnackbar(
        t("notif_delete_flow"),
        snackbar_info,
        true,
      );

      const redirectorDeleteResult = await dispatch(deleteRedirector(id));

      if (redirectorDeleteResult?.payload?.status === 204) {
        fetchData();
        closeSnackbar(pendingDeleteSnackbar);
        showSnackbar(t("redirector_is_deleted"), snackbar_success);
      } else {
        closeSnackbar(pendingDeleteSnackbar);
        showSnackbar(t("failed_delete_redirector"), snackbar_error);
      }
    },
    [closeSnackbar, dispatch, fetchData, showSnackbar],
  );

  return (
    <div
      className={`${s.allRedirectorsWrapper} ${isCollapsedSidebar === "true" ? s.collapsedSidebar : ""}`}
    >
      <CreateRedirectorPopup
        active={isCreateRedirectorActive}
        setActive={setCreateRedirectorActive}
        fetchRedirectors={fetchData}
        appList={appList}
      />
      <Header
          searchValue={inputSearchValue}
          setSearchValue={setInputSearchValue}
          isSearch
          isButton
          buttonText={t("create")}
          isCreateButton
          buttonHandler={() => setCreateRedirectorActive(true)}
      />
      <div className={s.pageContent}>
        <div className={s.filtersWrapper}>
          <div className={s.filters}>
            <div className={s.selectWrapper}>
              <div className={s.selectIconWrapper}>
                <SvgSelector id="domain-select-icon" />
              </div>
              <CustomAsyncSelect
                  extractDomain={true}
                  value={selectedDomains}
                  options={domainsListOptions}
                  setOptions={(values) =>
                      updateSearchParams(
                          domain_address,
                          values.map((el) => el.value).join(","),
                      )
                  }
                  placeholder={t("domain_placeholder")}
                  requestName={DOMAINS_REQUEST}
                  pageSize={50}
                  pending={asyncDomainsPending}
              />
            </div>
            <div className={s.selectWrapper}>
              <div className={s.selectIconWrapper}>
                <SvgSelector id="app-select-icon" />
              </div>
              <FilterApplicationSelect
                isClearable={true}
                value={selectedApplications}
                options={appListOptions}
                onChange={(values) =>
                  updateSearchParams(
                    application_name,
                    values.map((el) => el.value).join(","),
                  )
                }
                placeholder={t("application_placeholder")}
                isMulti={true}
              />
            </div>
            {hasNonEmptyParams(searchParams) && (
              <div className={s.cleanBtnContainer}>
                <button className={s.blueBorderBtn} onClick={() => cleanSearchParams(searchParams, setSearchParams)}>
                  {t("clear_button")}
                </button>
              </div>
            )}
          </div>
        </div>
        <div className={s.pageInnerContent}>
          {totalCount === 0 && !redirectorCardsPending ? (
            <EmptyList />
          ) : redirectorCardsPending ? (
            <div className={s.skeletonsWrapper}>
              {[...new Array(6)].map((_, index) => (
                <div className={s.skeletonItem} key={index}>
                  <CustomSkeleton styles={s.skeletonStyle} />
                </div>
              ))}
            </div>
          ) : (
            <div className={s.cardsWrapper}>
              {redirectorCardsList.map((item) => (
                <RedirectorCard
                  key={item.id}
                  {...item}
                  appList={appList}
                  user_id={user_id}
                  onDeleteHandler={onDeleteHandler}
                />
              ))}
            </div>
          )}
        </div>
        <div
          className={`${s.paginationContainer} ${isCollapsedSidebar === "true" ? s.collapsed : ""}`}
        >
          {!redirectorCardsPending && totalCount > 0 && (
            <div className={s.newRecordsCount}>
              {t("total")}:{" "}
              {!redirectorCardsPending && <span>{totalCount}</span>}
            </div>
          )}
          {totalCount > 20 && (
            <Pagination
              currentPage={parseInt(pageValue) || 1}
              setCurrentPage={(page) => {
                updateSearchParams(params.page, page);
              }}
              total={totalCount}
              limit={20}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AllRedirectors;
