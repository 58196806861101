import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SvgSelector from "../SvgSelector/SvgSelector";
import s from "./search.module.scss";

const Search = ({ value, setValue }) => {
  const searchRef = useRef(null);
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const { t } = useTranslation();

  const handleSearchFocus = () => {
    setIsSearchFocused(true);
  };

  const handleSearchBlur = () => {
    setIsSearchFocused(false);
  };

  return (
    <div className={s.searchInputWrapper}>
      <span>
        <SvgSelector id="search-icon" />
      </span>
      <input
        type="text"
        className={s.searchInput}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        ref={searchRef}
        onFocus={handleSearchFocus}
        onBlur={handleSearchBlur}
        placeholder={t("search_placeholder")}
      />
    </div>
  );
};

export default Search;
