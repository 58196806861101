import React, {useCallback, useEffect} from 'react';
import EmptyList from "../../../../components/EmptyList/EmptyList";
import s from "../flows.module.scss";
import CustomSkeleton from "../../../../components/UiKit/CustomSkeleton/CustomSkeleton";
import {useDispatch, useSelector} from "react-redux";
import {deleteFlow, flowSelector} from "../../../../redux/slices/flowSlice";
import {globalDataSelector} from "../../../../redux/slices/globalDataSlice";
import {authSelector} from "../../../../redux/slices/authSlice";
import {useSearchParams} from "react-router-dom";
import {snackbar_error, snackbar_info, snackbar_success} from "../../../../utils/vars/staticVariables";
import useCustomSnackbar from "../../../../hooks/useCustomSnackbar";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {flowsActiveTabColumnsList} from "../../../../utils/vars/params";
import {domain_address, search} from "../../Statistics/ClicksStats/ClicksStats";
import useSetSearchParams from "../../../../hooks/useSetSearchParams";
import ActiveFlowTd from "../ActiveFlowTd";

const Active = ({memorizedGeosList, fetchData}) => {
    const {flowsListPending, flowsList, totalCount, duplicateFlowPending} =
        useSelector(flowSelector);
    const {
        geoListPending,
    } = useSelector(globalDataSelector);

    const dispatch = useDispatch();
    const {isPartner} = useSelector(authSelector);
    const [searchParams, setSearchParams] = useSearchParams();
    const updateSearchParams = useSetSearchParams(setSearchParams);
    const showSnackbar = useCustomSnackbar();
    const {closeSnackbar} = useSnackbar();
    const {t} = useTranslation();

    const onDeleteHandler = useCallback(
        async (id, domain) => {
            const loadingSnackbarKey = showSnackbar(
                t("notif_delete_flow"),
                snackbar_info,
                true,
            );

            const flowDeleteResult = await dispatch(deleteFlow({id}));

            if (flowDeleteResult?.payload?.status === 200) {
                fetchData();
                closeSnackbar(loadingSnackbarKey);
                showSnackbar(
                    `${domain} ${t("notif_was_deleted")}`,
                    snackbar_success,
                );
            } else {
                closeSnackbar(loadingSnackbarKey);
                showSnackbar(
                    `${t("notif_failed_to_delete")} ${domain} ${t("notif_lowercase_flow")}`,
                    snackbar_error,
                );
            }
        },
        [closeSnackbar, dispatch, fetchData, showSnackbar],
    );

    useEffect(() => {
        const promise = fetchData();

        return () => {
            promise.abort();
        };
    }, [dispatch, searchParams]);

    const clearDomainParam = useCallback(() => {
        updateSearchParams(domain_address, "", true)
        updateSearchParams(search, "", true)
    },[updateSearchParams])

    const activeTabTableThead = <thead>
    <tr>
        {flowsActiveTabColumnsList.map(({name}) => (
            <th key={name}>
                <div className={s.headerContent}>
                    {t(name)}
                </div>
            </th>
        ))}
    </tr>
    </thead>

    return (
        <>
            {totalCount === 0 && !flowsListPending ? (
                <EmptyList/>
            ) : flowsListPending || geoListPending ? (
                <section className={s.body}>
                    <table>
                        {activeTabTableThead}
                    </table>
                    <div className={s.skeletonWrapper}>
                        <CustomSkeleton styles={s.skeletonTable}/>
                    </div>
                </section>
            ) : (
                <section className={s.body}>
                    <table>
                        {activeTabTableThead}
                        <tbody>
                        {flowsList.map((item, index) => (
                            <ActiveFlowTd
                                flowsList={flowsList}
                                index={index}
                                geosList={memorizedGeosList}
                                key={item.id}
                                getFlows={fetchData}
                                isPartner={isPartner}
                                duplicateFlowPending={duplicateFlowPending}
                                item={item}
                                onDeleteHandler={onDeleteHandler}
                                statusColumn
                                clearDomainParam={clearDomainParam}
                            />
                        ))}
                        </tbody>
                    </table>
                </section>
            )}
        </>
    )
}

export default Active;