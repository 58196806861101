import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchWheelStylesList,
  prelandingSelector,
} from "../../../../../../../redux/slices/prelandingSlice";
import CustomSkeleton from "../../../../../../../components/UiKit/CustomSkeleton/CustomSkeleton";
import s from "../../../../../Prelandings/CreatePrelanding/Create/create.module.scss";
import ReactSelectTheme from "../../../../../../../components/ReactSelectTheme/ReactSelectTheme";
import {
  cloacaModeOptions,
  prelandingStatusList,
  prelandingTypesList,
  wordPressOptions,
} from "../../../../../../../utils/vars/staticVariables";
import LimitedLengthInput from "../../../../../../../components/LimitedLengthInput/LimitedLengthInput";
import { Radio } from "@mui/material";
import AdaptiveWheelPreviewBtn from "../../../../../Prelandings/AdaptiveWheelPreviewBtn/AdaptiveWheelPreviewBtn";
import PopupWheel from "../../../../../Prelandings/PopupWheel/PopupWheel";
import Wheel from "../../../../../Prelandings/Wheel/Wheel";
import { Link } from "react-router-dom";
import PrimaryBtn from "../../../../../../../components/UiKit/Buttons/PrimaryBorderBtn/PrimaryBorderBtn";
import { setPrelandTabContent } from "../../../../../../../redux/slices/flowSettingsSlice";
import { useTranslation } from "react-i18next";

const CreatePrelanding = ({ formik, resetForm, setContent }) => {
  const [activeAdaptiveWheelPreview, setActiveAdaptiveWheelPreview] =
    useState(false);
  const errorRefs = useRef({});
  const { wheelStylesList, wheelStylesLoading, createPrelandPending } =
    useSelector(prelandingSelector);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    // Функція для скролу до першого поля з помилкою з урахуванням фіксованого хедера
    const scrollToError = () => {
      const firstErrorKey = Object.keys(formik.errors)[0];
      if (firstErrorKey && errorRefs.current[firstErrorKey]) {
        const errorElement = errorRefs.current[firstErrorKey];
        const headerOffset = 80; // Висота фіксованого хедера
        const elementPosition =
          errorElement.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - headerOffset;

        // Перевірка, чи поле з помилкою знаходиться поза зоною видимості
        const isOutOfView =
          elementPosition < window.pageYOffset ||
          elementPosition > window.pageYOffset + window.innerHeight;

        if (isOutOfView) {
          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });
        }
      }
    };

    if (formik.isSubmitting && Object.keys(formik.errors).length > 0) {
      scrollToError();
    }
  }, [formik.isSubmitting, formik.errors]);

  const skeletons = (styles) => {
    return [...new Array(15)].map((_, index) => (
      <CustomSkeleton styles={styles} key={index} />
    ));
  };

  const {
    header_text,
    rotation_button_text,
    respin_button_text,
    style,
    sectors,
    bonus_button_text,
    popup_win_text,
    winning_button_text,
  } = formik.values;

  const selectedStatus = (() => {
    const foundOption = prelandingStatusList.find(
      ({ value }) => value === formik.values.status,
    );

    if (foundOption) {
      return { ...foundOption, label: t(foundOption.label) };
    }

    return {
      ...prelandingStatusList[0],
      label: t(prelandingStatusList[0].label),
    };
  })();

  const prelandingType = (() => {
    const foundOption = prelandingTypesList.find(
      ({ value }) => value === formik.values.prelanding_type,
    );

    if (foundOption) {
      return { ...foundOption, label: t(foundOption.label) };
    }

    return {
      ...prelandingTypesList[0],
      label: t(prelandingTypesList[0].label),
    };
  })();

  return (
    <form className={s.createPrelandingForm} onSubmit={formik.handleSubmit}>
      <div className={s.formContentWrapper}>
        <div className={s.formFieldsWrapper}>
          <div className={s.fieldWrapper}>
            <p className={s.fieldName}>{t("prelanding")}</p>
            <ReactSelectTheme
              darkTheme={true}
              options={prelandingStatusList.map((option) => {
                return {
                  ...option,
                  label: t(option.label),
                };
              })}
              value={selectedStatus}
              action={(selectedOption) =>
                formik.setFieldValue("status", selectedOption.value)
              }
            />
          </div>
          <div
            className={s.fieldWrapper}
            ref={(el) => (errorRefs.current.name = el)}
          >
            <p className={s.fieldName}>{t("name_column")}</p>
            <input
              type="text"
              name="name"
              className={`${s.textInput} ${
                formik.touched.name && formik.errors.name ? s.error : ""
              }`}
              placeholder={t("prelanding_name")}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className={s.error}>{formik.errors.name}</div>
            ) : null}
          </div>
          <div className={s.fieldWrapper}>
            <p className={s.fieldName}>{t("prelanding_type")}</p>
            <ReactSelectTheme
              darkTheme={true}
              options={prelandingTypesList.map((option) => {
                return {
                  ...option,
                  label: t(option.label),
                };
              })}
              value={prelandingType}
              action={(selectedOption) =>
                formik.setFieldValue("prelanding_type", selectedOption.value)
              }
            />
          </div>
          <div
            className={s.fieldWrapper}
            ref={(el) => (errorRefs.current.header_text = el)}
          >
            <p className={s.fieldName}>{t("headline")}</p>
            <LimitedLengthInput
              value={formik.values.header_text}
              fieldName="header_text"
              action={formik.handleChange}
              onBlur={formik.handleBlur}
              formik={formik}
              maxLength={20}
            />
            {formik.touched.header_text && formik.errors.header_text ? (
              <div className={s.error}>{formik.errors.header_text}</div>
            ) : null}
          </div>
          <div
            className={s.fieldWrapper}
            ref={(el) => (errorRefs.current.rotation_button_text = el)}
          >
            <p className={s.fieldName}>{t("rotation_button_text")}</p>
            <LimitedLengthInput
              value={formik.values.rotation_button_text}
              fieldName="rotation_button_text"
              action={formik.handleChange}
              onBlur={formik.handleBlur}
              formik={formik}
              maxLength={10}
            />
            {formik.touched.rotation_button_text &&
            formik.errors.rotation_button_text ? (
              <div className={s.error}>
                {formik.errors.rotation_button_text}
              </div>
            ) : null}
          </div>
          <div
            className={s.fieldWrapper}
            ref={(el) => (errorRefs.current.respin_button_text = el)}
          >
            <p className={s.fieldName}>{t("respin_button_text")}</p>
            <LimitedLengthInput
              value={formik.values.respin_button_text}
              fieldName="respin_button_text"
              action={formik.handleChange}
              onBlur={formik.handleBlur}
              formik={formik}
              maxLength={10}
            />
            {formik.touched.respin_button_text &&
            formik.errors.respin_button_text ? (
              <div className={s.error}>{formik.errors.respin_button_text}</div>
            ) : null}
          </div>
          <div
            className={s.fieldWrapper}
            ref={(el) => (errorRefs.current.style = el)}
          >
            <p className={s.fieldName}>{t("wheel_style")}</p>
            <ul className={s.wheelStylesWrapper}>
              {wheelStylesLoading
                ? skeletons(s.wheelSkeleton)
                : wheelStylesList.map(({ id, wheel }) => (
                    <button
                      type="button"
                      key={id}
                      onClick={() => formik.setFieldValue("style", id)}
                    >
                      <img
                        src={wheel}
                        alt="wheel picture"
                        className={`${s.wheelImg} ${
                          id === formik.values.style ? s.active : ""
                        }`}
                      />
                    </button>
                  ))}
            </ul>
          </div>
          <div className={s.fieldWrapper}>
            <p className={s.fieldName}>{t("sectors_values")}</p>
            <div className={s.sectorsWrapper}>
              {formik.values.sectors.map(({ is_win, value }, index) => (
                <div className={s.sectorInputWrapper} key={index}>
                  <div className={s.markWinSector}>
                    <label htmlFor={`radio-${index}`}>WIN</label>
                    <Radio
                      id={`radio-${index}`}
                      value={index}
                      sx={{
                        height: 48,
                        boxSizing: "border-box",
                        padding: "3px",
                        color: "white",
                      }}
                      checked={is_win}
                      onChange={() => {
                        const updatedSectors = formik.values.sectors.map(
                          (sector, sectorIndex) => ({
                            ...sector,
                            is_win: index === sectorIndex,
                          }),
                        );
                        formik.setFieldValue("sectors", updatedSectors);
                      }}
                    />
                  </div>
                  <input
                    type="text"
                    className={`${s.textInput} ${
                      formik.touched.sectors?.[index]?.value &&
                      formik.errors.sectors?.[index]?.value
                        ? s.error
                        : ""
                    }`}
                    value={value}
                    onChange={(e) => {
                      const updatedSectors = formik.values.sectors.map(
                        (sector, sectorIndex) =>
                          sectorIndex === index
                            ? { ...sector, value: e.target.value }
                            : sector,
                      );
                      formik.setFieldValue("sectors", updatedSectors);
                    }}
                    placeholder={t("placehoder_type_text")}
                    maxLength={10}
                    onBlur={() =>
                      formik.setFieldTouched(`sectors.${index}.value`)
                    }
                  />
                  {formik.touched.sectors?.[index]?.value &&
                  formik.errors.sectors?.[index]?.value ? (
                    <div className={s.error}>
                      {formik.errors.sectors[index].value}
                    </div>
                  ) : null}
                  <div className={s.lengthIndicator}>{value.length}/10</div>
                </div>
              ))}
            </div>
          </div>
          <div
            className={s.fieldWrapper}
            ref={(el) => (errorRefs.current.bonus_button_text = el)}
          >
            <p className={s.fieldName}>{t("win_popup_headline")}</p>
            <LimitedLengthInput
              value={formik.values.bonus_button_text}
              fieldName="bonus_button_text"
              action={formik.handleChange}
              onBlur={formik.handleBlur}
              formik={formik}
              maxLength={16}
            />
            {formik.touched.bonus_button_text &&
            formik.errors.bonus_button_text ? (
              <div className={s.error}>{formik.errors.bonus_button_text}</div>
            ) : null}
          </div>
          <div
            className={s.fieldWrapper}
            ref={(el) => (errorRefs.current.popup_win_text = el)}
          >
            <p className={s.fieldName}>{t("text_in_win_popup")}</p>
            <LimitedLengthInput
              value={formik.values.popup_win_text}
              fieldName="popup_win_text"
              action={formik.handleChange}
              onBlur={formik.handleBlur}
              formik={formik}
              maxLength={30}
            />
            {formik.touched.popup_win_text && formik.errors.popup_win_text ? (
              <div className={s.error}>{formik.errors.popup_win_text}</div>
            ) : null}
            <p className={s.fieldName} style={{ color: "#99A0AE" }}>
              {t("insert_in_bonus")}
            </p>
          </div>
          <div
            className={s.fieldWrapper}
            ref={(el) => (errorRefs.current.winning_button_text = el)}
          >
            <p className={s.fieldName}>{t("button_text_in_win_popup")}</p>
            <LimitedLengthInput
              value={formik.values.winning_button_text}
              fieldName="winning_button_text"
              action={formik.handleChange}
              onBlur={formik.handleBlur}
              formik={formik}
              maxLength={10}
            />
            {formik.touched.winning_button_text &&
            formik.errors.winning_button_text ? (
              <div className={s.error}>{formik.errors.winning_button_text}</div>
            ) : null}
          </div>
        </div>
        <AdaptiveWheelPreviewBtn setActive={setActiveAdaptiveWheelPreview} />
        <PopupWheel
          setActive={setActiveAdaptiveWheelPreview}
          active={activeAdaptiveWheelPreview}
          wheelNumbers={sectors.map((sector) => sector.value)}
          prelandTitle={header_text}
          spinButtonText={rotation_button_text}
          respinButtonText={respin_button_text}
          winSector={sectors.findIndex((item) => item.is_win == true)}
          popupTitle={bonus_button_text}
          currentWheel={wheelStylesList.find((item) => item.id === style)}
          popupText={popup_win_text}
          popupButtonText={winning_button_text}
        />
        <div className={s.iPhoneWrapper}>
          <Wheel
            wheelNumbers={sectors.map((sector) => sector.value)}
            prelandTitle={header_text}
            spinButtonText={rotation_button_text}
            respinButtonText={respin_button_text}
            winSector={sectors.findIndex((item) => item.is_win == true)}
            popupTitle={bonus_button_text}
            currentWheel={wheelStylesList.find((item) => item.id === style)}
            popupText={popup_win_text}
            popupButtonText={winning_button_text}
          />
        </div>
      </div>
      <div className={s.buttons}>
        <button
          className={s.whiteBorderBtn}
          type="button"
          onClick={() => {
            // dispatch(setPrelandTabContent("choose"));
            setContent("choose");
            resetForm();
          }}
        >
          {t("button_cancel")}
        </button>
        <PrimaryBtn
          text={t("create")}
          submit={true}
          pending={createPrelandPending}
          className={s.customPrimaryBtn}
        />
      </div>
    </form>
  );
};

export default CreatePrelanding;
