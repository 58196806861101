import React, {useEffect, useRef, useState} from "react";
import s from "./whitePage.module.scss";
import { Link } from "react-router-dom";
import PrimaryBtn from "../../../../../components/UiKit/Buttons/PrimaryBorderBtn/PrimaryBorderBtn";
import ReactSelectTheme from "../../../../../components/ReactSelectTheme/ReactSelectTheme";
import { useSelector } from "react-redux";
import { authSelector } from "../../../../../redux/slices/authSlice";

import {
  cloacaModeOptions,
  cloacaTypesOptions, snackbar_warning,
  whitePageTypeOptions,
  wordPressOptions,
} from "../../../../../utils/vars/staticVariables";
import SvgSelector from "../../../../../components/SvgSelector/SvgSelector";
import { globalDataSelector } from "../../../../../redux/slices/globalDataSlice";
import IpSelect from "../../IpSelect/IpSelect";
import { flowSettingsSelector } from "../../../../../redux/slices/flowSettingsSlice";
import FlowSettingsGeoSelect from "../../../../../components/Selects/FlowSettingsGeoSelect";
import { useTranslation } from "react-i18next";
import useCustomSnackbar from "../../../../../hooks/useCustomSnackbar";
import {is_staff} from "../../../../../storage/user";

const WhitePage = ({ formik }) => {
  const [geoListOptions, setGeoListOptions] = useState([]);
  const [ipSelectErrorMessage, setIpSelectErrorMessage] = useState("");
  const [isVisibleCloacaModeTooltip, setIsVisibleCloacaModeTooltip] =
    useState(false);

  const appStoreUrlValidationRef = useRef(null);
  const googlePlayUrlValidationRef = useRef(null);

  const { setWhitePageSettingsPending, fetchFlowByIdPending } =
    useSelector(flowSettingsSelector);
  const { isPartner } = useSelector(authSelector);
  const { geoList } = useSelector(globalDataSelector);

  const { t } = useTranslation();

  const showSnackbar = useCustomSnackbar();

  const tdsClickIdMessage = isPartner
    ? t("tds_clickid_partner_message")
    : t("tds_clickid_regular_message");

  useEffect(() => {
    let geoListArr = geoList.map((item) => ({
      label: item.name,
      value: item.id,
      icon: item.flag,
      code: item.code,
      // isDisabled: item.code === "US" || item.code === "AE",
    }));

    if (geoListArr.length > 0) {
      if (formik.values.geo.length !== geoListArr.length) {
        setGeoListOptions([
          { value: "all_geos", label: t("all_geos") },
          ...geoListArr,
        ]);
      } else {
        setGeoListOptions(geoListArr);
      }
    }
  }, [geoList, formik.values.geo]);

  useEffect(() => {

    if (formik.errors.appStoreUrl && formik.touched.appStoreUrl) {
      appStoreUrlValidationRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [formik.errors.appStoreUrl, formik.touched.appStoreUrl])

  useEffect(() => {

    if (formik.errors.googlePlayUrl && formik.touched.googlePlayUrl) {
      googlePlayUrlValidationRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [formik.errors.googlePlayUrl, formik.touched.googlePlayUrl])

  const isWhitePageRecreationLimit = !is_staff && formik.values.whitepage_recreation_count >= 1

  const whitePageLimitedUpdateWarning = () => showSnackbar(t('limit_exceeded_for_whitepage_update'), snackbar_warning)

  return (
    <form className={s.whitePageForm} onSubmit={formik.handleSubmit}>
      <div className={s.pageInnerContent}>
        <div className={s.fieldsWrapper}>
          <div className={s.fieldsColumn}>
            <div className={s.fieldColumn}>
              <div className={s.fieldWrapper}>
                <p className={s.fieldName}>{t("link_for_offer")}</p>
                <input
                  type="text"
                  name="url"
                  className={`${s.textInput} ${
                    formik.touched.url && formik.errors.url ? s.error : ""
                  }`}
                  placeholder={
                    fetchFlowByIdPending ? t("loading") : "https://example.com"
                  }
                  value={fetchFlowByIdPending ? "" : formik.values.url}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <p className={s.tipMessage}>
                  <span>{tdsClickIdMessage}</span>
                </p>
                {formik.touched.url && formik.errors.url ? (
                  <div className={s.error}>{formik.errors.url}</div>
                ) : null}
              </div>
              <div className={s.fieldWrapper}>
                <p className={s.fieldName}>
                  {t("name_column")}
                  <span>{t("required_input_tooltip")}</span>
                </p>
                <input
                  type="text"
                  name="notes"
                  className={`${s.textInput} ${
                    formik.touched.notes && formik.errors.notes ? s.error : ""
                  }`}
                  placeholder={
                    fetchFlowByIdPending ? t("loading") : t("flow_name")
                  }
                  value={fetchFlowByIdPending ? "" : formik.values.notes}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.notes && formik.errors.notes ? (
                  <div className={s.error}>{formik.errors.notes}</div>
                ) : null}
              </div>
            </div>

            <div>
              <p className={s.rowtitle}>
                <SvgSelector id="db-icon" /> {t("cloaca_settings")}
              </p>
              <div className={s.rowsWrapper}>
                <div className={s.fieldRow}>
                  <div className={s.fieldWrapper}>
                    <p className={s.fieldName}>{t("cloaca_type")}</p>
                    <ReactSelectTheme
                      darkTheme={true}
                      options={cloacaTypesOptions}
                      placeHolder={t("choose_option_placeholder")}
                      value={cloacaTypesOptions[0]}
                      action={() => {
                        return;
                      }}
                    />
                  </div>
                  <div className={s.fieldWrapper}>
                    {isVisibleCloacaModeTooltip && (
                      <div className={s.cloacaModeTooltipWrapper}>
                        <p>
                          <span>{t("filter_cloaca_mode")}</span> -{" "}
                          {t("on_cloaca_mode_desc")}
                        </p>
                        <p>
                          <span>{t("money_cloaca_mode")}</span> -{" "}
                          {t("off_cloaca_mode_desc")}
                        </p>
                        <p>
                          <span>{t("moderation_cloaca_mode")}</span> -{" "}
                          {t("moderation_cloaca_mode_desc")}
                        </p>
                      </div>
                    )}
                    <p className={s.fieldName}>
                      {t("cloaca_mode_field_name")}
                      <button
                        type="button"
                        onMouseEnter={() => setIsVisibleCloacaModeTooltip(true)}
                        onMouseLeave={() =>
                          setIsVisibleCloacaModeTooltip(false)
                        }
                        onClick={() =>
                          setIsVisibleCloacaModeTooltip((prev) => !prev)
                        }
                      >
                        <SvgSelector id="info-primary-circle-icon" />
                      </button>
                    </p>
                    <ReactSelectTheme
                      darkTheme={true}
                      options={cloacaModeOptions.map((option) => {
                        return {
                          ...option,
                          label: t(option.label),
                        };
                      })}
                      placeholder={
                        fetchFlowByIdPending
                          ? t("loading")
                          : t("choose_option_placeholder")
                      }
                      value={fetchFlowByIdPending ? null : formik.values.status}
                      action={(selectedOption) => {
                        formik.setFieldValue("status", selectedOption);
                      }}
                    />
                  </div>
                </div>
                <div className={s.fieldRow}>
                  <div className={s.fieldWrapper}>
                    <p className={s.fieldName}>
                      {t("geo")}
                      <span>{t("required_input_tooltip")}</span>
                    </p>

                    <FlowSettingsGeoSelect
                      darkTheme={true}
                      isClearable={true}
                      isMulti={true}
                      options={geoListOptions}
                      placeholder={
                        fetchFlowByIdPending ? t("loading") : t("choose_geo")
                      }
                      value={fetchFlowByIdPending ? null : formik.values.geo}
                      isInvalid={formik.touched.geo && formik.errors.geo}
                      action={(selectedOption) => {
                        if (
                          selectedOption.some((obj) => obj.value === "all_geos")
                        ) {
                          const allOptions = geoListOptions.slice(1);
                          formik.setFieldValue("geo", allOptions);
                          setGeoListOptions(allOptions);
                        } else {
                          if (
                            !geoListOptions.some(
                              (item) => item.value === "all_geos",
                            )
                          ) {
                            setGeoListOptions((prev) => [
                              { value: "all_geos", label: t("all_geos") },
                              ...prev,
                            ]);
                          }
                          formik.setFieldValue("geo", selectedOption);
                        }
                      }}
                    />
                    {formik.touched.geo && formik.errors.geo ? (
                      <div className={s.error}>{formik.errors.geo}</div>
                    ) : null}
                  </div>

                  <div className={s.fieldWrapper}>
                    <p className={s.fieldName}>{t("ip_white_list")}</p>
                    <IpSelect
                      setErrorMessage={setIpSelectErrorMessage}
                      placeholder={
                        fetchFlowByIdPending ? t("loading") : t("ip_address")
                      }
                      selectedOption={
                        fetchFlowByIdPending ? null : formik.values.allowed_ips
                      }
                      onChange={(value) => {
                        formik.setFieldValue("allowed_ips", value);
                      }}
                      onCreate={(ip) => {
                        const updatedIps = [
                          ...formik.values.allowed_ips,
                          { value: ip, label: ip },
                        ];

                        formik.setFieldValue("allowed_ips", updatedIps);
                      }}
                      isInvalid={ipSelectErrorMessage}
                    />
                    {ipSelectErrorMessage && (
                      <div className={s.error}>{ipSelectErrorMessage}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <p className={s.rowtitle}>
                <SvgSelector id="db-icon" /> {t("white_page_settings")}
              </p>
              {
                  !is_staff && <div className={s.disabledBlockDescription}>
                    <SvgSelector id='pin-icon'/>
                    {t('limited_whitepage_update')}
                  </div>
              }
              <div className={s.rowsWrapper}>
                <div className={s.fieldRow}>
                  <div className={s.fieldWrapper}>
                    <p className={s.fieldName}>{t("white_page_type")}</p>
                    <ReactSelectTheme
                      darkTheme={true}
                      options={whitePageTypeOptions}
                      placeholder={
                        fetchFlowByIdPending
                          ? t("loading")
                          : t("choose_option_placeholder")
                      }
                      isDisabled={isWhitePageRecreationLimit}

                      value={
                        fetchFlowByIdPending ? "" : formik.values.type_of_cloaca
                      }
                      onDisabledClick={whitePageLimitedUpdateWarning}

                      action={(option) => {
                        formik.setFieldValue("type_of_cloaca", option);
                      }}
                    />
                  </div>
                  <div className={s.fieldWrapper}>
                    <p className={s.fieldName}>
                      {t("url_to_application")}{" "}
                      {formik.values.type_of_cloaca.label} {t("for_white_page")}
                    </p>

                    {formik.values.type_of_cloaca.value === "ap" && (
                        <div
                            onClick={() => {
                              if (isWhitePageRecreationLimit) {
                                whitePageLimitedUpdateWarning()
                              }
                            }}
                        >
                      <input
                        type="text"
                        name="appStoreUrl"
                        className={`${s.textInput} ${
                            formik.touched.appStoreUrl && formik.errors.appStoreUrl ? s.error : ""
                        }`}
                        disabled={isWhitePageRecreationLimit}
                        placeholder={
                          fetchFlowByIdPending
                            ? t("loading")
                            : t("link_for_app_store")
                        }
                        value={
                          fetchFlowByIdPending ? "" : formik.values.appStoreUrl
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                        </div>
                    )}
                    {formik.touched.appStoreUrl &&
                    formik.errors.appStoreUrl ? (
                        <div className={s.error} ref={appStoreUrlValidationRef}>
                          {formik.errors.appStoreUrl}
                        </div>
                    ) : null}

                    {formik.values.type_of_cloaca.value === "gp" && (
                        <div
                            onClick={() => {
                              if (isWhitePageRecreationLimit) {
                                whitePageLimitedUpdateWarning()
                              }
                            }}
                        >
                      <input
                        type="text"
                        name="googlePlayUrl"
                        className={`${s.textInput} ${
                          formik.touched.googlePlayUrl &&
                          formik.errors.googlePlayUrl
                            ? s.error
                            : ""
                        }`}
                        disabled={isWhitePageRecreationLimit}
                        placeholder={
                          fetchFlowByIdPending
                            ? t("loading")
                            : t("link_for_google_play")
                        }
                        value={
                          fetchFlowByIdPending
                            ? ""
                            : formik.values.googlePlayUrl
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                        </div>
                    )}
                    {formik.touched.googlePlayUrl &&
                    formik.errors.googlePlayUrl ? (
                      <div className={s.error} ref={googlePlayUrlValidationRef}>
                        {formik.errors.googlePlayUrl}
                      </div>
                    ) : null}

                    {formik.values.type_of_cloaca.value === "wp" && (
                      <ReactSelectTheme
                        darkTheme={true}
                        options={wordPressOptions.map((option) => {
                          return {
                            ...option,
                            label: t(option.value),
                          };
                        })}
                        placeholder={
                          fetchFlowByIdPending
                            ? t("loading")
                            : t("choose_option_placeholder")
                        }
                        onDisabledClick={whitePageLimitedUpdateWarning}
                        isDisabled={isWhitePageRecreationLimit}
                        value={
                          fetchFlowByIdPending
                            ? ""
                            : formik.values.wordPressOption
                        }
                        action={(selectedOption) =>
                          formik.setFieldValue(
                            "wordPressOption",
                            selectedOption,
                          )
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={s.buttons}>
        <Link className={s.whiteBorderBtn} to="/my-flows">
          {t("button_cancel")}
        </Link>
        <PrimaryBtn
          text={t("button_save")}
          submit={true}
          pending={fetchFlowByIdPending || setWhitePageSettingsPending}
          className={s.customPrimaryBtn}
        />
      </div>
    </form>
  );
};

export default WhitePage;
