import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../../redux/slices/authSlice";
import {
  financeSelector,
  setBalance,
  setTopUpBalanceActive,
} from "../../../redux/slices/financeSlice";
import {
  globalDataSelector,
  setAdaptiveMenu,
  setExitPopup,
  setIsCollapsedSidebar,
} from "../../../redux/slices/globalDataSlice";
import React, { useEffect, useRef, useState } from "react";
import { useWindowWidth } from "../../../hooks/useWindowWidth";
import {
  checkAllTokens,
  pixelApiSelector,
} from "../../../redux/slices/pixelApiSlice";
import {
  decodeUserJwt,
  getImpersonateUserToken,
  getUser, is_allowed_statistics_access, is_superuser, is_tech_user,
  removeItem, TOKEN,
} from "../../../storage/user";
import s from "./sidebar.module.scss";
import SvgSelector from "../../SvgSelector/SvgSelector";
import AnimatedBalance from "../../UiKit/AnimatedBalance/AnimatedBalance";
import SidebarMuiTooltip from "../../UiKit/MuiTooltip/SidebarToolTip";
import {
  sidebarOptionsList,
} from "../../../utils/vars/staticVariables";
import MuiTooltip from "../../UiKit/MuiTooltip/MuiTooltip";
import GradientBorder from "../../GradientBorder/GradientBorder";
import useWebSocket from "react-use-websocket";
import {
  getFinanceWebSocket,
  wsOptions,
  wsSource,
} from "../../../api/WebSocket";
import { useTranslation } from "react-i18next";
import {fetchStandardClickPrice} from "../../../redux/slices/managementSlice";
import {setSidebarWidth} from "../../../storage/sidebarWidth";

const Sidebar = () => {
  const [userIconType, setUserIconType] = useState(null);

  const location = useLocation();
  const dispatch = useDispatch();
  const { isPartner } = useSelector(authSelector);
  const { balance } = useSelector(financeSelector);
  const { isCollapsedSidebar, exitPopup, isAdaptiveMenuActive } =
    useSelector(globalDataSelector);
  const { isValidPixelApiTokens } = useSelector(pixelApiSelector);

  const { lastMessage } = useWebSocket(getFinanceWebSocket, wsOptions);

  const sidebarRef = useRef(null);
  const sidebarContainerRef = useRef(null);
  const sidebarWrapperRef = useRef(null);

  const width = useWindowWidth();

  const { t } = useTranslation();

  useEffect(() => {
    if (isPartner !== null) {
      if (isPartner === true) {
        setUserIconType("partner-user-icon");
      } else if (is_allowed_statistics_access && !is_superuser) {
        setUserIconType("support-icon");
      } else if (is_superuser) {
        setUserIconType("superuser-icon");
      } else {
        setUserIconType("default-user-icon");
      }
    }
  }, [isPartner]);

  useEffect(() => {
    if (!lastMessage) return;

    const parsedMessage = JSON.parse(lastMessage.data);

    if (parsedMessage?.source === wsSource.balance) {
      dispatch(setBalance(parsedMessage?.data?.new_balance));
    }
  }, [dispatch, lastMessage]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sidebarWrapperRef.current &&
        !sidebarWrapperRef.current.contains(event.target) &&
        sidebarContainerRef.current &&
        sidebarContainerRef.current.contains(event.target)
      ) {
        dispatch(setAdaptiveMenu(false));
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch]);

  useEffect(() => {
    if (isAdaptiveMenuActive && width <= 1000) {
      document.body.style.overflow = "hidden";
      if (sidebarRef.current) {
        sidebarRef.current.focus();
      }
    } else {
      document.body.style.overflow = "";
    }
  }, [isAdaptiveMenuActive, width]);

  useEffect(() => {
    dispatch(checkAllTokens());
  }, [dispatch]);

  useEffect(() => {
    if (is_tech_user) {
      dispatch(fetchStandardClickPrice())
    }
  }, [dispatch]);

  const onSidebarWidthHandler = () => {
    const booleanValue = isCollapsedSidebar === "true";
    if (booleanValue) {
      dispatch(setIsCollapsedSidebar("false"));
      setSidebarWidth("false");
    } else {
      dispatch(setIsCollapsedSidebar("true"));
      setSidebarWidth("true");
    }
  };

  useEffect(() => {
    dispatch(setIsCollapsedSidebar(isCollapsedSidebar));
    setSidebarWidth(isCollapsedSidebar);
  }, [dispatch, isCollapsedSidebar]);

  const userData = getUser();
  const user = userData ? userData?.user : null;

  const fullScreen = `${s.sidebarWrapper} ${isCollapsedSidebar === "true" ? s.collapsed : ""}`;
  const adaptiveScreen = `${s.sidebarWrapper} ${isAdaptiveMenuActive ? s.activeMenu : s.closedMenu}`;

  const adminEmail = decodeUserJwt(localStorage.getItem("accessToken"))?.user?.email

  return (
    <div
      className={`${s.sidebarContainer} ${isAdaptiveMenuActive ? s.activeMenu : s.closedMenu}`}
      ref={sidebarContainerRef}
    >
      <div
        className={width > 1000 ? fullScreen : adaptiveScreen}
        ref={sidebarWrapperRef}
      >
        <div className={s.sidebarHeader}>
          <div className={s.info}>
            <div className={s.balanceInfoContainer}>
              <Link to="/account" className={s.userIconContainer}>
                <SvgSelector id={userIconType} />
              </Link>
              {!isPartner && typeof isPartner === "boolean" && (
                <button
                  className={s.balanceBtn}
                  onClick={() => {
                    dispatch(setAdaptiveMenu(false));
                    dispatch(setTopUpBalanceActive(true));
                  }}
                >
                  <AnimatedBalance end={balance} />
                  <span className={s.plus}>+</span>
                </button>
              )}
            </div>
            <div className={s.userInfoContainer}>
              <p className={s.email}>
                {user?.email}
                <MuiTooltip title={user?.email}></MuiTooltip>
              </p>
            </div>
          </div>
          <button
            className={`${s.changeWidthBtn} ${isCollapsedSidebar === "true" ? s.rotated : ""}`}
            onClick={onSidebarWidthHandler}
          >
            <SvgSelector id="openSidebarArrow" />
          </button>
        </div>
        <div className={s.gradientContainer}>
          <GradientBorder className={s.gradientBorderCustom} />
        </div>

        {getImpersonateUserToken() && (
          <div className={s.returnToAdminContainer}>
            <button
              className={s.returnToAdminButton}
              onClick={() => {
                removeItem("impersonateUserToken");
                window.location.href = "/accounts-management";
              }}
            >
              <div className={s.returnIconContainer}>
                <SvgSelector id="return-to-adimn-acc-icon" />
              </div>
              <div className={s.btnDesc}>
                <span className={s.desc}>{t('back_to_account')}</span>
                <span className={s.email}>
                  {adminEmail}
                </span>
              </div>
            </button>
          </div>
        )}

        <div className={s.sidebar} ref={sidebarRef} tabIndex="-1">
          {sidebarOptionsList.map(({ title, pages }, index) => (
            <div className={s.chapterWrapper} key={index}>
              <h3 className={s.chapterTitle}>{t(title)}</h3>
              <div className={s.menu}>
                {pages.map(({ path, name, iconId }, index) => {
                  const translationName = t(name);

                  return (
                    <div key={index} className={s.menuBtnWrapper}>
                      <Link
                        onClick={() => dispatch(setAdaptiveMenu(false))}
                        to={path}
                        className={`${s.link} ${
                          location.pathname.startsWith(path) ? s.active : ""
                        }`}
                      >
                        {isCollapsedSidebar === "true" ? (
                          <SidebarMuiTooltip title={translationName}>
                            <SvgSelector id={iconId} />
                          </SidebarMuiTooltip>
                        ) : (
                          <SvgSelector id={iconId} />
                        )}

                        <span className={s.pageTitle}>{translationName}</span>

                        {path === "/pixel-api" && !isValidPixelApiTokens && (
                          <span className={s.alertContainer}>
                            <SvgSelector id="alert-circle-icon" />
                          </span>
                        )}
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}

          <div className={s.aLinks}>
            <a
              href="https://t.me/TL_supp_wwa_vados"
              target="_blank"
              className={s.menuBtnWrapper}
              rel="noreferrer"
            >
              <div className={s.link}>
                {isCollapsedSidebar === "true" ? (
                  <SidebarMuiTooltip title={t("pushNotifications")}>
                    <SvgSelector id="push" />
                  </SidebarMuiTooltip>
                ) : (
                  <SvgSelector id="push" />
                )}
                <span className={s.pageTitle}>{t("pushNotifications")}</span>
              </div>
            </a>

            <a
              href="https://t.me/yaroslava_support_wwa"
              target="_blank"
              className={s.menuBtnWrapper}
              rel="noreferrer"
            >
              <div className={s.link}>
                {isCollapsedSidebar === "true" ? (
                  <SidebarMuiTooltip title={t("support")}>
                    <SvgSelector id="support" />
                  </SidebarMuiTooltip>
                ) : (
                  <SvgSelector id="support" />
                )}
                <span className={s.pageTitle}>{t("support")}</span>
              </div>
            </a>

            <button
              className={s.menuBtnWrapper}
              onClick={() => {
                dispatch(setTopUpBalanceActive(false));
                dispatch(setExitPopup(!exitPopup));
                dispatch(setAdaptiveMenu(false));
              }}
            >
              <div className={s.link}>
                {isCollapsedSidebar === "true" ? (
                  <SidebarMuiTooltip title={t("exit")}>
                    <SvgSelector id="logout-icon" />
                  </SidebarMuiTooltip>
                ) : (
                  <SvgSelector id="logout-icon" />
                )}
                <span className={s.pageTitle} style={{ color: "#D02533" }}>
                  {t("logout")}
                </span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
