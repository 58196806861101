import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {financeSelector} from "../../../../redux/slices/financeSlice";
import {globalDataSelector} from "../../../../redux/slices/globalDataSlice";
import SvgSelector from "../../../SvgSelector/SvgSelector";
import PrimaryBtn from "../../Buttons/PrimaryBorderBtn/PrimaryBorderBtn";
import CancelButton from "../../Buttons/CancelBtn/CancelBtn";
import s from "./newConfirmPopup.module.scss";
import {useTranslation} from "react-i18next";

const NewConfirmPopup = ({
                             active,
                             setActive,
                             action,
                             description,
                             subDescription,
                             title,
                             confirmBtnText,
                             forcedClose = true,
                             icon,

                         }) => {
    const {topUpBalanceIsActive} = useSelector(financeSelector);
    const {exitPopup, isCollapsedSidebar} = useSelector(globalDataSelector);

    const {t} = useTranslation();

    const handleClosePopup = (event) => {
        if (event.target.classList.contains(s.popupWrapper)) {
            setActive(false);
        }
    };

    useEffect(() => {
        if ((forcedClose && topUpBalanceIsActive) || (forcedClose && exitPopup)) {
            setActive(false);
        }
    }, [topUpBalanceIsActive, exitPopup]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setActive(false);
        action();
    };

    useEffect(() => {
        const handleEscClose = (e) => {
            if (e.key === "Escape") {
                setActive(false);
                if (document.activeElement) {
                    document.activeElement.blur();
                }
            }
        };
        const handleEnterPress = (e) => {
            if (e.key === "Enter") {
                if (document.activeElement) {
                    document.activeElement.blur();
                }
                handleSubmit(e);
            }
        };
        if (active) {
            document.addEventListener("keydown", handleEscClose);
            document.addEventListener("keydown", handleEnterPress);
        }

        return () => {
            document.removeEventListener("keydown", handleEscClose);
            document.removeEventListener("keydown", handleEnterPress);
        };
    }, [active]);

    return (
        <form
            onSubmit={handleSubmit}
            className={`${s.popupWrapper} ${active ? s.active : ""} ${isCollapsedSidebar === "true" ? s.collapsed : ""}`}
            onClick={handleClosePopup}
        >
            <div className={active ? `${s.popup} ${s.active}` : s.popup}>
                <button
                    onClick={() => setActive(false)}
                    className={s.closePopup}
                    type="button"
                >
                    <SvgSelector id="close-popup"/>
                </button>
                <div className={s.bodyContainer}>
                    <span className={s.iconWrapper}>{icon}</span>
                    <p className={s.title}>{title}</p>
                    <p className={s.description}>{description}</p>
                    {
                        subDescription && <p className={s.subDescription}>{subDescription}</p>
                    }
                </div>
                <div className={s.footer}>
                    <CancelButton
                        text={t("button_cancel")}
                        action={() => setActive(false)}
                        width={120}
                    />
                    <PrimaryBtn text={confirmBtnText} submit={true}/>
                </div>
            </div>
        </form>
    );
};

export default NewConfirmPopup;
