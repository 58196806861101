import { jwtDecode } from "jwt-decode";


const getAccessToken = localStorage.getItem("accessToken")

const defineAccessToken = () => {
  const impersonateToken = getImpersonateUserToken();

  if (
    decodeUserJwt()?.user?.is_staff ||
    decodeUserJwt()?.user?.allow_access_to_statistics
  ) {
    if (impersonateToken) {
      return impersonateToken;
    } else {
      return getAccessToken
    }
  } else {
    localStorage.removeItem("impersonateUserToken");
    return getAccessToken
  }
};


export const getImpersonateUserToken = () => {
  return localStorage.getItem("impersonateUserToken");
};

export const getRefreshToken = () => {
  return localStorage.getItem("refreshToken");
};

export const decodeUserJwt = (inputToken) => {
  let token

  if (inputToken) {
    token = inputToken
  } else {
    token = getAccessToken
  }

  if (typeof token === "string") {
    return jwtDecode(token);
  }
  return null;
};

export const getUser = () => {
  const token = defineAccessToken();

  if (typeof token === "string") {
    return jwtDecode(token);
  }
  return null;
};

export const removeItem = (key) => {
  localStorage.removeItem(key);
};

export const removeTokens = () => {
  removeItem("accessToken");
  removeItem("refreshToken");
  removeItem("impersonateUserToken");
};

export const is_staff = getUser()?.user?.is_staff

export const is_superuser = getUser()?.user?.is_superuser

export const is_allowed_statistics_access = getUser()?.user?.allow_access_to_statistics

export const is_tech_user = getUser()?.user?.is_staff || getUser()?.user?.allow_access_to_statistics


const getIsNotified = () => {
  return localStorage.getItem("is_notified");
};
export const setIsNotified = (value) => {
  localStorage.setItem("is_notified", value);
};
export const isNotified = getIsNotified();

export const TOKEN = defineAccessToken()

export const isUserAuthenticated = !!localStorage.getItem("accessToken");