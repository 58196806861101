import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import MuiTooltip from "../../MuiTooltip/MuiTooltip";
import SvgSelector from "../../../SvgSelector/SvgSelector";
import {
    archiveAllNotifications,
    archiveNotification,
    fetchNotificationsList,
    readAllNotifications,
    readNotification,
    setHasUnreadNotifications,
    setIsNotificationPopupActive,
    setNotificationsList,
    globalDataSelector,
} from "../../../../redux/slices/globalDataSlice";
import {formatDateTime} from "../../../../utils/helpers/formatTime";
import {updateFieldById} from "../../../../utils/helpers/arrayHelpers";
import s from './notificationsPopup.module.scss'

const NotificationsPopup = ({bellButtonRef}) => {
    const popupRef = useRef(null);
    const popupBodyRef = useRef(null);
    const [currentPage, setCurrentPage] = useState(1);
    const dispatch = useDispatch();
    const {
        isNotificationPopupActive,
        isCollapsedSidebar,
        fetchNotificationsPending,
        notificationsList,
        notificationsTotalCount,
        isFetchingMoreNotifications,
        readAllNotificationsPending,
        archiveAllNotificationsPending
    } = useSelector(globalDataSelector);
    const {t} = useTranslation();

    useEffect(() => {
        dispatch(fetchNotificationsList({page: 1}));
    }, [dispatch]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                popupRef.current &&
                !popupRef.current.contains(event.target) &&
                bellButtonRef.current &&
                !bellButtonRef.current.contains(event.target)
            ) {
                dispatch(setIsNotificationPopupActive(false));
            }
        };

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [dispatch]);

    const handleScroll = useCallback(() => {
        const element = popupBodyRef.current;
        if (element && element.scrollTop + element.clientHeight >= element.scrollHeight - 10 && !isFetchingMoreNotifications) {

            const totalPages = Math.ceil(notificationsTotalCount / 50);
            if (currentPage < totalPages) {
                const nextPage = currentPage + 1;
                setCurrentPage(nextPage);
                dispatch(fetchNotificationsList({page: nextPage}));
            }
        }
    }, [currentPage, notificationsTotalCount, dispatch, isFetchingMoreNotifications]);

    useEffect(() => {
        const element = popupBodyRef.current;
        if (element) {
            element.addEventListener('scroll', handleScroll);
            return () => {
                element.removeEventListener('scroll', handleScroll);
            };
        }
    }, [handleScroll]);

    let processingQueue = [];
    let isProcessing = false;

    const processQueue = async (dispatch, getState) => {
        if (isProcessing || processingQueue.length === 0) return;
        isProcessing = true;

        while (processingQueue.length > 0) {
            const { id, resolve } = processingQueue.shift();

            try {
                const { status } = await dispatch(readNotification(id)).unwrap();
                if (status === 200) {
                    const currentList = getState().globalData.notificationsList;
                    const updatedArray = updateFieldById(currentList, id, "is_read", true);
                    dispatch(setNotificationsList(updatedArray));
                }
                resolve();
            } catch (error) {
            }
        }

        isProcessing = false;
    };

    const readNotificationHandler = (id, is_read) => (dispatch, getState) => {
        if (is_read) return;

        return new Promise((resolve, reject) => {
            processingQueue.push({ id, resolve, reject });
            processQueue(dispatch, getState);
        });
    };

    const readAllNotificationsHandler = async () => {

        const {status} = await dispatch(readAllNotifications()).unwrap()

        if (status === 200) {
            const updatedArray = notificationsList.map(notification => ({
                ...notification,
                is_read: true
            }));
            dispatch(setNotificationsList(updatedArray))
            dispatch(setHasUnreadNotifications(false))
        }
    }

    const archiveAllNotificationsHandler = async () => {
        const {status} = await dispatch(archiveAllNotifications()).unwrap()

        if (status === 200) {
            dispatch(setNotificationsList([]))
            dispatch(setHasUnreadNotifications(false))
        }
    }

    const archiveNotificationHandler = async (id) => {
        const {status} = await dispatch(archiveNotification(id)).unwrap()

        if (status === 200) {
            const updatedArray = notificationsList.filter(notification => notification.id !== id);
            dispatch(setNotificationsList(updatedArray))
        }
    }

    return (
        <div
            className={`
                    ${s.notificationsPopup}
                    ${isNotificationPopupActive ? s.active : ""}
                    ${isCollapsedSidebar === "true" ? s.collapsed : ""}
                `}
            ref={popupRef}
        >
            <div className={s.popupHeader}>
                <div className={s.iconWrapper}>
                    <SvgSelector id='notifications-clock-icon'/>
                </div>
                <div className={s.popupTitle}>{t('your_notifications')}</div>
                <button className={s.closePopupBtn} onClick={() => dispatch(setIsNotificationPopupActive(false))}>
                    <SvgSelector id="close-popup"/>
                </button>
            </div>
            <div className={s.popupBody} ref={popupBodyRef}>
                {
                    !fetchNotificationsPending && notificationsList.length === 0 &&
                    <div className={s.notificationsMessage}>{t('no_notifications')}</div>
                }
                {
                    fetchNotificationsPending && <div className={s.notificationsMessage}>{t('loading')}</div>
                }
                {
                    notificationsList.map(({created_at, id, is_read, title}) => {
                        const {date, time} = formatDateTime(created_at);

                        return (
                            <div
                                className={s.notificationItem}
                                key={id}
                                style={is_read ? {opacity: '0.6'} : null}
                                onMouseEnter={() => dispatch(readNotificationHandler(id, is_read))}
                            >
                                {/*<div className={s.icon}>*/}
                                {/*    <SvgSelector id='blocked-app-notification'/>*/}
                                {/*</div>*/}
                                <div className={s.notificationContent}>
                                    <p className={s.notificationText}>{title}</p>
                                    <div className={s.timeWrapper}>
                                        <span className={s.date}>{date}</span>
                                        <span className={s.time}>{time}</span>
                                        <button
                                            className={s.deleteBtn}
                                            onClick={() => archiveNotificationHandler(id)}
                                            disabled={archiveAllNotificationsPending}
                                        >
                                            <MuiTooltip title={t('button_remove')}>
                                                <SvgSelector id='new-remove-icon'/>
                                            </MuiTooltip>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                {
                    isFetchingMoreNotifications && <div className={s.notificationsMessage}>{t('loading')}</div>
                }
            </div>
            <div className={s.popupFooter}>
                <button
                    className={s.readNotificationsBtn}
                    onClick={readAllNotificationsHandler}
                    disabled={readAllNotificationsPending || fetchNotificationsPending}
                >
                    <SvgSelector id="eye-read-notifications-icon"/>
                    <span>{t('read_all')}</span>
                </button>
                <button
                    className={s.cleanNotificationsBtn}
                    onClick={archiveAllNotificationsHandler}
                    disabled={archiveAllNotificationsPending || fetchNotificationsPending}
                >
                    <SvgSelector id="close-popup"/>
                    <span>{t('clear_all')}</span>
                </button>
            </div>
        </div>
    )
}

export default NotificationsPopup;