import { useSelector } from "react-redux";
import { financeSelector } from "../../../../redux/slices/financeSlice";
import EmptyList from "../../../../components/EmptyList/EmptyList";
import s from "../transactions.module.scss";
import CustomSkeleton from "../../../../components/UiKit/CustomSkeleton/CustomSkeleton";
import { convertDate } from "../../../../utils/helpers/formatTime";
import { Link } from "react-router-dom";
import MuiTooltip from "../../../../components/UiKit/MuiTooltip/MuiTooltip";
import SvgSelector from "../../../../components/SvgSelector/SvgSelector";
import React from "react";
import { useTranslation } from "react-i18next";

const WriteOffOption = () => {
  const {
    transactionsLoading,
    transactionsList,
    totalCount,
    transactionsGlobalTotal,
  } = useSelector(financeSelector);

  const { t } = useTranslation();

  return (
    <>
      {totalCount === 0 && !transactionsLoading ? (
        <EmptyList />
      ) : transactionsLoading ? (
        <section className={s.body}>
          <table>
            <thead>
              <tr>
                <th>{t("date")}</th>
                <th>{t("installs")}</th>
                <th>{t("ammount")}</th>
                <th>{t("sevice")}</th>
                <th>{t("report")}</th>
              </tr>
            </thead>
          </table>
          <div className={s.skeletonWrapper}>
            <CustomSkeleton styles={s.skeletonTable} />
          </div>
        </section>
      ) : (
        <section className={s.body}>
          <table>
            <thead>
              <tr>
                <th>{t("date")}</th>
                <th>{t("installs")}</th>
                <th>{t("ammount")}</th>
                <th>{t("sevice")}</th>
                <th>{t("report")}</th>
              </tr>
            </thead>
            <tbody>
              {transactionsList.map(
                ({
                  id,
                  created_at,
                  amount,
                  description,
                  additional_info_installs,
                }) => (
                  <tr key={id}>
                    <td className={s.dateTd}>
                      <span>{convertDate(created_at, "DMY")}</span>{" "}
                      <span>{convertDate(created_at, "HM")}</span>
                    </td>
                    <td>
                      {additional_info_installs ? additional_info_installs : ""}
                    </td>
                    <td>$ {amount}</td>
                    <td>{description}</td>

                    <td>
                      <div className={s.actionsWrapper}>
                        {description.includes("Списание средств за домен") ? (
                          ""
                        ) : (
                          <div className={s.actionsContainer}>
                            <div className={s.actions}>
                              <Link
                                to={`/stats/clicks?created_at_after=${convertDate(created_at, "YMD")}&created_at_before=${convertDate(created_at, "YMD")}`}
                                className={`${s.manageTableFillBtn} ${s.statsBtn}`}
                              >
                                <MuiTooltip
                                  title={t("go_to_clicks_stat_option")}
                                >
                                  <SvgSelector id="new-stats-icon" />
                                </MuiTooltip>
                              </Link>
                            </div>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ),
              )}
            </tbody>
            <tfoot>
              <tr>
                <th>{t("total")}</th>
                <th></th>
                <th>$ {transactionsGlobalTotal}</th>
                <th></th>
                <th></th>
              </tr>
            </tfoot>
          </table>
        </section>
      )}
    </>
  );
};

export default WriteOffOption;
