import React, { useState } from "react";
import s from "./createPrelanding.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  globalDataSelector,
  setAdaptiveMenu,
} from "../../../../redux/slices/globalDataSlice";
import { setTopUpBalanceActive } from "../../../../redux/slices/financeSlice";
import SvgSelector from "../../../../components/SvgSelector/SvgSelector";
import { Link, useNavigate } from "react-router-dom";
import GradientBorder from "../../../../components/GradientBorder/GradientBorder";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  prelandingStatusList,
  prelandingTypesList,
  snackbar_error,
  snackbar_success,
} from "../../../../utils/vars/staticVariables";
import {
  createPrelanding,
  prelandingSelector,
} from "../../../../redux/slices/prelandingSlice";
import useCustomSnackbar from "../../../../hooks/useCustomSnackbar";
import Create from "./Create/Create";
import Upload from "./Upload/Upload";
import {
  noIndexHtml,
  noIndexHtmlFormikError, noLinkInIndexHtml,
  prelandingFileCyrillicError,
} from "../../../../utils/vars/errorMessages";
import { useTranslation } from "react-i18next";
import {
  failed_to_upload_prelanding,
  file_cyrillic_error,
  not_found_index_html,
} from "../../../../utils/vars/notificationsMessages";
import LanguageDropdown from "../../../../components/LanguageDropdown/LanguageDropdown";
import {managementSelector} from "../../../../redux/slices/managementSlice";
import {is_tech_user} from "../../../../storage/user";
import Header from "../../../../components/Partials/Header/Header";

const CreatePrelanding = () => {
  const [pageContent, setPageContent] = useState("create");

  const { isCollapsedSidebar, isAdaptiveMenuActive } =
    useSelector(globalDataSelector);
  const { wheelStylesList } = useSelector(prelandingSelector);
  const { standardClickPriceValue } = useSelector(managementSelector);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const showSnackbar = useCustomSnackbar();

  const { t } = useTranslation();

  const createPrelandFormik = useFormik({
    initialValues: {
      status: prelandingStatusList[0].value, // Статус
      name: "", // Назва преленда
      num_of_revolutions: 1, // Кількість обертів, ми не використовуємо це значення
      prelanding_type: prelandingTypesList[0].value, // Тип (колесо)
      header_text: "Welcome", // Заголовок преленда
      rotation_button_text: "Spin", // Текст кнопки першого обертання
      respin_button_text: "Spin", // Текст кнопки повторного обертання
      style: wheelStylesList[0]?.id, // ID стиля колеса
      sectors: new Array(8).fill().map((_, index) => ({
        // Значення секторів
        name: `Сектор ${index}`,
        value: "",
        is_win: index <= 0,
      })),
      bonus_button_text: "ОК", // Заголовок у попапі виграшу
      popup_win_text: "You get {bonus}", // Текст у попапі виграшу
      winning_button_text: "Get", // Текст кнопки у попапі виграшу
      logo: null, // Логотип, у нас його немає
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required(t("requiredField")),
      // header_text: Yup.string().required("Обязательное поле"),
      rotation_button_text: Yup.string().required(t("requiredField")),
      respin_button_text: Yup.string().required(t("requiredField")),
      sectors: Yup.array().of(
        Yup.object().shape({
          value: Yup.string().required(t("fill_sector_value")),
        }),
      ),
      bonus_button_text: Yup.string().required(t("requiredField")),
      winning_button_text: Yup.string().required(t("requiredField")),
      style: Yup.string().required(t("requiredField")),
    }),
    onSubmit: async (data, { setFieldError, validateField, setFieldValue }) => {
      const createPrelandingResult = await dispatch(createPrelanding(data));
      if (createPrelandingResult?.payload?.status === 201) {
        navigate("/prelandings");
        showSnackbar(t("prelanding_created"), snackbar_success);
      } else {
        showSnackbar(t("failed_to_create_prelanding"), snackbar_error);
      }
    },
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
  });

  const uploadPrelandFormik = useFormik({
    initialValues: {
      prelanding_file: null,
      prelanding_type: 4,
      name: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required(t("requiredField")),
      prelanding_file: Yup.string().required(t("upload_zip_archive")),
    }),
    onSubmit: async (data, { setFieldError, validateField, setFieldValue }) => {
      const formData = new FormData();
      formData.append("prelanding_file", data.prelanding_file);
      formData.append("prelanding_type", data.prelanding_type);
      formData.append("name", data.name);

      const createPrelandingResult = await dispatch(createPrelanding(formData));

      if (createPrelandingResult?.payload?.status === 201) {
        navigate("/prelandings");
        showSnackbar(
          `${t("prelanding") + " " + t("was_uploaded")}`,
          snackbar_success,
        );
      } else if (
        createPrelandingResult?.payload?.data?.detail === noIndexHtml
      ) {
        return setFieldError("prelanding_file", t(not_found_index_html));
      } else if (
          createPrelandingResult?.payload?.data?.detail === noLinkInIndexHtml
      ) {
        return setFieldError("prelanding_file", t('no_link_in_index_html'));
      } else if (
        createPrelandingResult?.payload?.data?.detail ===
        prelandingFileCyrillicError
      ) {
        return setFieldError("prelanding_file", t(file_cyrillic_error));
      } else {
        showSnackbar(t(failed_to_upload_prelanding), snackbar_error);
      }
    },
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
  });

  return (
    <div
      className={`${s.createPrelandingWrapper} ${
        isCollapsedSidebar === "true" ? s.collapsedSidebar : ""
      }`}
    >
      <Header />
      <div className={s.pageContent}>
        <div className={s.pageMarking}>
          <Link to="/prelandings" className={s.link}>
            {t("prelandings")}
          </Link>
          <SvgSelector id="arrow-right" />
          <span>{t("creating")}</span>
        </div>
        <div className={s.contentButtonsWrapper}>
          <button
            onClick={() => setPageContent("create")}
            className={`${s.contentBtn} ${pageContent === "create" ? s.active : ""}`}
          >
            {t("create")}
          </button>
          <button
            onClick={() => setPageContent("upload")}
            className={`${s.contentBtn} ${pageContent === "upload" ? s.active : ""}`}
          >
            {t("upload")}
          </button>
        </div>
        <GradientBorder className={s.gradientBorderCustom} />
        {pageContent === "create" && <Create formik={createPrelandFormik} />}
        {pageContent === "upload" && <Upload formik={uploadPrelandFormik} />}
      </div>
    </div>
  );
};

export default CreatePrelanding;
