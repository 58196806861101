import {CSV, XLSX} from "../vars/staticVariables";

export const downloadTableFromBinaryData = (data, name, format = XLSX, statsDateRange) => {
  const mimeType =
      format === CSV
          ? "text/csv"
          : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  const fileExtension = format === CSV ? CSV : XLSX;

  const blob = new Blob([data], { type: mimeType });
  const url = window.URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${name}.${statsDateRange}.${fileExtension}`
  );
  document.body.appendChild(link);
  link.click();

  link.parentNode.removeChild(link);
  window.URL.revokeObjectURL(url);
};
