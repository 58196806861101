import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useSearchParams} from "react-router-dom";
import Pagination from "../../../components/Pagination/Pagination";
import s from "./flows.module.scss";

import {
    fetchExpiredDomainsList,
    fetchFlowsList,
    flowSelector,
} from "../../../redux/slices/flowSlice";
import SvgSelector from "../../../components/SvgSelector/SvgSelector";
import {applicationSelector} from "../../../redux/slices/applicationSlice";
import {
    globalDataSelector,
} from "../../../redux/slices/globalDataSlice";
import useGetSearchParams from "../../../hooks/useGetSearchParams";
import {
    useSearchParamsOptions,
    useSelectOptions,
} from "../../../utils/helpers/useSelectHelpers";
import {useDebounce} from "../../../hooks/useDebounce";
import {
    application_name,
    domain_address,
    geo,
    page,
    search,
    sub7,
} from "../Statistics/ClicksStats/ClicksStats";
import useSetSearchParams from "../../../hooks/useSetSearchParams";
import {fetchFlowFilters} from "../../../api/helpers/fetchFilters";
import {getTableDefaultParams} from "../../../api/helpers/scripts";
import {
    ACTIVE_FLOWS, EXPIRED_FLOWS,
    INVALID_PAGE_ERROR,
} from "../../../utils/vars/staticVariables";
import {params} from "../../../utils/vars/params";
import {cleanSearchParams, hasNonEmptyParams} from "../../../utils/helpers/cleanFilters";
import FilterApplicationSelect from "../../../components/Selects/FilterApplicationSelect";
import {cleanDomain} from "../../../utils/helpers/cleanDomain";
import GeoSelect from "../../../components/Selects/GeoSelect";
import {is_tech_user} from "../../../storage/user";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import Active from "./Tabs/Active";
import Expired from "./Tabs/Expired";
import {DOMAINS_REQUEST, REDIRECTORS_REQUEST} from "../../../api/helpers/getFilterRequest";
import {CustomAsyncSelect} from "../../../components/Selects/CustomAsyncSelect";
import Header from "../../../components/Partials/Header/Header";


export const flowsTabs = [ACTIVE_FLOWS, EXPIRED_FLOWS]

const Flows = () => {
    const underlineRef = useRef(null);
    const [selectedGeos, setSelectedGeos] = useState([]);

    const dispatch = useDispatch();

    const {
        asyncDomainsList,
        asyncDomainsPending,
        asyncRedirectorsList,
        asyncRedirectorsPending,
        geoList,
        isCollapsedSidebar,
    } = useSelector(globalDataSelector);

    const {appList} = useSelector(applicationSelector);

    const {flowsListPending, totalCount} =
        useSelector(flowSelector);

    const {t} = useTranslation();

    const [searchParams, setSearchParams] = useSearchParams();

    const {search: urlSearchValue, page: pageValue} = useGetSearchParams();

    const updateSearchParams = useSetSearchParams(setSearchParams);

    const [selectedDomains, selectedSub7, selectedApplications] =
        useSearchParamsOptions(searchParams, [
            domain_address,
            sub7,
            application_name,
        ]);

    const domainsListOptions = useSelectOptions(
        asyncDomainsList,
        "address",
        "address",
    );

    const sub7ListOptions = useSelectOptions(asyncRedirectorsList, "sub7", "sub7");


    const currentTab = (() => {
        const contentParam = searchParams.get("content");

        if (flowsTabs.find(item => item === contentParam)) {
            return contentParam;
        } else {
            return flowsTabs[0];
        }
    })();

    const geoListOptions = useMemo(() => {
        return geoList.map((item) => ({
            label: item.name,
            value: item.code,
            icon: item.flag,
        }));
    }, [geoList]);

    useEffect(() => {
        const domainParam = searchParams.get("domain_address");

        if (domainParam) {
            const domains = domainParam.split(",");
            const cleanedDomains = domains.map((domain) =>
                cleanDomain(decodeURIComponent(domain)),
            );

            // Якщо очищені домени не збігаються з оригінальними, оновлюємо параметри
            if (cleanedDomains.join(",") !== domains.join(",")) {
                updateSearchParams("domain_address", cleanedDomains.join(","));
            }
        }
    }, [searchParams, updateSearchParams]);

    useEffect(() => {
        const newGeoListOptions = searchParams
            .get(geo)
            ?.split(",")
            .filter((value) => value !== "")
            .map((value) => {
                return geoListOptions.find((item) => item.value === value);
            });

        setSelectedGeos(newGeoListOptions);
    }, [searchParams, geoListOptions]);

    const memorizedGeosList = useMemo(() => {
        return geoList;
    }, [geoList]);

    const appListOptions = useMemo(() => {
        return appList.map((item) => ({
            label: item.name,
            value: item.name,
            is_alive: is_tech_user ? item.is_alive : true,
            name: "app_list",
            icon: item.icon,
        }));
    }, [appList]);

    const [inputSearchValue, setInputSearchValue] = useDebounce((value) => {
        if (value) {
            updateSearchParams(search, value);
        } else {
            updateSearchParams(search, value, true);
        }
    }, 600);

    useEffect(() => {
        fetchFlowFilters(dispatch);
    }, [dispatch]);

    useEffect(() => {
        if (!urlSearchValue) {
            setInputSearchValue("");
        } else {
            setInputSearchValue(urlSearchValue);
        }
    }, [searchParams]);

    const fetchActiveFlows = useCallback(() => {
        const promise = dispatch(
            fetchFlowsList(
                getTableDefaultParams({
                    searchParams,
                    defaultPageSize: 25,
                }),
            ),
        );

        promise.unwrap().catch((err) => {
            if (err?.message === INVALID_PAGE_ERROR) {
                updateSearchParams(page, 1);
            }
        });

        return promise;
    }, [dispatch, searchParams, updateSearchParams]);


    const fetchExpiredFlows = useCallback(() => {
        const promise = dispatch(
            fetchExpiredDomainsList(
                getTableDefaultParams({
                    searchParams,
                    defaultPageSize: 25,
                }),
            ),
        );

        promise.unwrap().catch((err) => {
            if (err?.message === INVALID_PAGE_ERROR) {
                updateSearchParams(page, 1);
            }
        });

        return promise;
    }, [dispatch, searchParams, updateSearchParams]);

    const updateUnderline = () => {
        const activeBtn = document.querySelector(`.${CSS.escape(s.active)}`);
        if (activeBtn && underlineRef.current) {
            const {left, width} = activeBtn.getBoundingClientRect();
            const parentLeft = activeBtn.parentElement.getBoundingClientRect().left;
            underlineRef.current.style.width = `${width}px`;
            underlineRef.current.style.transform = `translateX(${left - parentLeft}px)`;
        }
    };

    useEffect(() => {
        updateUnderline();
        window.addEventListener("resize", updateUnderline);

        return () => {
            window.removeEventListener("resize", updateUnderline);
        };
    }, [currentTab, i18n.language]);


    return (
        <div
            className={`${s.flowsWrapper} ${isCollapsedSidebar === "true" ? s.collapsedSidebar : ""}`}
        >
            <Header
                searchValue={inputSearchValue}
                setSearchValue={setInputSearchValue}
                isSearch
                isLink
                linkPath={"/my-flows/create-flow"}
                buttonText={t("create_flow")}
                isCreateButton
            />

            <div className={s.pageContent}>
                <div className={s.switchPageContentContainer}>
                    <button
                        className={`${s.pageBtn} ${
                            currentTab === ACTIVE_FLOWS ? s.active : ""
                        }`}
                        onClick={() =>
                            updateSearchParams("content", ACTIVE_FLOWS)
                        }
                    >
                        <SvgSelector id="active-domains-tab"/>
                        {t("active_domains")}
                    </button>

                    <button
                        className={`${s.pageBtn} ${
                            currentTab === EXPIRED_FLOWS ? s.active : ""

                        }`}
                        onClick={() =>
                            updateSearchParams("content", EXPIRED_FLOWS)
                        }
                    >
                        <SvgSelector id="expired-domains-tab"/>
                        {t("expired_domains")}
                    </button>
                    <div className={s.underline} ref={underlineRef}></div>
                </div>

                {
                    currentTab === EXPIRED_FLOWS && <div className={s.tabDescription}>
                        <SvgSelector id='pin-icon'/>
                        {t('expired_domains_tab_description')}
                    </div>
                }

                <div className={s.filtersWrapper}>
                    <div className={s.filters}>
                        <div className={s.selectWrapper}>
                            <div className={s.selectIconWrapper}>
                                <SvgSelector id="domain-select-icon"/>
                            </div>
                            <CustomAsyncSelect
                                extractDomain={true}
                                value={selectedDomains}
                                options={domainsListOptions}
                                setOptions={(values) =>
                                    updateSearchParams(
                                        domain_address,
                                        values.map((el) => el.value).join(","),
                                    )
                                }
                                placeholder={t("domain_placeholder")}
                                requestName={DOMAINS_REQUEST}
                                pageSize={50}
                                pending={asyncDomainsPending}
                            />
                        </div>

                        <div className={s.selectWrapper}>
                            <div className={s.selectIconWrapper}>
                                <SvgSelector id="app-select-icon"/>
                            </div>
                            <FilterApplicationSelect
                                isClearable={true}
                                value={selectedApplications}
                                options={appListOptions}
                                onChange={(values) =>
                                    updateSearchParams(
                                        application_name,
                                        values.map((el) => el.value).join(","),
                                    )
                                }
                                placeholder={t("application_placeholder")}
                                isMulti={true}
                            />
                        </div>

                        <div className={s.selectWrapper}>
                            <div className={s.selectIconWrapper}>
                                <SvgSelector id="geo-select-icon"/>
                            </div>
                            <GeoSelect
                                isClearable={true}
                                value={selectedGeos}
                                options={geoListOptions}
                                onChange={(values) =>
                                    updateSearchParams(
                                        geo,
                                        values.map((el) => el.value).join(","),
                                    )
                                }
                                placeholder={t("geo_placeholder")}
                                isMulti={true}
                            />
                        </div>

                        <div className={s.selectWrapper}>
                            <div className={s.selectIconWrapper}>
                                <SvgSelector id="sub7-select-icon"/>
                            </div>
                            <CustomAsyncSelect
                                value={selectedSub7}
                                options={sub7ListOptions}
                                setOptions={(values) =>
                                    updateSearchParams(
                                        sub7,
                                        values.map((el) => el.value).join(","),
                                    )
                                }
                                placeholder="Sub7"
                                requestName={REDIRECTORS_REQUEST}
                                pageSize={50}
                                pending={asyncRedirectorsPending}
                            />
                        </div>

                        {hasNonEmptyParams(searchParams) && (
                            <div className={s.cleanBtnContainer}>
                                <button
                                    className={s.blueBorderBtn}
                                    onClick={() => cleanSearchParams(searchParams, setSearchParams)}>
                                    {t("clear_button")}
                                </button>
                            </div>
                        )}
                    </div>
                </div>

                <div className={s.pageInnerContent}>
                    {
                        currentTab === ACTIVE_FLOWS &&
                        <Active
                            fetchData={fetchActiveFlows}
                            memorizedGeosList={memorizedGeosList}
                        />
                    }
                    {
                        currentTab === EXPIRED_FLOWS &&
                        <Expired
                            fetchData={fetchExpiredFlows}
                            memorizedGeosList={memorizedGeosList}
                        />
                    }
                </div>
                <div
                    className={`${s.paginationContainer} ${isCollapsedSidebar === "true" ? s.collapsed : ""}`}
                >
                    {!flowsListPending && totalCount > 0 && (
                        <div className={s.newRecordsCount}>
                            {t("total")}: {!flowsListPending && <span>{totalCount}</span>}
                        </div>
                    )}
                    {totalCount > 25 && (
                        <Pagination
                            currentPage={parseInt(pageValue) || 1}
                            setCurrentPage={(page) => {
                                updateSearchParams(params.page, page);
                            }}
                            total={totalCount}
                            limit={25}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Flows;
