import { useDispatch, useSelector } from "react-redux";
import {
  globalDataSelector,
  setAdaptiveMenu,
} from "../../../redux/slices/globalDataSlice";
import { Link, useSearchParams } from "react-router-dom";
import useGetSearchParams from "../../../hooks/useGetSearchParams";
import useSetSearchParams from "../../../hooks/useSetSearchParams";
import { useDebounce } from "../../../hooks/useDebounce";
import { page, search } from "../Statistics/ClicksStats/ClicksStats";
import React, { useCallback, useEffect } from "react";
import {
  deletePrelanding,
  fetchPrelandingsList,
} from "../../../redux/slices/prelandingSlice";
import { getTableDefaultParams } from "../../../api/helpers/scripts";
import {
  INVALID_PAGE_ERROR,
  snackbar_error,
  snackbar_info,
  snackbar_success,
} from "../../../utils/vars/staticVariables";

import s from "./prelandings.module.scss";
import { setTopUpBalanceActive } from "../../../redux/slices/financeSlice";
import SvgSelector from "../../../components/SvgSelector/SvgSelector";
import Search from "../../../components/Search/Search";
import EmptyList from "../../../components/EmptyList/EmptyList";
import CustomSkeleton from "../../../components/UiKit/CustomSkeleton/CustomSkeleton";
import PrelandingTd from "./PrelandingTd/PrelandingTd";
import Pagination from "../../../components/Pagination/Pagination";
import { params } from "../../../utils/vars/params";
import useCustomSnackbar from "../../../hooks/useCustomSnackbar";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import LanguageDropdown from "../../../components/LanguageDropdown/LanguageDropdown";
import {is_tech_user} from "../../../storage/user";
import {managementSelector} from "../../../redux/slices/managementSlice";
import Header from "../../../components/Partials/Header/Header";

const Prelandings = () => {
  const dispatch = useDispatch();

  const { prelandingsList, totalCount, prelandingsPending } = useSelector(
    (state) => state.prelanding,
  );

  const { isCollapsedSidebar, isAdaptiveMenuActive } =
    useSelector(globalDataSelector);
  const { standardClickPriceValue } = useSelector(managementSelector);


  const [searchParams, setSearchParams] = useSearchParams();

  const { search: urlSearchValue, page: pageValue } = useGetSearchParams();

  const updateSearchParams = useSetSearchParams(setSearchParams);

  const showSnackbar = useCustomSnackbar();
  const { closeSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const [inputSearchValue, setInputSearchValue] = useDebounce((value) => {
    if (value) {
      updateSearchParams(search, value);
    } else {
      updateSearchParams(search, value, true);
    }
  }, 600);

  useEffect(() => {
    if (!urlSearchValue) {
      setInputSearchValue("");
    } else {
      setInputSearchValue(urlSearchValue);
    }
  }, [searchParams]);

  const fetchPrelandingsData = useCallback(() => {
    const promise = dispatch(
      fetchPrelandingsList(
        getTableDefaultParams({
          searchParams,
          defaultPageSize: 25,
        }),
      ),
    );

    promise.unwrap().catch((err) => {
      if (err?.message === INVALID_PAGE_ERROR) {
        updateSearchParams(page, 1);
      }
    });

    return promise;
  }, [dispatch, searchParams, updateSearchParams]);

  useEffect(() => {
    const promise = fetchPrelandingsData();

    return () => {
      promise.abort();
    };
  }, [dispatch, searchParams]);

  const onDeleteHandler = useCallback(
    async (id) => {
      const pendingDeleteSnackbar = showSnackbar(
        t("notif_delete_flow"),
        snackbar_info,
        true,
      );

      const prelandingDeleteResult = await dispatch(deletePrelanding(id));

      if (prelandingDeleteResult?.payload?.status === 204) {
        fetchPrelandingsData();
        closeSnackbar(pendingDeleteSnackbar);
        showSnackbar(t("prelanding_was_removed"), snackbar_success);
      } else {
        closeSnackbar(pendingDeleteSnackbar);
        showSnackbar(t("failed_to_remove_prelanding"), snackbar_error);
      }
    },
    [closeSnackbar, dispatch, fetchPrelandingsData, showSnackbar],
  );

  return (
    <div
      className={`${s.prelandingsTableWrapper} ${isCollapsedSidebar === "true" ? s.collapsedSidebar : ""}`}
    >
      <Header
          searchValue={inputSearchValue}
          setSearchValue={setInputSearchValue}
          isSearch
          isLink
          linkPath="/prelandings/create"
          buttonText={t("create")}
          isCreateButton
      />
      <div className={s.pageContent}>
        <div className={s.pageInnerContent}>
          {totalCount === 0 && !prelandingsPending ? (
            <EmptyList />
          ) : prelandingsPending ? (
            <section className={s.body}>
              <table>
                <thead>
                  <tr>
                    <th>{t("name_column")}</th>
                    <th>{t("type")}</th>
                    <th></th>
                  </tr>
                </thead>
              </table>
              <div className={s.skeletonWrapper}>
                <CustomSkeleton styles={s.skeletonTable} />
              </div>
            </section>
          ) : (
            <section className={s.body}>
              <table>
                <thead>
                  <tr>
                    <th>{t("name_column")}</th>
                    <th>{t("type")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {prelandingsList.map((item) => (
                    <PrelandingTd
                      key={item.id}
                      item={item}
                      onDeleteHandler={onDeleteHandler}
                    />
                  ))}
                </tbody>
              </table>
            </section>
          )}
        </div>

        <div
          className={`${s.paginationContainer} ${isCollapsedSidebar === "true" ? s.collapsed : ""}`}
        >
          <div className={s.newRecordsCount}>
            {t("total")}: {!prelandingsPending && <span>{totalCount}</span>}
          </div>
          {totalCount > 25 && (
            <Pagination
              currentPage={parseInt(pageValue) || 1}
              setCurrentPage={(page) => {
                updateSearchParams(params.page, page);
              }}
              total={totalCount}
              limit={25}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Prelandings;
