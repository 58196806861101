import {useEffect} from "react";


export const useOutsideAndEscClose = (ref, setActive) => {

    const handleClickOutside = (e) => {
        if (
            ref.current &&
            !ref.current.contains(e.target)
        ) {
            setActive(false);
        }
    };

    const handleEscClose = (e) => {
        if (e.key === "Escape") {
            setActive(false);
            if (document.activeElement) {
                document.activeElement.blur();
            }
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        document.addEventListener("keydown", handleEscClose);

        return () => {
            document.removeEventListener("click", handleClickOutside);
            document.removeEventListener("keydown", handleEscClose);
        };
    }, [ref, setActive]);
}
