import React from "react";
import s from "./adaptiveWheelPreviewBtn.module.scss";
import SvgSelector from "../../../../components/SvgSelector/SvgSelector";

const AdaptiveWheelPreviewBtn = ({ setActive }) => (
  <button
    type="button"
    className={s.adaptiveWheelPreviewBtn}
    onClick={() => setActive(true)}
  >
    <SvgSelector id="wheel-preview-icon" />
    <span className={s.text}>Превью</span>
  </button>
);

export default AdaptiveWheelPreviewBtn;
