import React from "react";
import { useTranslation } from "react-i18next";
import s from "./limitedLengthInput.module.scss";

const LimitedLengthInput = ({
  value,
  action,
  placeholder = "placehoder_type_text",
  maxLength,
  fieldName,
  formik,
}) => {
  const { t } = useTranslation();

  return (
    <div className={s.inputContainer}>
      <input
        type="text"
        name={fieldName}
        className={`${s.textInput} ${
          formik.touched[fieldName] && formik.errors[fieldName] ? s.error : ""
        }`}
        placeholder={t(placeholder)}
        value={value}
        onChange={action}
        maxLength={maxLength}
      />
      <div className={s.lengthIndicator}>
        {value.length}/{maxLength}
      </div>
    </div>
  );
};

export default LimitedLengthInput;
