import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { financeSelector } from "../../../../redux/slices/financeSlice";
import { globalDataSelector } from "../../../../redux/slices/globalDataSlice";
import {
  createTeam,
  managementSelector,
} from "../../../../redux/slices/managementSlice";
import SvgSelector from "../../../SvgSelector/SvgSelector";
import CancelButton from "../../Buttons/CancelBtn/CancelBtn";
import PrimaryBtn from "../../Buttons/PrimaryBorderBtn/PrimaryBorderBtn";
import {
  snackbar_error,
  snackbar_success,
} from "../../../../utils/vars/staticVariables";
import useCustomSnackbar from "../../../../hooks/useCustomSnackbar";
import s from "./addNewTeam.module.scss";
import { userExists } from "../../../../utils/vars/errorMessages";
import ApplicationSelect from "../../../Selects/ApplicationSelect";
import { userTypeList } from "../../../../utils/vars/params";
import { redirectDropdownStyles } from "../GetLinksPopup/GetLinksPopup";
import { useTranslation } from "react-i18next";

const AddNewTeam = ({ active, setActive, setCurrentTab, fetchUsers }) => {
  const { topUpBalanceIsActive } = useSelector(financeSelector);
  const { exitPopup, isCollapsedSidebar } = useSelector(globalDataSelector);
  const { createTeamPending } = useSelector(managementSelector);

  const dispatch = useDispatch();
  const showSnackbar = useCustomSnackbar();

  const { t } = useTranslation();

  useEffect(() => {
    if (topUpBalanceIsActive || exitPopup) {
      setActive(false);
    }
  }, [topUpBalanceIsActive, exitPopup]);

  const formik = useFormik({
    initialValues: {
      email: "",
      pushsub: "",
      offer: "",
      telegram_token: "",
      is_wwa_partner: userTypeList[0],
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("incorrectEmailFormat"))
        .required(t("requiredField")),

      pushsub: Yup.string().required(t("requiredField")),
      telegram_token: Yup.string().required(t("requiredField")),

      offer: Yup.string().required(t("requiredField")),
    }),
    onSubmit: async (form, { setFieldError }) => {
      const dataObj = {};

      dataObj.pushsub = form.pushsub.trim();
      dataObj.email = form.email.trim();
      dataObj.offer = form.offer.trim();
      dataObj.telegram_token = form.telegram_token.trim();
      dataObj.is_wwa_partner = form.is_wwa_partner?.value;

      const createTeamResult = await dispatch(createTeam(dataObj));

      const payload = createTeamResult?.payload;

      const { data, status } = payload;

      if (status === 201) {
        setCurrentTab("users");
        fetchUsers();
        setActive(false);
        showSnackbar(t("new_team_is_created"), snackbar_success);
        formik.resetForm();
      } else if (data?.detail === userExists) {
        setFieldError("email", t("such_user_exists"));
      } else {
        showSnackbar(t("failed_to_create_new_team"), snackbar_error);
      }
    },
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
  });

  useEffect(() => {
    const handleEscClose = (e) => {
      if (e.key === "Escape") {
        setActive(false);
        if (document.activeElement) {
          document.activeElement.blur();
        }
      }
    };

    if (active) {
      document.addEventListener("keydown", handleEscClose);
    }

    return () => {
      document.removeEventListener("keydown", handleEscClose);
    };
  }, [active, setActive]);

  const handleClosePopup = (event) => {
    if (event.target.classList.contains(s.popupWrapper)) {
      setActive(false);
    }
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      onClick={handleClosePopup}
      className={`${s.popupWrapper} ${active ? s.active : ""} ${isCollapsedSidebar === "true" ? s.collapsed : ""}`}
    >
      <div className={active ? `${s.popup} ${s.active}` : s.popup}>
        <button
          onClick={() => setActive(false)}
          className={s.closePopup}
          type="button"
        >
          <SvgSelector id="close-popup" />
        </button>
        <div className={s.headerContainer}>
          <span className={s.iconWrapper}>
            <SvgSelector id="add-new-team-popup-icon" />
          </span>
          <p className={s.title}>{t("new_team")}</p>
        </div>
        <div className={s.bodyContainer}>
          <div className={s.fieldWrapper}>
            <p>{t("email")}</p>
            <div className={s.inputWrapper}>
              <input
                name="email"
                type="text"
                placeholder={t("type_email")}
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`${s.textInput} ${
                  formik.touched.email && formik.errors.email ? s.error : ""
                }`}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className={s.error}>{formik.errors.email}</div>
              ) : null}
            </div>
          </div>

          <div className={s.fieldWrapper}>
            <p>{t("offer")}</p>
            <div className={s.inputWrapper}>
              <input
                name="offer"
                type="text"
                placeholder={t("type_offer")}
                value={formik.values.offer}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`${s.textInput} ${
                  formik.touched.offer && formik.errors.offer ? s.error : ""
                }`}
              />
              {formik.touched.offer && formik.errors.offer ? (
                <div className={s.error}>{formik.errors.offer}</div>
              ) : null}
            </div>
          </div>

          <div className={s.fieldWrapper}>
            <p>Pushsub</p>
            <div className={s.inputWrapper}>
              <input
                name="pushsub"
                type="text"
                placeholder={t("type_pushsub")}
                value={formik.values.pushsub}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`${s.textInput} ${
                  formik.touched.pushsub && formik.errors.pushsub ? s.error : ""
                }`}
              />
              {formik.touched.pushsub && formik.errors.pushsub ? (
                <div className={s.error}>{formik.errors.pushsub}</div>
              ) : null}
            </div>
          </div>

          <div className={s.fieldWrapper}>
            <p>{t("user_type")}</p>
            <div className={s.inputWrapper}>
              <ApplicationSelect
                value={formik.values.is_wwa_partner}
                isClearable={false}
                options={userTypeList}
                onChange={(option) => {
                  formik.setFieldValue("is_wwa_partner", option);
                }}
                isMulti={false}
                placeholderProp={t("choose_option_placeholder")}
                styles={redirectDropdownStyles}
              />
            </div>
          </div>

          <div className={s.fieldWrapper}>
            <p>Token</p>
            <div className={s.inputWrapper}>
              <input
                name="telegram_token"
                type="text"
                placeholder={t("type_token")}
                value={formik.values.telegram_token}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`${s.textInput} ${
                  formik.touched.telegram_token && formik.errors.telegram_token
                    ? s.error
                    : ""
                }`}
              />
              {formik.touched.telegram_token && formik.errors.telegram_token ? (
                <div className={s.error}>{formik.errors.telegram_token}</div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={s.footerContainer}>
          <CancelButton
            text={t("button_cancel")}
            action={() => {
              setActive(false);
              formik.resetForm();
            }}
          />
          <PrimaryBtn
            text={t("button_save")}
            submit={true}
            disabled={createTeamPending}
          />
        </div>
      </div>
    </form>
  );
};

export default AddNewTeam;
