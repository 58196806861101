import React from "react";
import { redirectDropdownStyles } from "../UiKit/Popups/GetLinksPopup/GetLinksPopup";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import {is_tech_user} from "../../storage/user";

const customOption = (props) => {
  const { data, isFocused, isSelected } = props;

  const customStyle = {
    display: "flex",
    alignItems: "center",
    // padding: "10px 16px",
    padding: "10px 8px 10px 16px",
    borderRadius: 20,
    cursor: "pointer",
    backgroundColor: isFocused ? "#1E2437" : "#171a21", // Логіка для фокуса
    color: isSelected ? "#F2F5FA" : "#F2F5FA", // Логіка для вибраного стану
    textAlign: "left",
    marginTop: 7,
    marginBottom: 7,
    "&:last-child": {
      // marginBottom: 7,
    },
    "&:active": {
      backgroundColor: "#1E2437",
    },
    "&:hover": {
      backgroundColor: "#1E2437",
    },
  };

  // Додаткова логіка для кольору, якщо `data.name` і `data.is_alive`
  if (data?.name === "app_list" && !data.is_alive && is_tech_user) {
    customStyle.color = "#d32f2f";
  }

  return (
    <div {...props.innerProps} style={customStyle}>
      {props.data.icon && (
        <img
          src={props.data.icon}
          alt=""
          style={{ width: 20, marginRight: 10, borderRadius: "50%" }}
        />
      )}
      {props.data.label}
    </div>
  );
};

const ApplicationSelect = ({
  isClearable,
  value,
  options,
  onChange,
  isInvalid,
  isMulti,
  placeholderProp = "choose_application",
}) => {
  const { t } = useTranslation();

  return (
    <Select
      value={value}
      isClearable={isClearable}
      options={options}
      onChange={(selectedOption) => onChange(selectedOption)}
      isInvalid={isInvalid}
      isMulti={isMulti}
      placeholder={t(placeholderProp)}
      styles={redirectDropdownStyles}
      noOptionsMessage={() => t("no_options")}
      components={{ Option: customOption }}
    />
  );
};

export default ApplicationSelect;
