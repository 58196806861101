import React from "react";
import { params, sortOrderPrefixes } from "../../utils/vars/params";
import inactiveSortIcon from "../../assets/img/inactiveSortIcon.svg";
import activeSortIcon from "../../assets/img/activeSortIcon.svg";
import s from "./ParamsSort.module.scss";

const ParamsSort = ({ sortBy, setSearchParams, searchParams }) => {
  const activeSort = searchParams.get(params.ordering) || "";
  const isActiveSort =
    activeSort === sortBy ||
    activeSort === `${sortOrderPrefixes.desc}${sortBy}`;
  const isDescending = activeSort === `${sortOrderPrefixes.desc}${sortBy}`;

  const onClickHandler = () => {
    let newSortBy = sortBy;

    if (sortBy === activeSort) {
      if (activeSort.startsWith(sortOrderPrefixes.desc)) {
        // Видалити префікс `-`, щоб змінити на ascending
        newSortBy = activeSort.substring(sortOrderPrefixes.desc.length);
      } else {
        // Додати префікс `-`, щоб змінити на descending
        newSortBy = sortOrderPrefixes.desc + sortBy;
      }
    }

    setSearchParams((_params) => {
      _params.set(params.ordering, newSortBy);
      return _params;
    });
  };

  return (
    <button onClick={onClickHandler} className={s.sortBtn}>
      <img
        width={25}
        height={20}
        src={isActiveSort ? activeSortIcon : inactiveSortIcon}
        alt="sort icon"
        className={`${s.sortIcon} ${isDescending ? s.rotation : ""}`}
      />
    </button>
  );
};
export default ParamsSort;
