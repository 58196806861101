import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { activateAccount, authSelector } from "../../../redux/slices/authSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import s from "./SActivation.module.scss";
import SvgSelector from "../../../components/SvgSelector/SvgSelector";
import * as yup from "yup";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UnauthorizedPopup from "../../../components/UiKit/Popups/UnauthorizedPopup/UnauthorizedPopup";
import { useTranslation } from "react-i18next";

const SActivation = () => {
  const [isErrorPopupActive, setErrorPopupActive] = useState(false);

  const [isShowedNewPassword1, setShowedNewPassword1] = useState(false);
  const [isShowedNewPassword2, setShowedNewPassword2] = useState(false);

  const passwordInputRef1 = useRef(null);
  const passwordInputRef2 = useRef(null);

  const { activateAccountPending } = useSelector(authSelector);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const path = location.pathname.replace("/sactivation/", "");
  const decodedUrlList = decodeURIComponent(path)?.split("/");

  const uid = decodedUrlList[0];
  const token = decodedUrlList[1];

  const handleShowPasswordClick = (e, ref, state, action) => {
    e.preventDefault();
    action(!state);

    if (ref.current) {
      ref.current.focus();
    }
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      re_password: "",
    },
    validationSchema: Yup.object({
      password: yup
        .string()
        // .matches(
        //   /^[A-Za-z0-9\s!@#$%^&*(),.?":{}|<>[\]\\/`~;'\-+=]*$/,
        //   t("passwordLatinCharactersOnly"),
        // )
        .min(10, t("passwordMin10Characters"))
        .max(128, t("passwordMax128Characters"))
        .matches(/[0-9]/, t("passwordMin1Digit"))
        .matches(/[A-Z]/, t("passwordMin1CapitalLetter"))
        .matches(/[a-z]/, t("passwordMin1SmallLetter"))
        .matches(
          /[!@#$%^&*(),.?":{}|<>]/,
          t("password_must_contain_1_special_symbol"),
        )
        .required(t("requiredField")),

      re_password: yup
        .string()
        .oneOf([yup.ref("password"), null], t("passwordsMustMatch"))
        .required(t("requiredField")),
    }),
    onSubmit: async (form) => {
      const dataObj = {
        uid: uid.trim(),
        token: token.trim(),
        password: form.password.trim(),
        re_password: form.re_password.trim(),
      };

      const activateAccountResult = await dispatch(activateAccount(dataObj));

      const { data, status } = activateAccountResult?.payload;

      const { access_token, refresh_token } = data;

      if (status === 200) {
        if (access_token && refresh_token) {
          localStorage.setItem("accessToken", access_token);
          localStorage.setItem("refreshToken", refresh_token);
          window.history.pushState({}, "", "/app-catalog");
          window.location.reload();
        } else {
          navigate("/auth/login");
        }
      } else {
        setErrorPopupActive(true);
      }
    },
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
  });

  return (
    <form className={s.authFormWrapper} onSubmit={formik.handleSubmit}>
      <UnauthorizedPopup
        active={isErrorPopupActive}
        description={t("accActivationPopupError")}
        title={t("activationError")}
        confirmBtnText={t("continue")}
        action={() => navigate("/auth/login")}
        support={true}
        error
      />

      <div className={s.welcomeBlock}>
        <Link to="/auth/login">
          <SvgSelector id="logo-icon" />
        </Link>
        <p>{t("accountActivation")}</p>
      </div>
      <div className={s.formDescription}>{t("enterPassword")}</div>

      <div className={s.formFieldsWrapper}>
        <div className={s.fieldWrapper}>
          <p className={s.fieldDescription}>{t("newPassword")}</p>
          <div
            className={`${s.inputWrapper} ${
              formik.touched.password && formik.errors.password
                ? s.wrapperError
                : ""
            }`}
          >
            <button
              type="button"
              className={s.showPasswordBtn}
              onClick={(e) =>
                handleShowPasswordClick(
                  e,
                  passwordInputRef1,
                  isShowedNewPassword1,
                  setShowedNewPassword1,
                )
              }
            >
              <SvgSelector
                id={
                  isShowedNewPassword1
                    ? "show-password-icon"
                    : "hide-password-icon"
                }
              />
            </button>
            <input
              name="password"
              value={formik.values.password}
              ref={passwordInputRef1}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`${s.input} ${s.password} ${
                formik.touched.password && formik.errors.password ? s.error : ""
              }`}
              type={isShowedNewPassword1 ? "text" : "password"}
              placeholder={t("enterPassword")}
            />
          </div>
          {formik.touched.password && formik.errors.password ? (
            <div className={s.errorMessage}>{formik.errors.password}</div>
          ) : null}
        </div>

        <div className={s.fieldWrapper}>
          <p className={s.fieldDescription}>{t("repeatPassword")}</p>
          <div
            className={`${s.inputWrapper} ${
              formik.touched.re_password && formik.errors.re_password
                ? s.wrapperError
                : ""
            }`}
          >
            <button
              type="button"
              className={s.showPasswordBtn}
              onClick={(e) =>
                handleShowPasswordClick(
                  e,
                  passwordInputRef2,
                  isShowedNewPassword2,
                  setShowedNewPassword2,
                )
              }
            >
              <SvgSelector
                id={
                  isShowedNewPassword2
                    ? "show-password-icon"
                    : "hide-password-icon"
                }
              />
            </button>
            <input
              name="re_password"
              value={formik.values.re_password}
              ref={passwordInputRef2}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`${s.input} ${s.password} ${
                formik.touched.re_password && formik.errors.re_password
                  ? s.error
                  : ""
              }`}
              type={isShowedNewPassword2 ? "text" : "password"}
              placeholder={t("enterPassword")}
            />
          </div>
          {formik.touched.re_password && formik.errors.re_password ? (
            <div className={s.errorMessage}>{formik.errors.re_password}</div>
          ) : null}
        </div>
      </div>
      <div className={s.buttonWrapper}>
        <button
          className={s.enterBtn}
          type="submit"
          disabled={activateAccountPending}
        >
          {t("activate")}
        </button>
      </div>
    </form>
  );
};

export default SActivation;
