import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useWindowWidth } from "../../../hooks/useWindowWidth";
import {applicationSelector, fetchAppListWithSearch} from "../../../redux/slices/applicationSlice";
import {is_tech_user} from "../../../storage/user";
import CustomSkeleton from "../../../components/UiKit/CustomSkeleton/CustomSkeleton";
import EmptyList from "../../../components/EmptyList/EmptyList";
import s from "./appCatalog.module.scss";
import AppCard from "./AppCard/AppCard";
import {
  fetchGeosList,
  globalDataSelector,
} from "../../../redux/slices/globalDataSlice";
import useGetSearchParams from "../../../hooks/useGetSearchParams";
import useSetSearchParams from "../../../hooks/useSetSearchParams";
import { useDebounce } from "../../../hooks/useDebounce";
import { search } from "../Statistics/ClicksStats/ClicksStats";
import Header from "../../../components/Partials/Header/Header";

const AppCatalog = () => {
  const dispatch = useDispatch();
  const { appList, appListLoading } = useSelector(applicationSelector);
  const { isCollapsedSidebar, geoListPending } =
    useSelector(globalDataSelector);

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const { search: urlSearchValue } = useGetSearchParams();

  const updateSearchParams = useSetSearchParams(setSearchParams);

  const [inputSearchValue, setInputSearchValue] = useDebounce((value) => {
    if (value) {
      updateSearchParams(search, value);
    } else {
      updateSearchParams(search, value, true);
    }
  }, 600);

  const filteredAppList = useMemo(() => {
    if (is_tech_user) {
      return appList.filter((item) => item?.is_alive === true);
    }
    return appList;
  }, [appList]);

  const width = useWindowWidth();

  useEffect(() => {
    dispatch(fetchAppListWithSearch(searchParams));
  }, [dispatch, searchParams]);

  useEffect(() => {
    dispatch(fetchGeosList());
  }, [dispatch]);

  useEffect(() => {
    if (!urlSearchValue) {
      setInputSearchValue("");
    } else {
      setInputSearchValue(urlSearchValue);
    }
  }, [searchParams]);

  let skeletonsCount;

  if (isCollapsedSidebar === "true") {
    if (width < 2288) {
      skeletonsCount = 9;
    }
    if (width >= 2288 && width < 2815) {
      skeletonsCount = 12;
    }

    if (width >= 2815 && width < 3492) {
      skeletonsCount = 15;
    }

    if (width > 3341 && width < 3868) {
      skeletonsCount = 18;
    }

    if (width > 3868) {
      skeletonsCount = 21;
    }
  } else {
    if (width < 2438) {
      skeletonsCount = 9;
    }
    if (width > 2437 && width < 2965) {
      skeletonsCount = 12;
    }

    if (width > 2964 && width < 3492) {
      skeletonsCount = 15;
    }

    if (width > 3491 && width < 4019) {
      skeletonsCount = 18;
    }

    if (width > 4018) {
      skeletonsCount = 21;
    }
  }

  const skeletons = (styles) => {
    return [...new Array(skeletonsCount)].map((_, index) => (
      <CustomSkeleton styles={styles} key={index} />
    ));
  };

  const pageWidth = useWindowWidth();

  return (
    <div
      className={`${s.appCatalogWrapper} ${isCollapsedSidebar === "true" ? s.collapsedSidebar : ""}`}
    >
      <Header
          searchValue={inputSearchValue}
          setSearchValue={setInputSearchValue}
          isSearch
      />
      <div className={s.pageContent}>
        <div className={s.appCatalog}>
          {!appListLoading && !geoListPending && filteredAppList.length < 1 ? (
            <EmptyList />
          ) : (
            <div
              className={`${s.content} ${isCollapsedSidebar === "true" ? s.collapsedSidebar : ""}`}
            >
              {appListLoading || geoListPending
                ? skeletons(s.skeletonCard)
                : filteredAppList.map((item) => (
                    <AppCard
                      key={item.id}
                      {...item}
                      navigate={navigate}
                      pageWidth={pageWidth}
                    />
                  ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AppCatalog;
