import React from "react";
import SvgSelector from "../../../components/SvgSelector/SvgSelector";
import {faq, lessons} from "../../../utils/vars/documentation";
import s from "./documentation.module.scss";
import {useSelector} from "react-redux";
import {
    globalDataSelector,
} from "../../../redux/slices/globalDataSlice";
import {useTranslation} from "react-i18next";
import Header from "../../../components/Partials/Header/Header";

const Documentation = () => {
    const {isCollapsedSidebar} = useSelector(globalDataSelector);

    const {t} = useTranslation();

    return (
        <div
            className={`${s.documentationWrapper} ${isCollapsedSidebar === "true" ? s.collapsedSidebar : ""}`}
        >
            <Header/>
            <div className={s.pageContent}>
                <div className={s.documentation}>
                    <div>
                        <div className={s.chapterHeader}>
                            <p className={s.name}>{t(faq.name)}</p>
                            <p className={s.desc}>{t(faq.desc)}</p>
                        </div>
                        <ul className={s.faqLinkList}>
                            {faq.linksList.map(({link, name, image, desc}, index) => (
                                <li key={index} className={s.faqCard}>
                                    <a href={link} target="_blank" rel="noreferrer">
                                        <div
                                            className={s.cardImg}
                                            style={{
                                                backgroundImage: `url(${image})`,
                                            }}
                                        ></div>
                                    </a>
                                    <div className={s.body}>
                                        <div className={s.name}>{t(name)}</div>
                                        <div className={s.desc}>{t(desc)}</div>
                                        <div className={s.linkContainer}>
                                            <a
                                                href={link}
                                                target="_blank"
                                                className={s.link}
                                                rel="noreferrer"
                                            >
                                                {t("follow")}{" "}
                                                <SvgSelector id="follow-link-faq-icon"/>
                                            </a>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div>
                        <div className={s.chapterHeader}>
                            <p className={s.name}>{t(lessons.name)}</p>
                            <p className={s.desc}>{t(lessons.desc)}</p>
                        </div>
                        <ul className={s.lessonsLinkList}>
                            {lessons.linksList.map(({link, name, image, desc}, index) => (
                                <li key={index} className={s.lessonsCard}>
                                    <div className={s.lessonTag}>
                                        <SvgSelector id="faq-lesson-player-icon"/> Урок{" "}
                                        {index + 1}
                                    </div>
                                    <a href={link} target="_blank" rel="noreferrer">
                                        <div
                                            className={s.cardImg}
                                            style={{
                                                backgroundImage: `url(${image})`,
                                            }}
                                        ></div>
                                    </a>
                                    <div className={s.body}>
                                        <div className={s.name}>{t(name)}</div>
                                        <div className={s.desc}>{t(desc)}</div>
                                        <div className={s.linkContainer}>
                                            <a
                                                href={link}
                                                target="_blank"
                                                className={s.link}
                                                rel="noreferrer"
                                            >
                                                {t("follow")}{" "}
                                                <SvgSelector id="follow-link-faq-icon"/>
                                            </a>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Documentation;
