import {Navigate, Route, Routes} from "react-router-dom";
import MainLayout from "../layout/MainLayout/MainLayout";
import AppCatalog from "../pages/Authorized/AppCatalog/AppCatalog";
import Documentation from "../pages/Authorized/Documentation/Documentation";
import AuthLayout from "../layout/AuthLayout/AuthLayout";
import PrelandLayout from "../layout/PrelandLayout/PrelandLayout";
import Balances from "../pages/AdminAccess/Balances/Balances";
import Flows from "../pages/Authorized/Flows/Flows";
import PixelApi from "../pages/Authorized/PixelApi/PixelApi";
import ClicksStats from "../pages/Authorized/Statistics/ClicksStats/ClicksStats";
import CloacaStats from "../pages/Authorized/Statistics/CloacaStats/CloacaStats";
import CommonStats from "../pages/Authorized/Statistics/CommonStats/CommonStats";
import GeoAppStats from "../pages/AdminAccess/Statistics/GeoAppStats/GeoAppStats";
import UsersStats from "../pages/AdminAccess/Statistics/UsersStats/UsersStats";
import Transactions from "../pages/Authorized/Transactions/Transactions";
import EditPixelApi from "../pages/Authorized/PixelApi/EditPixelApi/EditPixelApi";
import Prelandings from "../pages/Authorized/Prelandings/Prelandings";
import CreatePrelanding from "../pages/Authorized/Prelandings/CreatePrelanding/CreatePrelanding";
import EditPrelanding from "../pages/Authorized/Prelandings/EditPrelanding/EditPrelanding";
import AllRedirectors from "../pages/Authorized/AllRedirectors/AllRedirectors";
import CreateFlow from "../pages/Authorized/CreateFlow/CreateFlow";
import FlowSettings from "../pages/Authorized/FlowSettings/FlowSettings";
import NewAuth from "../pages/Unauthorized/NewAuth/NewAuth";
import WebPrelanding from "../pages/Unauthorized/WebPrelanding/WebPrelanding";
import Page404 from "../pages/Authorized/Page404/Page404";
import ChangePassword from "../pages/Authorized/ChangePassword/ChangePassword";
import Management from "../pages/AdminAccess/Management/Management";
import EditUser from "../pages/AdminAccess/Management/EditUser/EditUser";
import PostbackLogsStats from "../pages/Authorized/Statistics/PostbackLogsStats/PostbackLogsStats";
import SActivation from "../pages/Unauthorized/SActivation/SActivation";
import NewPassword from "../pages/Unauthorized/ResetPassword/NewPassword/NewPassword";
import EmailConfirm from "../pages/Unauthorized/ResetPassword/EmailConfirm/EmailConfirm";
import ConversionStats from "../pages/Authorized/Statistics/ConversionStats/ConversionStats";
import {is_tech_user} from "../storage/user";

export const useRoutes = (isLogged) => {
    if (isLogged) {
        return (
            <Routes>
                <Route path="/" element={<PrelandLayout/>}>
                    <Route path="prelanding/preview" element={<WebPrelanding/>}/>
                </Route>

                <Route path="/" element={<MainLayout/>}>
                    <Route index element={<Navigate to="/app-catalog" replace/>}/>

                    <Route path="*" element={<Page404/>}/>
                    <Route path="app-catalog" element={<AppCatalog/>}/>

                    <Route path="my-flows/create-flow" element={<CreateFlow/>}/>
                    <Route path="my-flows/edit/:id" element={<FlowSettings/>}/>
                    <Route path="my-flows" element={<Flows/>}/>

                    <Route path="stats/clicks" element={<ClicksStats/>}/>
                    {/*<Route path="stats/conversion" element={<ConversionStats/>}/>*/}
                    <Route path="stats/common" element={<CommonStats/>}/>
                    <Route path="stats/cloaca" element={<CloacaStats/>}/>
                    <Route path="stats/postback-logs" element={<PostbackLogsStats/>}/>

                    {is_tech_user && (
                        <>
                            <Route path="stats/geo+app" element={<GeoAppStats/>}/>
                            <Route path="stats/users" element={<UsersStats/>}/>
                            <Route path="balances" element={<Balances/>}/>
                            <Route path="accounts-management" element={<Management/>}/>
                            <Route path="accounts-management/edit-user/:id" element={<EditUser/>}/>
                        </>
                    )}

                    <Route path="account" element={<ChangePassword/>}/>

                    <Route path="pixel-api" element={<PixelApi/>}/>
                    <Route path="pixel-api/edit/:id" element={<EditPixelApi/>}/>
                    <Route path="documentation" element={<Documentation/>}/>
                    <Route path="transactions" element={<Transactions/>}/>

                    <Route path="prelandings" element={<Prelandings/>}/>
                    <Route path="prelandings/create" element={<CreatePrelanding/>}/>
                    <Route path="prelandings/edit/:id" element={<EditPrelanding/>}/>

                    <Route path="all-redirectors" element={<AllRedirectors/>}/>
                </Route>
            </Routes>
        );
    }
    return (
        <Routes>
            <Route path="/" element={<AuthLayout/>}>
                <Route path="sactivation/*" element={<SActivation/>}/>
                <Route path="reset/new-password/*" element={<NewPassword/>}/>
                <Route path="reset/email-confirm" element={<EmailConfirm/>}/>
                <Route path="auth/login" element={<NewAuth/>}/>
                <Route index element={<Navigate to="/auth/login" replace/>}/>
                <Route path="*" element={<Navigate to="/auth/login" replace/>}/>
            </Route>

            <Route path="/" element={<PrelandLayout/>}>
                <Route path="prelanding/preview" element={<WebPrelanding/>}/>
            </Route>
        </Routes>
    );
};
