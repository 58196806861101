import React, { useState, useRef, useCallback, useEffect } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { useDispatch } from "react-redux";
import debounce from "lodash/debounce";
import {asyncSelectStyles} from "./MultiSelect/MultiSelect";
import { useTranslation } from "react-i18next";
import { getFilterRequest } from "../../api/helpers/getFilterRequest";
import {getDomainFromUrl} from "../../utils/helpers/getDomainFromUrl";

export const CustomAsyncSelect = ({
                                      setOptions,
                                      placeholder = "Select options...",
                                      isMulti = true,
                                      value = null,
                                      isClearable = true,
                                      defaultValue = null,
                                      isInvalid = false,
                                      requestName,
                                      pageSize,
                                      pending,
                                      extractDomain = false,
                                  }) => {
    const selectContainerRef = useRef(null);

    const [inputValue, setInputValue] = useState("");
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const fetchOptionsFunction = useCallback(getFilterRequest(requestName, dispatch), [
        requestName,
        dispatch,
    ]);

    const debouncedFetchOptions = useRef(
        debounce(async (searchQuery, page, callback) => {
            try {
                const query = extractDomain ? getDomainFromUrl(searchQuery) : searchQuery;

                const { options, hasMore } = await fetchOptionsFunction({
                    page,
                    pageSize,
                    searchQuery: query,
                });

                callback({
                    options,
                    hasMore,
                    additional: {
                        page: page + 1,
                    },
                });
            } catch (error) {
                callback({
                    options: [],
                    hasMore: false,
                });
            }
        }, 300)
    ).current;

    const loadOptions = useCallback(
        (searchQuery, loadedOptions, { page }) => {
            return new Promise((resolve) => {
                debouncedFetchOptions(searchQuery, page, resolve);
            });
        },
        [debouncedFetchOptions]
    );

    const handleInputChange = useCallback(
        (newValue, { action }) => {
            if (action === "input-change") {
                setInputValue(newValue);

                debouncedFetchOptions(newValue, 1, () => {});
            }
        },
        [debouncedFetchOptions]
    );

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                selectContainerRef.current &&
                !selectContainerRef.current.contains(event.target)
            ) {
                setMenuIsOpen(false);
                setInputValue("");
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div ref={selectContainerRef}>
            <AsyncPaginate
                value={value}
                loadOptions={loadOptions}
                onChange={setOptions}
                isMulti={isMulti}
                styles={asyncSelectStyles}
                placeholder={placeholder}
                isClearable={isClearable}
                defaultValue={defaultValue}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                additional={{
                    page: 1,
                }}
                isLoading={pending}
                noOptionsMessage={() => t("no_options")}
                loadingMessage={() => t("search_options")}
                isInvalid={isInvalid}
                menuIsOpen={menuIsOpen}
                onFocus={() => setMenuIsOpen(true)}
            />
        </div>
    );
};
