export const getDaysToExpiry = (expiredDateStr) => {
    const expiredDate = new Date(expiredDateStr);
    const currentDate = new Date();


    expiredDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);

    return Math.ceil((expiredDate - currentDate) / (1000 * 60 * 60 * 24));
}
