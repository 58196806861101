import React, {useEffect, useRef} from "react";
import s from "./editPrelanding.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate, useParams} from "react-router-dom";
import {
    editPrelanding,
    fetchWheelStylesList,
    getPrelandingById,
    patchEditPrelanding,
    prelandingSelector,
} from "../../../../redux/slices/prelandingSlice";
import {
    globalDataSelector,
    setAdaptiveMenu,
} from "../../../../redux/slices/globalDataSlice";
import useCustomSnackbar from "../../../../hooks/useCustomSnackbar";
import {useFormik} from "formik";
import {
    prelandingStatusList,
    prelandingTypesList,
    snackbar_error,
    snackbar_success,
} from "../../../../utils/vars/staticVariables";
import * as Yup from "yup";
import CustomSkeleton from "../../../../components/UiKit/CustomSkeleton/CustomSkeleton";
import {setTopUpBalanceActive} from "../../../../redux/slices/financeSlice";
import SvgSelector from "../../../../components/SvgSelector/SvgSelector";
import GradientBorder from "../../../../components/GradientBorder/GradientBorder";
import LimitedLengthInput from "../../../../components/LimitedLengthInput/LimitedLengthInput";
import {Radio} from "@mui/material";
import Wheel from "../Wheel/Wheel";
import PrimaryBtn from "../../../../components/UiKit/Buttons/PrimaryBorderBtn/PrimaryBorderBtn";
import ReactSelectTheme from "../../../../components/ReactSelectTheme/ReactSelectTheme";
import EditCustom from "./EditCustom/EditCustom";
import EditDefault from "./EditDefault/EditDefault";
import {
    noIndexHtml,
    noIndexHtmlFormikError, noLinkInIndexHtml,
    prelandingFileCyrillicError,
} from "../../../../utils/vars/errorMessages";
import {useTranslation} from "react-i18next";
import {
    file_cyrillic_error,
    not_found_index_html,
} from "../../../../utils/vars/notificationsMessages";
import LanguageDropdown from "../../../../components/LanguageDropdown/LanguageDropdown";
import {is_tech_user} from "../../../../storage/user";
import {managementSelector} from "../../../../redux/slices/managementSlice";
import Header from "../../../../components/Partials/Header/Header";

const EditPrelanding = () => {
    const errorRefs = useRef({}); // Додано для збереження рефів полів з помилками

    const {isCollapsedSidebar, isAdaptiveMenuActive} =
        useSelector(globalDataSelector);
    const {wheelStylesList, prelandingObj} = useSelector(prelandingSelector);

    const {standardClickPriceValue} = useSelector(managementSelector);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const {id} = useParams();

    const showSnackbar = useCustomSnackbar();

    const {t} = useTranslation();

    const initialValues = {
        status: prelandingObj?.status,
        name: prelandingObj?.name || "",
        num_of_revolutions: 1,
        prelanding_type:
            prelandingObj?.prelanding_type || prelandingTypesList[0].value,
        header_text: prelandingObj?.header_text || "",
        rotation_button_text: prelandingObj?.rotation_button_text || "",
        respin_button_text: prelandingObj?.respin_button_text || "",
        style: prelandingObj?.style || wheelStylesList[0]?.id,
        sectors:
            prelandingObj?.sectors ||
            new Array(8).fill().map((_, index) => ({
                name: `Сектор ${index}`,
                value: "",
                is_win: index <= 0,
            })),
        bonus_button_text: prelandingObj?.bonus_button_text || "",
        popup_win_text: prelandingObj?.popup_win_text || "",
        winning_button_text: prelandingObj?.winning_button_text || "",
        logo: null,
    };

    const editDefaultPrelandFormik = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema: Yup.object({
            name: Yup.string().required(t("requiredField")),
            // header_text: Yup.string().required("Обязательное поле"),
            rotation_button_text: Yup.string().required(t("requiredField")),
            respin_button_text: Yup.string().required(t("requiredField")),
            sectors: Yup.array().of(
                Yup.object().shape({
                    value: Yup.string().required(t("fill_sector_value")),
                }),
            ),
            bonus_button_text: Yup.string().required(t("requiredField")),
            winning_button_text: Yup.string().required(t("requiredField")),
            style: Yup.string().required(t("requiredField")),
        }),
        onSubmit: async (data, {setFieldError, validateField, setFieldValue}) => {
            const editPrelandingResult = await dispatch(
                editPrelanding({prelandObj: data, id}),
            );
            if (editPrelandingResult?.payload?.status === 200) {
                navigate("/prelandings");
                showSnackbar(
                    `${t("prelanding") + " " + t("is_edited")}`,
                    snackbar_success,
                );
            } else {
                showSnackbar(t("failed_to_edit_prelanding"), snackbar_error);
            }
        },
        validateOnMount: true,
        validateOnBlur: true,
        validateOnChange: true,
    });

    const editCustomPrelandFormik = useFormik({
        initialValues: {
            prelanding_file: prelandingObj?.prelanding_file,
            prelanding_type: prelandingObj?.prelanding_type,
            name: prelandingObj?.name,
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            name: Yup.string().required(t("requiredField")),
            prelanding_file: Yup.string().required(t("upload_zip_archive")),
        }),
        onSubmit: async (data, {setFieldError, validateField, setFieldValue}) => {
            if (
                data.prelanding_file === prelandingObj?.prelanding_file &&
                data.name === prelandingObj?.name
            ) {
                setFieldError("name", " ");
                setFieldError("prelanding_file", t("validation_make_changes"));

                return;
            }

            if (
                data.prelanding_file === prelandingObj?.prelanding_file &&
                data.name !== prelandingObj?.name
            ) {
                const patchRequestObj = {
                    name: data.name,
                    prelanding_type: data.prelanding_type,
                };

                const editPrelandingResult = await dispatch(
                    patchEditPrelanding({prelandObj: patchRequestObj, id}),
                );

                if (editPrelandingResult?.payload?.status === 200) {
                    navigate("/prelandings");
                    showSnackbar(t("prelanding_name_edited"), snackbar_success);
                } else {
                    showSnackbar(t("failed_to_edit_prelanding"), snackbar_error);
                }
                return;
            }

            const formData = new FormData();
            formData.append("prelanding_file", data.prelanding_file);
            formData.append("prelanding_type", data.prelanding_type);
            formData.append("name", data.name);

            const editPrelandingResult = await dispatch(
                editPrelanding({prelandObj: formData, id}),
            );
            if (editPrelandingResult?.payload?.status === 200) {
                navigate("/prelandings");
                showSnackbar(
                    `${t("prelanding") + " " + t("is_edited")}`,
                    snackbar_success,
                );
            } else if (editPrelandingResult?.payload?.data?.detail === noIndexHtml) {
                return setFieldError("prelanding_file", t(not_found_index_html));
            } else if (
                editPrelandingResult?.payload?.data?.detail === noLinkInIndexHtml
            ) {
                return setFieldError("prelanding_file", t('no_link_in_index_html'));
            } else if (
                editPrelandingResult?.payload?.data?.detail ===
                prelandingFileCyrillicError
            ) {
                return setFieldError("prelanding_file", t(file_cyrillic_error));
            } else {
                showSnackbar(t("failed_to_edit_prelanding"), snackbar_error);
            }
        },
        validateOnMount: true,
        validateOnBlur: true,
        validateOnChange: true,
    });

    useEffect(() => {
        dispatch(fetchWheelStylesList());
    }, [dispatch]);

    useEffect(() => {
        // Функція для скролу до першого поля з помилкою з урахуванням фіксованого хедера
        const scrollToError = () => {
            const firstErrorKey = Object.keys(editDefaultPrelandFormik.errors)[0];
            if (firstErrorKey && errorRefs.current[firstErrorKey]) {
                const errorElement = errorRefs.current[firstErrorKey];
                const headerOffset = 80; // Висота фіксованого хедера
                const elementPosition =
                    errorElement.getBoundingClientRect().top + window.pageYOffset;
                const offsetPosition = elementPosition - headerOffset;

                // Перевірка, чи поле з помилкою знаходиться поза зоною видимості
                const isOutOfView =
                    elementPosition < window.pageYOffset ||
                    elementPosition > window.pageYOffset + window.innerHeight;

                if (isOutOfView) {
                    window.scrollTo({
                        top: offsetPosition,
                        behavior: "smooth",
                    });
                }
            }
        };

        if (
            editDefaultPrelandFormik.isSubmitting &&
            Object.keys(editDefaultPrelandFormik.errors).length > 0
        ) {
            scrollToError();
        }
    }, [editDefaultPrelandFormik.isSubmitting, editDefaultPrelandFormik.errors]);

    useEffect(() => {
        dispatch(getPrelandingById(id));
    }, [dispatch, id]);

    return (
        <div
            className={`${s.editPrelandingWrapper} ${
                isCollapsedSidebar === "true" ? s.collapsedSidebar : ""
            }`}
        >
            <Header />
            <div className={s.pageContent}>
                <div className={s.pageMarking}>
                    <Link to="/prelandings" className={s.link}>
                        {t("prelandings")}
                    </Link>
                    <SvgSelector id="arrow-right"/>
                    <span>{t("edit")}</span>
                </div>
                <GradientBorder className={s.gradientBorderCustom}/>
                {prelandingObj?.prelanding_type === 1 && (
                    <EditDefault formik={editDefaultPrelandFormik}/>
                )}
                {prelandingObj?.prelanding_type === 4 && (
                    <EditCustom formik={editCustomPrelandFormik}/>
                )}
            </div>
        </div>
    );
};

export default EditPrelanding;
