import { useDispatch, useSelector } from "react-redux";
import { financeSelector } from "../../../../../../redux/slices/financeSlice";
import { globalDataSelector } from "../../../../../../redux/slices/globalDataSlice";
import s from "./uploadPrelandingPopup.module.scss";
import React, { useEffect } from "react";
import SvgSelector from "../../../../../../components/SvgSelector/SvgSelector";
import CancelButton from "../../../../../../components/UiKit/Buttons/CancelBtn/CancelBtn";
import PrimaryBtn from "../../../../../../components/UiKit/Buttons/PrimaryBorderBtn/PrimaryBorderBtn";
import { prelandingSelector } from "../../../../../../redux/slices/prelandingSlice";
import { useTranslation } from "react-i18next";

const UploadPrelandingPopup = ({
  active,
  setActive,
  action,
  forcedClose = true,
  icon,
  formik,
}) => {
  const { topUpBalanceIsActive } = useSelector(financeSelector);
  const { exitPopup, isCollapsedSidebar } = useSelector(globalDataSelector);

  const { createPrelandPending } = useSelector(prelandingSelector);

  const { prelanding_file, name } = formik.values;

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const handleClosePopup = (event) => {
    if (event.target.classList.contains(s.popupWrapper)) {
      setActive(false);
    }
  };

  useEffect(() => {
    if ((forcedClose && topUpBalanceIsActive) || (forcedClose && exitPopup)) {
      setActive(false);
    }
  }, [topUpBalanceIsActive, exitPopup]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setActive(false);
    action();
  };

  useEffect(() => {
    const handleEscClose = (e) => {
      if (e.key === "Escape") {
        setActive(false);
        if (document.activeElement) {
          document.activeElement.blur();
        }
      }
    };
    if (active) {
      document.addEventListener("keydown", handleEscClose);
    }

    return () => {
      document.removeEventListener("keydown", handleEscClose);
    };
  }, [active]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      className={`${s.popupWrapper} ${active ? s.active : ""} ${isCollapsedSidebar === "true" ? s.collapsed : ""}`}
      onClick={handleClosePopup}
    >
      <div className={active ? `${s.popup} ${s.active}` : s.popup}>
        <button
          onClick={() => setActive(false)}
          className={s.closePopup}
          type="button"
        >
          <SvgSelector id="close-popup" />
        </button>
        <div className={s.headerContainer}>
          <span className={s.iconWrapper}>{icon}</span>
          <p className={s.title}>{t("preland_uploading")}</p>
        </div>
        <div className={s.bodyContainer}>
          <div className={s.formFieldsWrapper}>
            <div className={s.fieldWrapper}>
              <p className={s.fieldName}>{t("prelanding_name")}</p>
              <input
                type="text"
                name="name"
                className={`${s.textInput} ${
                  formik.touched.name && formik.errors.name ? s.error : ""
                }`}
                placeholder={t("placehoder_type_text")}
                value={name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className={s.error}>{formik.errors.name}</div>
              ) : null}
            </div>
            <div className={s.uploadWrapper}>
              {/*<div className={s.uploadWrapper}>*/}
              <label
                htmlFor="popup-upload-button"
                className={`${s.uploadPrelandBtn} ${
                  formik.touched.prelanding_file &&
                  formik.errors.prelanding_file
                    ? s.zipArchiveError
                    : ""
                }`}
              >
                <SvgSelector id="archive-download-icon" />
                <span>{prelanding_file?.name || t("upload")}</span>
              </label>
              {formik.touched.prelanding_file &&
              formik.errors.prelanding_file ? (
                <div className={s.error}>{formik.errors.prelanding_file}</div>
              ) : null}
              <input
                id="popup-upload-button"
                type="file"
                style={{ display: "none" }}
                onChange={(e) => {
                  formik.setFieldValue("prelanding_file", e.target.files[0]);
                }}
                accept=".zip"
              />
            </div>
          </div>
        </div>
        <div className={s.footer}>
          <CancelButton
            text={t("button_cancel")}
            action={() => {
              setActive(false);
              formik.resetForm();
            }}
            width={120}
          />
          <PrimaryBtn
            text={t("confirm")}
            submit={true}
            pending={createPrelandPending}
          />
        </div>
      </div>
    </form>
  );
};

export default UploadPrelandingPopup;
