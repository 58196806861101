import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import $api, {API} from "../../api/url";

export const login = createAsyncThunk("auth/login", async (form) => {
  try {
    const response = await axios.post(
      `${API}/users/token/obtain/`,
      form,
    );

    const { data, status } = response;

    return { data, status };
  } catch (error) {
    const { data, status } = error.response;

    return { data, status };
  }
});

export const resetEmailConfirm = createAsyncThunk(
  "auth/reset-email-confirm",
  async (dataObj) => {
    try {
      const response = await axios.post(
        `${API}/auth/users/reset_password/`,
        dataObj,
      );

      const { data, status } = response;

      return { data, status };
    } catch (error) {
      const { data, status } = error.response;

      return { data, status };
    }
  },
);

export const resetPasswordConfirm = createAsyncThunk(
  "auth/reset_password",
  async (dataObj) => {
    try {
      const response = await axios.post(
        `${API}/auth/users/reset_password_confirm/`,
        dataObj,
      );

      const { data, status } = response;

      return { data, status };
    } catch (error) {
      const { data, status } = error.response;

      return { data, status };
    }
  },
);

export const activateAccount = createAsyncThunk(
  "auth/activateAccount",
  async (form) => {
    try {
      const response = await axios.post(`${API}/sactivation/`, form);

      const { data, status } = response;

      return { data, status };
    } catch (error) {
      const { data, status } = error.response;

      return { data, status };
    }
  },
);

export const register = createAsyncThunk(
  "auth/register",
  async ({ data, successAction, setError }) => {
    try {
      const response = await axios.post(`${API}/auth/users/`, data);
      successAction();
      return response.data;
    } catch (error) {
      const emailError = error?.response?.data?.email;
      const passwordError = error?.response?.data?.password;

      if (
        emailError?.includes("user with this email address already exists.")
      ) {
        setError("email", {
          type: "manual",
          message: "Запись с такой почтой уже существует",
        });
      }

      if (
        passwordError?.includes(
          "The password is too similar to the email address.",
        )
      ) {
        setError("newPassword1", {
          type: "manual",
          message: "Пароль должен отличаться от почты",
        });
      }

      if (passwordError?.includes("This password is too common.")) {
        setError("newPassword1", {
          type: "manual",
          message: "Пароль слишком легкий",
        });
      }

      throw error;
    }
  },
);

export const refreshTokens = createAsyncThunk("auth/checkAuth", async () => {
  try {
    const refreshToken = localStorage.getItem("refreshToken");
    const response = await $api.post("/users/token/refresh/", {
      refresh: refreshToken,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const fetchMyUserInfo = createAsyncThunk("fetchMyUserInfo", async () => {
  try {
    const response = await $api.get("/auth/users/me/");

    return response.data;
  } catch (error) {
    throw error;
  }
});

export const changePassword = createAsyncThunk(
  "account/changePassword",
  async (dataObj) => {
    try {
      const response = await $api.post("/auth/users/set_password/", dataObj);
      const { data, status } = response;

      return { data, status };
    } catch (error) {
      const { data, status } = error.response;

      return { data, status };
    }
  },
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
    changePasswordPending: false,
    isPartner: null,

    activateAccountPending: false,
    resetEmailConfirmPending: false,
    resetPasswordConfirmPending: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(login.rejected, (state) => {
        state.loading = false;
      })

      .addCase(register.pending, (state) => {
        state.loading = true;
      })
      .addCase(register.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(register.rejected, (state) => {
        state.loading = false;
      })

      .addCase(fetchMyUserInfo.fulfilled, (state, action) => {
        state.isPartner = action?.payload?.is_wwa_partner;
      })
      .addCase(fetchMyUserInfo.rejected, (state) => {
        state.isPartner = true;
      })

      .addCase(changePassword.pending, (state) => {
        state.changePasswordPending = true;
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.changePasswordPending = false;
      })
      .addCase(changePassword.rejected, (state) => {
        state.changePasswordPending = false;
      })

      .addCase(activateAccount.pending, (state) => {
        state.activateAccountPending = true;
      })
      .addCase(activateAccount.fulfilled, (state) => {
        state.activateAccountPending = false;
      })
      .addCase(activateAccount.rejected, (state) => {
        state.activateAccountPending = false;
      })

      .addCase(resetEmailConfirm.pending, (state) => {
        state.resetEmailConfirmPending = true;
      })
      .addCase(resetEmailConfirm.fulfilled, (state) => {
        state.resetEmailConfirmPending = false;
      })
      .addCase(resetEmailConfirm.rejected, (state) => {
        state.resetEmailConfirmPending = false;
      })

      .addCase(resetPasswordConfirm.pending, (state) => {
        state.resetPasswordConfirmPending = true;
      })
      .addCase(resetPasswordConfirm.fulfilled, (state) => {
        state.resetPasswordConfirmPending = false;
      })
      .addCase(resetPasswordConfirm.rejected, (state) => {
        state.resetPasswordConfirmPending = false;
      })

      .addCase(refreshTokens.pending, (state) => {
        state.loading = true;
      })
      .addCase(refreshTokens.fulfilled, (state, action) => {
        localStorage.setItem("accessToken", action.payload.access);
        localStorage.setItem("refreshToken", action.payload.refresh);
        state.loading = false;
      })
      .addCase(refreshTokens.rejected, (state) => {
        state.loading = false;
      });
  },
});

// export const {} = authSlice.actions;

export default authSlice.reducer;

export const authSelector = (state) => state.auth;
