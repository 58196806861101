export const nonClearableParams = ["page", "purpose", "content"]

export const hasNonEmptyParams = (searchParams) => {

  for (let param of searchParams.keys()) {
    if (
        !nonClearableParams.includes(param) &&
        searchParams.get(param) &&
        searchParams.get(param).trim() !== ""
    ) {
      return true;
    }
  }

  return false;
};


export const cleanSearchParams = (searchParams, setSearchParams) => {
  const newParams = new URLSearchParams();
  const updatedNonClearableParams = nonClearableParams.filter(param => param !== "page");

  for (let param of searchParams.keys()) {
    if (updatedNonClearableParams.includes(param)) {
      newParams.set(param, searchParams.get(param));
    }
  }

  setSearchParams(newParams);
};