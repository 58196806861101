import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import pixelApiReducer from "./slices/pixelApiSlice";
import redirectorSlice from "./slices/redirectorSlice";
import applicationSlice from "./slices/applicationSlice";
import statisticsSlice from "./slices/statisticsSlice";
import flowSlice from "./slices/flowSlice";
import flowSettingsSlice from "./slices/flowSettingsSlice";
import financeSlice from "./slices/financeSlice";
import globalDataSlice from "./slices/globalDataSlice";
import prelandingSlice from "./slices/prelandingSlice";
import managementSlice from "./slices/managementSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    pixelApi: pixelApiReducer,
    redirector: redirectorSlice,
    application: applicationSlice,
    statistics: statisticsSlice,
    flow: flowSlice,
    flowSettings: flowSettingsSlice,
    finance: financeSlice,
    globalData: globalDataSlice,
    prelanding: prelandingSlice,
    management: managementSlice,
  },
});
