import React from "react";
import { useRoutes } from "./hooks/useRoutes";
import {isUserAuthenticated} from "./storage/user";
import CustomSnackbarProvider from "./components/UiKit/SnackbarProvider/SnackbarProvider";
import s from "./assets/styles/app.module.scss";

function App() {
  const routes = useRoutes(isUserAuthenticated);

  return (
    <div className={s.appWrapper}>
      <CustomSnackbarProvider>{routes}</CustomSnackbarProvider>
    </div>
  );
}

export default App;
