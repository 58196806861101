import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import $api from "../../api/url";

import { errorMessage } from "../../api/helpers/scripts";
import axios from "axios";
import { ABORTED_ERROR } from "../../utils/vars/staticVariables";

export const fetchPrelandingsList = createAsyncThunk(
  "prelanding/fetchPrelandingsList",
  async (params, { signal, rejectWithValue }) => {
    try {
      const { data } = await $api.get("/prelanding/?" + params, {
        signal,
      });
      return data;
    } catch (error) {
      let err = errorMessage(error);

      if (axios.isCancel(error)) {
        return rejectWithValue();
      }

      throw new Error(err);
    }
  },
);

export const fetchAllPrelandings = createAsyncThunk(
  "prelanding/fetchAllPrelandings",
  async () => {
    try {
      const response = await $api.get(`/prelanding/?page_size=1000&page=1`);
      return response.data;
    } catch (error) {
      const { data, status } = error.response;
      return { data, status };
    }
  },
);

export const getPrelandingById = createAsyncThunk(
  "prelanding/getPrelandingById",
  async (id) => {
    try {
      const response = await $api.get(`/prelanding/${id}/`);

      return response.data;
    } catch (error) {
      const { data, status } = error.response;

      return { data, status };
    }
  },
);

export const customPrelandingUpload = createAsyncThunk(
  "prelanding/customPrelandingUpload",
  async ({ formData, onSuccessAction, onErrorAction }) => {
    try {
      const response = await $api.post(
        "/configuration/flow/create/set_prelanding/",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );

      onSuccessAction();

      return response.data;
    } catch (error) {
      const errorMessage = error.response.data.detail;

      if (errorMessage) {
        onErrorAction(errorMessage);
        throw new Error(errorMessage);
      } else {
        throw new Error(error);
      }

      // if (errorMessage === 'Нет свободных доменов') {
      //     onErrorAction("Нет доступных доменов для создания потока")
      //     throw new Error(errorMessage);
      // }
      // if (errorMessage === 'Недостаточная сумма на балансе для создания потока. Пополните баланс.') {
      //     onErrorAction("Недостачно денег на балансе")
      //     throw new Error(errorMessage);
      // } else {
      //     onErrorAction("Произошла ошибка при создании потока")
      //     throw error
      // }

      // throw error;
    }
  },
);

export const fetchWheelStylesList = createAsyncThunk(
  "prelanding/fetchWheelStylesList",
  async () => {
    try {
      const response = await $api.get("/prelanding/styles/");
      return response.data;
    } catch {}
  },
);

export const deletePrelanding = createAsyncThunk(
  "prelanding/deletePrelanding",
  async (id) => {
    try {
      const response = await $api.delete(`/prelanding/${id}/`);
      const { data, status } = response;

      return { data, status };
    } catch (error) {
      const { data, status } = error.response;

      return { data, status };
    }
  },
);

export const createPrelanding = createAsyncThunk(
  "prelanding/createPrelanding",
  async (prelandObj) => {
    try {
      const response = await $api.post(`/prelanding/`, prelandObj);
      const { data, status } = response;

      return { data, status };
    } catch (error) {
      const { data, status } = error.response;

      return { data, status };
    }
  },
);

export const editPrelanding = createAsyncThunk(
  "prelanding/editPrelanding",
  async ({ prelandObj, id }) => {
    try {
      const response = await $api.put(`/prelanding/${id}/`, prelandObj);

      const { data, status } = response;

      return { data, status };
    } catch (error) {
      const { data, status } = error.response;

      return { data, status };
    }
  },
);

export const patchEditPrelanding = createAsyncThunk(
  "prelanding/patchEditPrelanding",
  async ({ prelandObj, id }) => {
    try {
      const response = await $api.patch(`/prelanding/${id}/`, prelandObj);

      const { data, status } = response;

      return { data, status };
    } catch (error) {
      const { data, status } = error.response;

      return { data, status };
    }
  },
);

const prelandingSlice = createSlice({
  name: "prelanding",
  initialState: {
    prelandingsList: [],
    prelandingsPending: false,
    totalCount: 0,

    wheelStylesList: [],
    wheelStylesLoading: false,

    prelandPending: false,
    createPrelandPending: false,
    getPrelandPending: false,
    editPrelandPending: false,
    currentPage: 1,

    prelandingObj: null,

    allPrelandingsPending: false,
    allPrelandingsList: [],

    // prelandingObj: null,
    prelandingObjId: null,
    prelandingObjLoading: false,

    customPrelandPending: false,

    selectedImage: "",
    spinCount: 1,
    prelandingName: "",
    prelandTitle: "",
    spinButtonText: "",
    respinButtonText: "",
    popupTitle: "",
    popupText: "",
    popupButtonText: "",
    styleId: 1,

    currentWheel: null,

    // currentWheel: {
    //     id: 1,
    //     background: '/static/media/blueBg.91bfaf22c80389297f4d.png',
    //     wheel: "/static/media/blueWheelImg.3361f562dbe24aa60733bdea4e55bb31.svg",
    //     button: "/static/media/blueWheelBtn.058b645cc9b0dd225d251d19e8725a67.svg",
    //     pointer: "/static/media/blueWheelPointer.c6bf6fe15884f8ddb281bb19b71d1484.svg",
    //     popup: "/static/media/bluePopup.0583c1635b8553b21ae568c308ed55c2.svg"
    // },

    sectors: [],
    prelandingStatus: true,
  },

  // http://46.101.141.204

  reducers: {
    setPrelandPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setAllPrelandingsList: (state, action) => {
      state.allPrelandingsList = action.payload;
    },
    setWheelStylesList: (state, action) => {
      state.wheelStylesList = action.payload;
    },

    setSelectedImage: (state, action) => {
      state.selectedImage = action.payload;
    },

    setSectors: (state, action) => {
      state.sectors = action.payload;
    },

    setSpinCount: (state, action) => {
      state.spinCount = action.payload;
    },

    setPrelandingName: (state, action) => {
      state.prelandingName = action.payload;
    },

    setPrelandTitle: (state, action) => {
      state.prelandTitle = action.payload;
    },

    setSpinButtonText: (state, action) => {
      state.spinButtonText = action.payload;
    },

    setRespinButtonText: (state, action) => {
      state.respinButtonText = action.payload;
    },

    setPopupTitle: (state, action) => {
      state.popupTitle = action.payload;
    },

    setPopupText: (state, action) => {
      state.popupText = action.payload;
    },

    setPopupButtonText: (state, action) => {
      state.popupButtonText = action.payload;
    },

    setStyleId: (state, action) => {
      state.styleId = action.payload;
    },

    setCurrentWheel: (state, action) => {
      state.currentWheel = action.payload;
    },

    setPrelandingStatus: (state, action) => {
      state.prelandingStatus = action.payload;
    },

    resetStylesList: (state) => {
      state.wheelStylesList = [];
    },

    updateSectorValue: (state, action) => {
      const { index, newValue } = action.payload;
      state.sectors = state.sectors.map((sector, sectorIndex) =>
        sectorIndex === index ? { ...sector, value: newValue } : sector,
      );
    },

    // setStatus: (state, action) => {
    //     state.status = action.payload
    // },
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchPrelandingsList.pending, (state) => {
        state.prelandingsPending = true;
        state.prelandingsList = [];
      })
      .addCase(fetchPrelandingsList.fulfilled, (state, { payload }) => {
        const { results, count } = payload;

        state.prelandingsList = results;
        state.totalCount = count;
        state.prelandingsPending = false;
      })
      .addCase(fetchPrelandingsList.rejected, (state, { error }) => {
        const { message } = error;

        state.prelandingsPending = message === ABORTED_ERROR;
        state.prelandingsList = [];

        if (message !== ABORTED_ERROR) {
          state.totalCount = 0;
        }
      })

      .addCase(fetchAllPrelandings.pending, (state) => {
        state.allPrelandingsPending = true;
        state.allPrelandingsList = [];
      })
      .addCase(fetchAllPrelandings.fulfilled, (state, action) => {
        state.allPrelandingsList = action.payload?.results;
        state.allPrelandingsPending = false;
      })
      .addCase(fetchAllPrelandings.rejected, (state, action) => {
        state.allPrelandingsList = [];
        state.allPrelandingsPending = false;
      })

      .addCase(createPrelanding.pending, (state) => {
        state.createPrelandPending = true;
      })
      .addCase(createPrelanding.fulfilled, (state, action) => {
        state.createPrelandPending = false;
      })
      .addCase(createPrelanding.rejected, (state, action) => {
        state.createPrelandPending = false;
      })

      .addCase(customPrelandingUpload.pending, (state) => {
        state.customPrelandPending = true;
      })
      .addCase(customPrelandingUpload.fulfilled, (state, action) => {
        state.customPrelandPending = false;
      })
      .addCase(customPrelandingUpload.rejected, (state, action) => {
        state.customPrelandPending = false;
      })

      .addCase(editPrelanding.pending, (state) => {
        state.editPrelandPending = true;
      })
      .addCase(editPrelanding.fulfilled, (state, action) => {
        state.editPrelandPending = false;
      })
      .addCase(editPrelanding.rejected, (state, action) => {
        state.editPrelandPending = false;
      })

      .addCase(patchEditPrelanding.pending, (state) => {
        state.editPrelandPending = true;
      })
      .addCase(patchEditPrelanding.fulfilled, (state, action) => {
        state.editPrelandPending = false;
      })
      .addCase(patchEditPrelanding.rejected, (state, action) => {
        state.editPrelandPending = false;
      })

      .addCase(getPrelandingById.pending, (state) => {
        state.getPrelandPending = true;
        state.prelandingObj = null;
      })
      .addCase(getPrelandingById.fulfilled, (state, action) => {
        state.getPrelandPending = false;
        state.prelandingObj = action.payload;
      })
      .addCase(getPrelandingById.rejected, (state, action) => {
        state.getPrelandPending = false;
        state.prelandingObj = null;
      })

      .addCase(fetchWheelStylesList.pending, (state) => {
        state.wheelStylesLoading = true;
        state.wheelStylesList = [];
      })
      .addCase(fetchWheelStylesList.fulfilled, (state, action) => {
        state.wheelStylesList = action.payload;
        state.wheelStylesLoading = false;
      })
      .addCase(fetchWheelStylesList.rejected, (state, action) => {
        state.wheelStylesList = [];
        state.wheelStylesLoading = false;
      });
  },
});

export const {
  setAllPrelandingsList,
  setWheelStylesList,
} = prelandingSlice.actions;

export const prelandingSelector = (state) => state.prelanding;

export default prelandingSlice.reducer;
