import React, { useEffect } from "react";
import SvgSelector from "../../../SvgSelector/SvgSelector";
import s from "./setNotePopup.module.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { globalDataSelector } from "../../../../redux/slices/globalDataSlice";
import { financeSelector } from "../../../../redux/slices/financeSlice";
import CancelButton from "../../Buttons/CancelBtn/CancelBtn";
import PrimaryBtn from "../../Buttons/PrimaryBorderBtn/PrimaryBorderBtn";
import { flowSelector, setNote } from "../../../../redux/slices/flowSlice";
import { useTranslation } from "react-i18next";

const SetNotePopup = ({
  active,
  setActive,
  flowId,
  title,
  notes,
  setNoteText,
  icon,
  onSetNoteHandler,
}) => {
  const { topUpBalanceIsActive } = useSelector(financeSelector);
  const { exitPopup, isCollapsedSidebar } = useSelector(globalDataSelector);
  const { setNotePending } = useSelector(flowSelector);

  const { t } = useTranslation();

  useEffect(() => {
    if (topUpBalanceIsActive || exitPopup) {
      setActive(false);
    }
  }, [topUpBalanceIsActive, exitPopup]);

  const formik = useFormik({
    initialValues: {
      textarea: notes || "",
    },
    validationSchema: Yup.object({
      textarea: Yup.string()
        .matches(/[\p{L}\d]/u, t("set_note_popup_validation_one_value"))
        .test("is-modified", t("validation_make_changes"), function (value) {
          return value !== notes;
        })
        .test("is-changed", t("validation_make_changes"), function (value) {
          return value !== this.resolve(Yup.ref("initialTextarea"));
        }),
    }),
    onSubmit: async (form) => {
      const dataObj = {
        flow_id: flowId,
        notes: form.textarea.trim(),
      };
      onSetNoteHandler(dataObj);
    },
  });

  useEffect(() => {
    if (notes) {
      setNoteText(notes);
    }

    const handleEscClose = (e) => {
      if (e.key === "Escape") {
        setActive(false);
        if (document.activeElement) {
          document.activeElement.blur();
        }
      }
    };

    if (active) {
      document.addEventListener("keydown", handleEscClose);
    }

    return () => {
      document.removeEventListener("keydown", handleEscClose);
    };
  }, [notes, active, setNoteText, setActive]);

  const handleClosePopup = (event) => {
    if (event.target.classList.contains(s.popupWrapper)) {
      setActive(false);
    }
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      onClick={handleClosePopup}
      className={`${s.popupWrapper} ${active ? s.active : ""} ${isCollapsedSidebar === "true" ? s.collapsed : ""}`}
    >
      <div className={active ? `${s.popup} ${s.active}` : s.popup}>
        <button
          onClick={() => setActive(false)}
          className={s.closePopup}
          type="button"
        >
          <SvgSelector id="close-popup" />
        </button>
        <div className={s.headerContainer}>
          <span className={s.iconWrapper}>{icon}</span>
          <p className={s.title}>{title}</p>
        </div>
        <div className={s.bodyContainer}>
          <textarea
            id={flowId}
            name="textarea"
            className={`${s.customTextarea} ${formik.touched.textarea && formik.errors.textarea ? `${s.error}` : ""}`} // Умовно додаємо клас
            placeholder={t("placehoder_type_text")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.textarea}
          ></textarea>
          {formik.touched.textarea && formik.errors.textarea ? (
            <div className={s.error}>{formik.errors.textarea}</div>
          ) : null}
        </div>
        <div className={s.footerContainer}>
          <CancelButton
            text={t("button_cancel")}
            action={() => {
              setActive(false);
              formik.resetForm();
            }}
          />
          <PrimaryBtn
            text={t("button_save")}
            submit={true}
            disabled={setNotePending}
          />
        </div>
      </div>
    </form>
  );
};

export default SetNotePopup;
