import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  globalDataSelector,
} from "../../../../redux/slices/globalDataSlice";
import useCustomSnackbar from "../../../../hooks/useCustomSnackbar";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  checkPixelTokenValid,
  editPixelApiItem,
  fetchPixelApiItem,
  pixelApiSelector,
} from "../../../../redux/slices/pixelApiSlice";
import {
  snackbar_error,
  snackbar_success,
} from "../../../../utils/vars/staticVariables";
import { pixelIdExists } from "../../../../utils/vars/errorMessages";
import { Link, useNavigate, useParams } from "react-router-dom";
import {getUser} from "../../../../storage/user";
import s from "./editPixelApi.module.scss";
import SvgSelector from "../../../../components/SvgSelector/SvgSelector";
import PrimaryBtn from "../../../../components/UiKit/Buttons/PrimaryBorderBtn/PrimaryBorderBtn";
import { useTranslation } from "react-i18next";
import Header from "../../../../components/Partials/Header/Header";

const EditPixelApi = () => {
  const { isCollapsedSidebar } = useSelector(globalDataSelector);

  const {
    pixelApiItem,
    pixelApiItemPending,
    editPixelApiPending,
    checkPixelApiPending,
  } = useSelector(pixelApiSelector);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const showSnackbar = useCustomSnackbar();

  const { t } = useTranslation();

  const { user_id } = getUser();

  useEffect(() => {
    dispatch(fetchPixelApiItem(id));
  }, [dispatch, id]);

  const formik = useFormik({
    initialValues: {
      pixel_id: pixelApiItem?.pixel_id || "",
      token: pixelApiItem?.token || "",
      owner: user_id,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      pixel_id: Yup.string()
        .matches(/^\d+$/, t("validation_must_contain_only_digits"))
        .matches(/^\S*$/, t("validation_no_space_between_characters"))
        .required(t("requiredField")),
      token: Yup.string()
        .matches(/^[a-zA-Z0-9]+$/, t("validation_symbols_and_digits"))
        .required(t("requiredField")),
    }),
    onSubmit: async (data, { setFieldError, validateField, setFieldValue }) => {
      const isValidTokenResult = await dispatch(
        checkPixelTokenValid(data.token),
      );

      if (isValidTokenResult?.payload?.status === 200) {
        const editPixelApiResult = await dispatch(
          editPixelApiItem({ pixelApiObj: data, id }),
        );

        if (editPixelApiResult?.payload?.status === 200) {
          navigate("/pixel-api");
          showSnackbar(t("pixel_api_is_edited"), snackbar_success);
        } else {
          if (editPixelApiResult?.payload?.data?.details === pixelIdExists) {
            return setFieldError("pixel", t("pixel_with_such_id_exists"));
          }

          showSnackbar(t("failed_to_edit_pixel_api"), snackbar_error);
        }
      } else {
        setFieldError("token", t("invalid_token"));
      }
    },
  });

  return (
    <div
      className={`${s.editPixelApiWrapper} ${isCollapsedSidebar === "true" ? s.collapsedSidebar : ""}`}
    >
      <Header />
      <div className={s.pageContent}>
        <div className={s.pageMarking}>
          <Link to="/pixel-api" className={s.link}>
            Pixel API
          </Link>
          <SvgSelector id="arrow-right" />
          <span>{t("edit")}</span>
        </div>
        <form className={s.pixelForm} onSubmit={formik.handleSubmit}>
          <div className={s.inputsWrapper}>
            <div className={s.inputWrapper}>
              <p className={s.inputName}>Token</p>
              <input
                name="pixel_id"
                type="text"
                className={`${s.textInput} ${
                  formik.touched.pixel_id && formik.errors.pixel_id
                    ? s.error
                    : ""
                }`}
                placeholder={
                  pixelApiItemPending ? t("loading") : t("type_pixel")
                }
                value={formik.values.pixel_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.pixel_id && formik.errors.pixel_id ? (
                <div className={s.error}>{formik.errors.pixel_id}</div>
              ) : null}
            </div>
            <div className={s.inputWrapper}>
              <p className={s.inputName}>Token</p>
              <input
                name="token"
                type="text"
                className={`${s.textInput} ${
                  formik.touched.token && formik.errors.token ? s.error : ""
                }`}
                placeholder={
                  pixelApiItemPending ? t("loading") : t("type_token")
                }
                value={formik.values.token}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.token && formik.errors.token ? (
                <div className={s.error}>{formik.errors.token}</div>
              ) : null}
            </div>
          </div>
          <div className={s.buttons}>
            <Link className={s.whiteBorderBtn} to="/pixel-api">
              {t("button_cancel")}
            </Link>
            <PrimaryBtn
              text={t("button_save")}
              submit={true}
              pending={
                editPixelApiPending ||
                pixelApiItemPending ||
                checkPixelApiPending
              }
              className={s.customPrimaryBtn}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditPixelApi;
