import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import $api from "../../api/url";
import axios from "axios";
import { errorMessage } from "../../api/helpers/scripts";
import {
  ABORTED_ERROR,
  REJECTED_ERROR,
} from "../../utils/vars/staticVariables";
import {
  created_at_after,
  created_at_before,
  date_time_after,
  date_time_before,
} from "../../pages/Authorized/Statistics/ClicksStats/ClicksStats";

export const fetchClicksStats = createAsyncThunk(
  "statistics/fetchClicksStats",
  async (params, { signal, rejectWithValue }) => {
    try {
      const copiedParams = new URLSearchParams(params);
      const status = copiedParams.get("status");
      let customStatus = "";
      if (status) {
        copiedParams.delete("status");
        customStatus =
          "&" +
          status
            .split(",")
            .map((item) => item + "=true")
            .join("&");
      }
      const { data } = await $api.get(
        "/click-data/statistic/?" + copiedParams + customStatus,
        { signal },
      );
      return data;
    } catch (error) {
      let err = errorMessage(error);

      if (axios.isCancel(error)) {
        return rejectWithValue();
      }

      throw new Error(err);
    }
  },
);


export const fetchConversionStats = createAsyncThunk(
  "statistics/fetchConversionStats",
  async (params, { signal, rejectWithValue }) => {
    try {
      const copiedParams = new URLSearchParams(params);
      const status = copiedParams.get("status");
      let customStatus = "";
      if (status) {
        copiedParams.delete("status");
        customStatus =
          "&" +
          status
            .split(",")
            .map((item) => item + "=true")
            .join("&");
      }
      const { data } = await $api.get(
        "/click-data/conversions/?" + copiedParams + customStatus,
        { signal },
      );
      return data;
    } catch (error) {
      let err = errorMessage(error);

      if (axios.isCancel(error)) {
        return rejectWithValue();
      }

      throw new Error(err);
    }
  },
);

export const fetchPostbackLogsStats = createAsyncThunk(
  "statistics/fetchPostbackLogsStats",
  async (params, { signal, rejectWithValue }) => {
    try {
      const copiedParams = new URLSearchParams(params);
      const { data } = await $api.get("/postbacklogs/?" + copiedParams, {
        signal,
      });
      return data;
    } catch (error) {
      let err = errorMessage(error);

      if (axios.isCancel(error)) {
        return rejectWithValue();
      }

      throw new Error(err);
    }
  },
);

export const downloadCommonStats = createAsyncThunk(
    "statistics/downloadCommonStats",
    async ({ params, file_type }) => {
      const copiedParams = new URLSearchParams(params);
      if (!copiedParams.get('sort') && !copiedParams.get('created_at_after') && !copiedParams.get('created_at_before')) {
        copiedParams.set('sort', 'today');
      }

      try {
        const response = await $api.get(
            `/click-data/clicks/clicks-file/?file_type=${file_type}&` + copiedParams,
            {
              responseType: "arraybuffer",
              headers: {
                Accept: "*/*",
              },
            }
        );

        const { data, status } = response;
        return { data, status };
      } catch (error) {
        const { status, data } = error.response || {};
        return { data, status };
      }
    }
);

export const downloadClicksStats = createAsyncThunk(
    "statistics/downloadClicksStats",
    async ({ params, file_type }) => {

      const copiedParams = new URLSearchParams(params);
      if (!copiedParams.get('sort') && !copiedParams.get('created_at_after') && !copiedParams.get('created_at_before')) {
        copiedParams.set('sort', 'today');
      }

      try {
        const response = await $api.get(
            `/click-data/statistic/statistic-file/?file_type=${file_type}&` + copiedParams,
            {
              responseType: "arraybuffer",
              headers: {
                Accept: "*/*",
              },
            }
        );

        const { data, status } = response;
        return { data, status };
      } catch (error) {
        const { status, data } = error.response || {};
        return { data, status };
      }
    }
);




export const fetchCommonStats = createAsyncThunk(
  "statistics/fetchCommonStatisticsData",
  async (params, { signal, rejectWithValue }) => {
    try {
      const { data } = await $api.get("/click-data/clicks/?" + params, {
        signal,
      });
      return data;
    } catch (error) {
      let err = errorMessage(error);

      if (axios.isCancel(error)) {
        return rejectWithValue();
      }

      throw new Error(err);
    }
  },
);

export const fetchGeoAppStats = createAsyncThunk(
  "statistics/fetchGeoApp",
  async (params, { signal, rejectWithValue }) => {
    try {
      const { data } = await $api.get("/click-data/geo-app/?" + params, {
        signal,
      });
      return data;
    } catch (error) {
      let err = errorMessage(error);

      if (axios.isCancel(error)) {
        return rejectWithValue();
      }

      throw new Error(err);
    }
  },
);

export const fetchUsersStats = createAsyncThunk(
  "statistics/fetchUsersStats",
  async (params, { signal, rejectWithValue }) => {
    try {
      const { data } = await $api.get("/click-data/geo-app-user/?" + params, {
        signal,
      });
      return data;
    } catch (error) {
      let err = errorMessage(error);

      if (axios.isCancel(error)) {
        return rejectWithValue();
      }

      throw new Error(err);
    }
  },
);

export const fetchCloacaStats = createAsyncThunk(
  "statistics/fetchCloacaStats",
  async ({ params, signal }, { rejectWithValue }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel("Операцію скасовано користувачем.");
    });

    try {
      const copiedParams = new URLSearchParams(params);

      const replaceParamName = (oldName, newName) => {
        if (copiedParams.has(oldName)) {
          const value = copiedParams.get(oldName);
          copiedParams.delete(oldName);
          copiedParams.set(newName, value);
        }
      };

      replaceParamName(created_at_after, date_time_after);
      replaceParamName(created_at_before, date_time_before);

      const { data } = await $api.get(
        "/click-data/report-statistic/?" + copiedParams,
        {
          signal,
        },
      );
      return data;
    } catch (error) {
      let err = errorMessage(error);

      if (axios.isCancel(error)) {
        return rejectWithValue();
      }

      throw new Error(err);
    }
  },
);

const statisticsSlice = createSlice({
  name: "statistics",
  initialState: {
    statisticsList: [],
    cloacaStatsList: [],
    loading: false,
    cloacaLoading: false,
    totalCount: null,
    currentPage: 1,
    totalInstalls: 0,
    totalLeads: 0,
    totalSales: 0,
    totalClicks: 0,
    uniqueClicks: 0,

    downloadCommonStatisticsPending: false,
    downloadClicksStatisticsPending: false,
  },
  reducers: {
    resetCloacaStats: (state) => {
      state.cloacaStatsList = [];
      state.cloacaTotalCount = null;
      state.cloacaLoading = false;
    },
    resetTotalCount: (state) => {
      state.totalCount = null;
    },
  },
  extraReducers: (builder) => {
    builder
      /////////////////////////// ЗАГАЛЬНА СТАТИСТИКА //////////////////////////////////////////
      .addCase(fetchCommonStats.pending, (state) => {
        state.loading = true;
        state.statisticsList = [];
        state.totalInstalls = 0;
        state.totalLeads = 0;
        state.totalSales = 0;
        state.totalClicks = 0;
        state.uniqueClicks = 0;
      })
      .addCase(fetchCommonStats.fulfilled, (state, { payload }) => {
        const {
          results,
          count,
          sum_total_installs,
          sum_total_leads,
          sum_total_sales,
          sum_total_clicks,
          sum_unique_clicks,
        } = payload;

        state.statisticsList = results;
        state.totalCount = count;
        state.totalInstalls = sum_total_installs;
        state.totalLeads = sum_total_leads;
        state.totalSales = sum_total_sales;
        state.totalClicks = sum_total_clicks;

        if (sum_unique_clicks) {
          state.uniqueClicks = sum_unique_clicks;
        }

        state.loading = false;
        // state.loading = true;
      })
      .addCase(fetchCommonStats.rejected, (state, { error }) => {
        const { message } = error;

        state.loading = message === ABORTED_ERROR;
        state.statisticsList = [];

        if (message !== ABORTED_ERROR) {
          state.totalCount = 0;
        }

        state.totalInstalls = 0;
        state.totalLeads = 0;
        state.totalSales = 0;
        state.totalClicks = 0;
        state.uniqueClicks = 0;
        state.statisticsList = [];
      })

      /////////////////////////// СТАТИСТИКА ПО КЛІКАХ //////////////////////////////////////////
      .addCase(fetchClicksStats.pending, (state) => {
        state.loading = true;
        state.statisticsList = [];
      })
      .addCase(fetchClicksStats.fulfilled, (state, { payload }) => {
        const { results, count } = payload;

        state.statisticsList = results;
        state.totalCount = count;
        state.loading = false;
      })

      .addCase(fetchClicksStats.rejected, (state, { error }) => {
        const { message } = error;

        state.loading = message === ABORTED_ERROR;
        state.statisticsList = [];

        if (message !== ABORTED_ERROR) {
          state.totalCount = 0;
        }
      })

        .addCase(fetchConversionStats.pending, (state) => {
        state.loading = true;
        state.statisticsList = [];
      })
      .addCase(fetchConversionStats.fulfilled, (state, { payload }) => {
        const { results, count } = payload;

        state.statisticsList = results;
        state.totalCount = count;
        state.loading = false;
      })

      .addCase(fetchConversionStats.rejected, (state, { error }) => {
        const { message } = error;

        state.loading = message === ABORTED_ERROR;
        state.statisticsList = [];

        if (message !== ABORTED_ERROR) {
          state.totalCount = 0;
        }
      })

      .addCase(fetchPostbackLogsStats.pending, (state) => {
        state.loading = true;
        state.statisticsList = [];
      })
      .addCase(fetchPostbackLogsStats.fulfilled, (state, { payload }) => {
        const { results, count } = payload;

        state.statisticsList = results;
        state.totalCount = count;
        // state.totalCount = 0;
        state.loading = false;
      })
      .addCase(fetchPostbackLogsStats.rejected, (state, { error }) => {
        const { message } = error;

        state.loading = message === ABORTED_ERROR;
        state.statisticsList = [];

        if (message !== ABORTED_ERROR) {
          state.totalCount = 0;
        }
      })

      .addCase(fetchCloacaStats.pending, (state) => {
        state.cloacaLoading = true;
        state.cloacaStatisticsList = [];
      })
      .addCase(fetchCloacaStats.fulfilled, (state, { payload }) => {
        const { results, count } = payload;

        state.cloacaStatisticsList = results;
        state.cloacaTotalCount = count;
        state.cloacaLoading = false;
      })
      .addCase(fetchCloacaStats.rejected, (state, { error }) => {
        const { message } = error;

        state.cloacaLoading = message === REJECTED_ERROR;
        state.cloacaStatisticsList = [];

        if (message !== REJECTED_ERROR) {
          state.cloacaTotalCount = 0;
        }
      })

      .addCase(downloadCommonStats.pending, (state) => {
        state.downloadCommonStatisticsPending = true
      })
      .addCase(downloadCommonStats.fulfilled, (state, { payload }) => {
        state.downloadCommonStatisticsPending = false
      })
      .addCase(downloadCommonStats.rejected, (state) => {
        state.downloadCommonStatisticsPending = false
      })

      .addCase(downloadClicksStats.pending, (state) => {
        state.downloadClicksStatisticsPending = true
      })
      .addCase(downloadClicksStats.fulfilled, (state, { payload }) => {
        state.downloadClicksStatisticsPending = false
      })
      .addCase(downloadClicksStats.rejected, (state) => {
        state.downloadClicksStatisticsPending = false
      })

      ////////////////////////////// GEO + APP ///////////////////////////////////////
      .addCase(fetchGeoAppStats.pending, (state) => {
        state.loading = true;
        state.statisticsList = [];
        state.totalInstalls = 0;
        state.totalLeads = 0;
        state.totalSales = 0;
        state.totalClicks = 0;
      })

      .addCase(fetchGeoAppStats.fulfilled, (state, { payload }) => {
        const {
          results,
          count,
          sum_total_installs,
          sum_total_leads,
          sum_total_sales,
          sum_total_clicks,
        } = payload;

        state.statisticsList = results;
        state.totalCount = count;
        state.totalInstalls = sum_total_installs;
        state.totalLeads = sum_total_leads;
        state.totalSales = sum_total_sales;
        state.totalClicks = sum_total_clicks;

        state.loading = false;
      })
      .addCase(fetchGeoAppStats.rejected, (state, { error }) => {
        const { message } = error;

        state.loading = message === ABORTED_ERROR;
        state.statisticsList = [];

        if (message !== ABORTED_ERROR) {
          state.totalCount = 0;
        }

        state.totalInstalls = 0;
        state.totalLeads = 0;
        state.totalSales = 0;
        state.totalClicks = 0;
        state.statisticsList = [];
      })

      ////////////////////////////// СТАТИСТИКА ПО КОРИСТУВАЧАХ ///////////////////////////////////////
      .addCase(fetchUsersStats.pending, (state) => {
        state.loading = true;
        state.statisticsList = [];
        state.totalInstalls = 0;
        state.totalLeads = 0;
        state.totalSales = 0;
        state.totalClicks = 0;
      })
      .addCase(fetchUsersStats.fulfilled, (state, { payload }) => {
        const {
          results,
          count,
          sum_total_installs,
          sum_total_leads,
          sum_total_sales,
          sum_total_clicks,
        } = payload;

        state.statisticsList = results;
        state.totalCount = count;
        state.totalInstalls = sum_total_installs;
        state.totalLeads = sum_total_leads;
        state.totalSales = sum_total_sales;
        state.totalClicks = sum_total_clicks;

        state.loading = false;
        // state.loading = true;
      })
      .addCase(fetchUsersStats.rejected, (state, { error }) => {
        const { message } = error;

        state.loading = message === ABORTED_ERROR;
        state.statisticsList = [];

        if (message !== ABORTED_ERROR) {
          state.totalCount = 0;
        }

        state.totalInstalls = 0;
        state.totalLeads = 0;
        state.totalSales = 0;
        state.totalClicks = 0;
      });
  },
});

export const { resetCloacaStats, resetTotalCount } = statisticsSlice.actions;

export default statisticsSlice.reducer;

export const statisticsSelector = (state) => state.statistics;
